import { db } from '@/core/services/firebase'

const INITIAL_ONSNAPSHOT_VALUE = -1
const RESETED_ONSNAPSHOT_VALUE = 0

export const getInitialState = () => {
  return {
    certificates: [],
    streamedCertificatesUpdates: INITIAL_ONSNAPSHOT_VALUE,
    streamedCertificatesUnsubscribe: null,
    isLoadingCertificate: false
  }
}

const state = getInitialState()

const getters = {
  certificates: state => {
    return state.certificates
  },
  isLoadingCertificate: state => {
    return state.isLoadingCertificate
  }
}

const actions = {
  bindCertificates ({ rootState, commit, dispatch }) {
    const { user } = rootState.account

    dispatch('unbindStreamedCertificates')

    try {
      const observer = db.collection(`people/${user.uid}/credentials`)
        .onSnapshot(function (querySnapshot) {
          var certificates = []
          querySnapshot.forEach(function (doc) {
            certificates.push(doc.data())
          })

          commit('updateStreamedCertificates', certificates)
        })

      commit('setStreamedCertificatesUnsubscribe', observer)

    } catch (error) {
      console.log(error)
    }
  },
  unbindStreamedCertificates({ commit }) {
    commit('unbindStreamedCertificates')
  }
}

const mutations = {
  updateStreamedCertificates (state, certificates) {
    state.certificates = certificates
    state.streamedCertificatesUpdates++
  },
  clearStreamedCertificatesUpdates (state) {
    state.streamedCertificatesUpdates = RESETED_ONSNAPSHOT_VALUE
  },
  unbindStreamedCertificates (state) {
    if (state.streamedCertificatesUnsubscribe) {
      state.streamedCertificatesUnsubscribe()
    }
    state.streamedCertificatesUnsubscribe = getInitialState().streamedCertificatesUnsubscribe
    state.streamedCertificatesUpdates = getInitialState().streamedCertificatesUpdates
  },
  setStreamedCertificatesUnsubscribe (state, unsubscribe) {
    state.streamedCertificatesUnsubscribe = unsubscribe
  },
  toggleLoadingCertificate(state, isLoadingCertificate) {
    state.isLoadingCertificate = isLoadingCertificate
  }
}

export const certificates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}