<template>
  <transition name="fade-onboarding">
    <div v-if="isVisible" class="journey-manual-toolkit">
      <div class="header" type="header">
        <base-header>
          <template v-slot:default>
            <heading-with-before color="white" :before-color-variation="true">
              {{ title }}
            </heading-with-before>
          </template>
          <template v-slot:footer>
            <heading color="white" tag="h4">{{ subtitle }}</heading>
          </template>
        </base-header>
      </div>
      <div class="main">
        <div class="image">
          <base-image
            image-url="https://storage.googleapis.com/tera-lxs-images/manual-teraway-illustration.jpg"
            image-description="Em primeiro plano a imagem de uma pessoa suspensa. Ao seu redor, ites flutuam formando um círculo."
          ></base-image>
        </div>
        <div class="first-section">
          <heading tag="h4">{{ firstSectiontitle }}</heading>
          <paragraph>{{ firstSectionParagraph }}</paragraph>
        </div>
        <div class="second-section">
          <toolkit-download-card
            v-for="(p, index) in toolkitDownloadContent"
            :key="index"
            :title="p.title"
            :paragraph="p.paragraph"
            :objective="p.objective"
            :image-url="p.imageUrl"
            :download-url="p.downloadUrl"
            :file-size="p.fileSize"
          >
          </toolkit-download-card>
        </div>
      </div>
      <div class="footer">
        <footer-onboarding></footer-onboarding>
      </div>
    </div>
  </transition>
</template>

<script>
import ToolkitDownloadCard from '@/modules/Journeys/components/JourneyManual/ToolkitDownloadCard'
import FooterOnboarding from '@/modules/Journeys/components/JourneyManual/FooterOnboarding'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JourneyManualToolkit',
  components: {
    ToolkitDownloadCard,
    FooterOnboarding
  },
  data() {
    return {
      isVisible: false,
      title: 'Explore as Ferramentas',
      subtitle: 'E construa coisas incríveis',
      firstSectiontitle: 'Um cinto de utilidades',
      firstSectionParagraph:
        'Separamos abaixo as principais ferramentas que você vai utilizar durante a contrução de um produto ou serviço. Faça o download e entenda como cada uma delas pode ajudar você em cada fase do projeto que você está participando, seja durante o bootcamp ou no seu dia a dia.',
      toolkitDownloadContent: [
        {
          title: 'Matriz CSD',
          paragraph:
            'Essa matriz nos ajuda a identificar o quanto de domínio sobre um projeto ou assunto temos inicialmente, partindo de 3 pilares: certezas, suposições e dúvidas.',
          objective:
            'O principal objetivo dessa ferramenta é esclarecer para o time quais pontos ainda não estão absorvidos e que precisam ser pesquisados e respondidos. Uma ótima ferramenta para etapas iniciais dos projetos ou para consolidação de aprendizados de entrevistas.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/matrix-CSD.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_MatrizCSD.png',
          fileSize: '33 KB'
        },
        {
          title: 'Lean canvas',
          paragraph:
            'O Lean Canvas é um modelo de plano de negócios que ajuda a desconstruir sua ideia em suas principais suposições. Ele substitui planos de negócios elaborados por um modelo de negócios de página única.',
          objective:
            'O Lean Canvas concentra-se no modo como o cronograma do produto  afeta o fluxo de receita de um negócio. É mais específico e incorpora as pequenas e grandes empresas.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/lean-canvas.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_lean_canvas.pdf',
          fileSize: '53 KB'
        },
        {
          title: 'Product vision Board',
          paragraph:
            'O PVB ajuda a dar forma ao seu produto, levantando as necessidades e o valor do negócio.',
          objective:
            'Orientar e envolver todos os participantes de um projeto desde a concepção até o o desenvolvimento, demonstrando a estratégia para atingir a visão desejada.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/product-canvas.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_product_canvas.pdf',
          fileSize: '75 KB'
        },
        {
          title: 'Business model canvas',
          paragraph:
            'O BMC é uma ferramenta que auxilia no gerenciamento estratégico, permitindo desenvolver e esboçar modelos de negócio novos ou já existentes.',
          objective:
            'Esse canvas é um mapa dos principais itens que constituem uma empresa, que deve ser revisado ao longo do tempo para saber se cada quadrante está sendo bem atendido ou se é necessário fazer alteração. O mapa é um resumo dos pontos chave de um plano de negócio, mas não deixa de excluir um plano de negócio em si.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/business-model-canvas.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_business_model_canvas.pdf',
          fileSize: '118 KB'
        },
        {
          title: 'Value preposi-tion canvas',
          paragraph:
            'Uma ferramenta para o desenvolvimento de clientes que explora mais profundamente os principais blocos do Business Model Canvas.',
          objective:
            'Essa ferramenta é indicada para o período de validação de uma Startup ou para entender os clientes em uma empresa mais estruturada. Pode ser utilizada para estruturar o processo de vendas, pois visa entender as necessidades dos clientes.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/value-proposition-canvas.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_ValuePropositionCanvas_template.png',
          fileSize: '64 KB'
        },
        {
          title: 'Empathy map',
          paragraph:
            'O mapa da empatia é uma ferramenta simples para entendimento sobre os usuários de um produto. É ideal para entender a audiência de produtos e serviços, tanto dos clientes quanto de concorrentes.',
          objective:
            'A empatia traz para dentro do time uma visão mais rica dos usuários. Passamos com essa ferramenta a exercitar o conhecimento sobre o contexto em que as personas de um projeto estão inseridas. O mapa da empatia é uma ferramenta que facilita a visualização e permite o foco nas questões certas.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/mapa-empatia.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_empathy_map.pdf',
          fileSize: '96 KB'
        },
        {
          title: 'Crazy eights',
          paragraph:
            'Uma técnica fundamental do Design Sprint. É um exercício rápido que desafia as pessoas a esboçar oito ideias distintas em oito minutos.',
          objective:
            'O objetivo é ultrapassar a sua primeira ideia, frequentemente a menos inovadora, e gerar uma ampla variedade de soluções para o seu desafio.\n\nAlguns membros da equipe sem experiência em design podem achar esse método intimidador no começo, então é útil tranquilizar a todos que esses são esboços difíceis. Eles não precisam ser perfeitos ou bonitos - os esboços só precisam comunicar a ideia. Se necessário, você pode até realizar uma rápida sessão de tutorial “como esboçar” antes de iniciar este exercício.',
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/canvas/crazy-eights.jpg',
          downloadUrl:
            'https://storage.googleapis.com/tera-materials/manual-toolkit/TERA_Crazy%20Eights_%20Template.png',
          fileSize: '20 KB'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['currentJourneyMoment'])
  },
  mounted() {
    const {
      journeys,
      modules,
      journeyManuals,
      journeyManualToolkit
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyManuals, journeyManualToolkit]

    this.setRouteBreadcrumb(breadcrumb)

    const interval = setInterval(() => {
      this.isVisible = true
      clearInterval(interval)
    }, 1000)
  },
  methods: {
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang='scss' scoped>
.journey-manual-toolkit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .header {
    width: 100%;
    height: 100%;
    min-height: 236px;
    > .base-header {
      min-height: 236px;
      @include breakpoint-small {
        min-height: 176px;
      }
    }
    @include breakpoint-small {
      min-height: unset;
    }
  }

  > .main {
    width: 100%;
    max-width: $container-l;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .image {
      margin-top: -40px;
      position: sticky;
    }

    > .first-section {
      margin: $space-xl 0;
      > .heading {
        margin-bottom: $space-xxs;
      }
      > .paragraph {
        line-height: $space-m;
      }
    }
    // @include breakpoint-small {
    //   max-width: $container-s;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }

    @include breakpoint-small {
      width: 100%;
      max-width: 300px;
      padding: 0 32px;
    }
  }

  > .footer {
    width: 100%;
  }
}
</style>
