import Originals from './index';
import { getOptionalParametersFromDataSnapshot } from '../../utils/classUtils';

const originalsConverter = {
  fromFirestore (snapshot, options) {
    const data = snapshot.data(options);
    var originals = new Originals(getOptionalParametersFromDataSnapshot(data, snapshot));

    return originals;
  }
};

export default originalsConverter;
