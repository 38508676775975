var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';
import axios from 'axios';
import { saveAs } from 'file-saver';
export default Vue.extend({
    name: 'FileDownload',
    components: {
        NodeViewWrapper
    },
    props: nodeViewProps,
    methods: {
        handleDownloadFile: ({ url, fileName, mimeType }) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                axios
                    .get(url, {
                    responseType: 'blob',
                    headers: {}
                    // onDownloadProgress(progress) {
                    //   console.log('download progress', progress)
                    // }
                })
                    .then((response) => {
                    const blob = new Blob([response.data], {
                        type: mimeType
                    });
                    saveAs(blob, fileName);
                });
            }
            catch (error) {
                console.error(error);
            }
        })
    }
});
