<template>
  <card-router-link :route="routeToGo" :journey="article" :block="article">
    <div
      v-if="article && (
        article.type && (
          article.type.toUpperCase() === 'CHALLENGE' ||
          article.type.toUpperCase() === 'BRANDED_CHALLENGE' ||
          article.type.toUpperCase() === 'TUTORIAL')
      )"
    >
      <div class="challenge-card">
        <div class="header">
          <img v-if="article.isBranded" src="@/assets/on_demand/challenge_branded.jpg" />
          <img v-else src="@/assets/on_demand/challenge_tera.jpg" />
        </div>
        <div class="container">
          <div class="left">
            <div class="icon-container">
              <progress-bar v-if="progressPercentage < 99" color="#99FB8C" size="34" :percent="progressPercentage" />
              <div v-else class="circle">
                <icon-base name="check" size="32" color="#99FB8C" />
              </div>
            </div>
          </div>
          <div class="content">
            <div class="main">
              <heading class="title" tag="h3">{{ article.name }}</heading>
              <p>{{ article.description }}</p>
            </div>

            <div class="footer">
              <img v-if="article.isBranded" :src="article.brandLogo" :alt="article.brandName" />
              <heading class="subtitle" fontWeight="normal" tag="h4" textTransform="none">
                {{ readingTime }} min <span v-if="isOptional">• <span class="optional">Opcional</span></span>
                <br />
                <span :class="{ concluded: progressPercentage === 100 }"> {{ progressPercentage }}% concluído </span>
              </heading>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="article-card" @click="setCurrentExpertsOfArticle">
        <div class="header">
          <div class="image-grid" :class="`grid-${expertGridSize}`">
            <img v-for="(expert, i) in expertsInGrid" :key="`${i}_${article.id}`" :src="expert.pictureUrl" />
            <div v-if="experts.length > expertsInGrid.length" class="expert-counter">
              +{{ experts.length - expertsInGrid.length }}
            </div>
          </div>
        </div>
        <div class="container">
          <div class="left">
            <div class="icon-container">
              <progress-bar v-if="progressPercentage < 99" color="#99FB8C" size="34" :percent="progressPercentage" />
              <div v-else class="circle">
                <icon-base name="check" size="32" color="#99FB8C" />
              </div>
            </div>
          </div>
          <div class="content">
            <div class="main">
              <heading class="title" tag="h3">{{ article.name }}</heading>
              <p v-if="experts.length === 1">com {{ experts[0].name }} • {{ experts[0].company }}</p>
              <p v-else-if="experts.length > 1">com {{ experts.map(e => e.name).join(', ') }}</p>
            </div>
            <div class="footer">
              <heading class="subtitle" fontWeight="normal" tag="h4" textTransform="none">
                {{ readingTime }} min <span v-if="isOptional">• <span class="optional">Opcional</span></span>
                <br />
                <span :class="{ concluded: progressPercentage === 100 }"> {{ progressPercentage }}% concluído </span>
              </heading>
            </div>
          </div>
          <div class="right">
            <div class="image-grid" :class="`grid-${expertGridSize}`">
              <img v-for="(expert, i) in expertsInGrid" :key="`${i}_${article.id}`" :src="expert.pictureUrl" />
              <div v-if="experts.length > expertsInGrid.length" class="expert-counter">
                +{{ experts.length - expertsInGrid.length }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card-router-link>
</template>

<script>
import {mapActions} from 'vuex'
import CardRouterLink from '@/core/components/CardRouterLink'
import ProgressBar from '../components/ProgressBar'
import {getNumberOfVideos, getProgressPercentage, getReadingTime} from '../utils/tiptapUtils'

export default {
  name: 'ArticleCard',
  components: {
    CardRouterLink,
    ProgressBar
  },
  props: {
    article: {},
    articleProgress: {},
    teraId: String,
    moduleIndex: Number,
    isOptional: {
      required: false,
      default: false
    },
    experts: {
      required: false,
      default: () => {
        return []
      }
    }
  },
  computed: {
    routeToGo() {
      return {
        name: 'journey-article',
        params: {
          teraId: this.teraId,
          articleId: this.article.id,
          moduleIndex: this.moduleIndex
        }
      }
    },
    expertGridSize() {
      if (!this.experts) return 0

      if (this.experts.length >= 4) return 4

      return this.experts.length
    },
    expertsInGrid() {
      if (this.experts.length > 4)
        return this.experts.slice(0, 3)

      return this.experts.slice(0, 4)
    },
    numberOfVideos() {
      return this.article ? getNumberOfVideos(this.article.chapters) : 0
    },
    readingTime() {
      return this.article ? getReadingTime(this.article.chapters) : 0
    },
    progressPercentage() {
      if (this.article && this.article.chapters) {
        return getProgressPercentage(this.article.chapters.length, this.doneChapters)
      }

      return 0
    },
    doneChapters() {
      return this.getNumberOfChaptersDone(this.articleProgress)
    }
  },
  methods: {
    ...mapActions('journeys', ['setCurrentArticleExperts']),

    getNumberOfChaptersDone(progress) {
      const currentChapterInteractions = progress ? progress.chapterInteractions || [] : []

      let numberOfChaptersDone = 0

      currentChapterInteractions.forEach(chapter => {
        if (chapter.status === 'done') {
          numberOfChaptersDone += 1
        }
      })

      return numberOfChaptersDone
    },

    setCurrentExpertsOfArticle() {
      this.setCurrentArticleExperts(this.experts)
    }
  }
}
</script>

<style lang="scss" scoped>
.challenge-card,
.article-card {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  max-width: 752px;
  width: 100%;

  .header {
    max-height: 192px;
    width: 100%;

    img {
      object-fit: cover;
      height: 192px;
      width: 100%;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
  }

  .left {
    max-width: 80px;
    box-sizing: border-box;
    padding: 16px 24px;
    justify-content: center;
    display: flex;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    height: 192px;

    .main {
      flex-grow: 1;
      color: #ffffff;
      padding: 16px 24px 0 0;
      flex-wrap: wrap;

      p {
        font-family: 'rubik';
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $color-gray-60;
        margin: 8px 0 24px 0;
      }
    }
  }

  .footer {
    font-family: 'rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $color-gray-40;
    padding: 0 0 16px 0;

    .concluded {
      color: #8DFE7E;
    }

    .optional {
      color: #68DDFD;
    }

    img {
      margin-bottom: 16px;
    }
  }
}

.challenge-card {
  .content {
    height: 100%;
  }
}

.article-card {
  height: 192px;

  .header {
    display: none;
  }

  .right {
    display: flex;
  }
}

@media (max-width: 800px) {

  .challenge-card,
  .article-card {
    height: 100%;

    .content {
      height: 100%;
    }
  }

  .article-card {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      max-height: initial;
      height: 100%;

      img {
        height: initial;
        width: 100%;
      }
    }

    .right {
      display: none;
    }
  }
}

.image-grid {
  display: grid;
  width: 100%;
  min-width: 192px;
  max-width: 192px;
  max-height: 192px;
  overflow: hidden;

  @media (max-width: 800px) {
    max-width: 800px;
    max-height: unset;
  }

  img {
    width: 100%;
    min-width: 192px;
  }

  &.grid-1 {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(1, 100%);
  }

  &.grid-2 {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);

    img:first-child,
    img:last-child {
      grid-row: span 2;
      width: 100%;
      height: 192px;
      min-width: unset;
      object-fit: cover;

      @media (max-width: 800px) {
        height: 320px;
      }
    }
  }

  &.grid-3 {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);

    img {
      height: 100%;
      width: 100%;
      min-width: unset;
      max-height: calc(192px / 2);
      object-fit: cover;

      @media (max-width: 800px) {
        max-height: calc(320px / 2);
      }
    }

    img:first-child {
      grid-row: span 2;
      width: 100%;
      height: 192px;
      min-width: unset;
      object-fit: cover;
      max-height: unset;

      @media (max-width: 800px) {
        height: 320px;
      }
    }

    img:nth-child(2) {
      grid-column: 2 / 2;
      grid-row: 1 / 2
    }

    img:last-child {
      grid-column: 2 / 2;
      grid-row: 2 / 2
    }
  }

  &.grid-4 {
    grid-template-columns: repeat(2, 96px);
    grid-template-rows: repeat(2, 96px);

    @media (max-width: 800px) {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(2, 50%);
    }

    img {
      height: 100%;
      width: 100%;
      max-height: calc(192px / 2);
      min-width: unset;

      @media (max-width: 800px) {
        max-height: calc(320px / 2);
      }
    }
  }

  .expert-counter {
    color: white;
    font-family: 'Rubik';
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: calc(192px / 2);
    height: 100%;
    width: 100%;

    @media (max-width: 800px) {
      max-height: calc(320px / 2);
    }
  }
}
</style>
