var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.getSelectedResponse === null)?_c('div',{class:_vm.optionData.index === _vm.getSelectedIndexOption
        ? 'quiz-option-container-selected'
        : 'quiz-option-container',on:{"click":function($event){return _vm.handleSelectOption(_vm.optionData)}}},[_c('div',{staticClass:"check-icon-container"},[_c('span',[_c('img',{staticClass:"success-icon",attrs:{"src":_vm.successIcon}}),_c('img',{staticClass:"fail-icon",attrs:{"src":_vm.failIcon}}),_c('div',{staticClass:"selected"})])]),_c('p',[_vm._v(_vm._s(_vm.optionData.option))])]):_c('div',{class:_vm.optionData.index !== _vm.getSelectedIndexOption
        ? 'quiz-option-container-default'
        : _vm.getSelectedResponse
          ? 'quiz-option-container-success'
          : 'quiz-option-container-fail'},[_c('div',{staticClass:"check-icon-container"},[_c('span',[_c('img',{staticClass:"success-icon",attrs:{"src":_vm.successIcon}}),_c('img',{staticClass:"fail-icon",attrs:{"src":_vm.failIcon}}),_c('div',{staticClass:"selected"})])]),_c('p',[_vm._v(_vm._s(_vm.optionData.option))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }