<template>
  <div class="middle-content">
    <div v-if="loading" class="loading-modal">
      <loading-animation />
    </div>

    <div class="progress-bar">
      <section :style="`width: ${((currentStep * 100) / totalSteps) - 5}%`" />
    </div>

    <div class="buttons-container">
      <button @click="prevStep">
        <img :src="leftArrow" />
        <span>Voltar</span>
      </button>
      <button @click="nextStep">
        <span>Pular</span>
        <img :src="rightArrow" />
      </button>
    </div>

    <div class="content-length">{{ `${currentStep} De ${totalSteps}` }}</div>

    <h1 v-if="currentStep === 1">Como você avalia o seu aprendizado sobre o tema da aula?</h1>
    <h1 v-else>Como você classificaria a performance das(os) experts nesta aula?</h1>

    <p v-if="currentStep === 1">
      Aula: {{ currentJourneyArticle.name }}
    </p>

    <div v-else class="expert-info">
      <!--
        <div>
          <img :src="expert.photo" />
        </div>

        <span>{{ expert.name }}</span>
      -->
    </div>

    <div class="faces-container">
      <div :class="rateValues[0].selected ? 'button-selected' : ''">
        <button @click="handleRate(0)">
          <img :src="sadFace" />
        </button>
        <span>Ruim</span>
      </div>
      <div :class="rateValues[1].selected ? 'button-selected' : ''">
        <button @click="handleRate(1)">
          <img :src="neutralFace" />
        </button>
        <span>Bom</span>
      </div>
      <div :class="rateValues[2].selected ? 'button-selected' : ''">
        <button @click="handleRate(2)">
          <img :src="happyFace" />
        </button>
        <span>Ótimo</span>
      </div>
    </div>

    <button :disabled="!showNextButton" @click="saveAnswer">Avançar</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getCurrentUser } from '@/core/services/firebase'
import evaluationArticleService from '@/modules/Journeys/services/evaluationArticle'
import LoadingAnimation from '@/modules/Journeys/components/LoadingAnimation.vue'

export default Vue.extend({
  name: 'RateStep',
  components: {
    LoadingAnimation
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    expert: {
      type: Boolean
    },
    totalSteps: {
      type: Number,
      required: true
    },
    prevStep: {
      type: Function,
      required: true
    },
    nextStep: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      sadFace: require('../../../assets/icons/sad_face.svg'),
      neutralFace: require('../../../assets/icons/neutral_face.svg'),
      happyFace: require('../../../assets/icons/happy_face.svg'),
      rightArrow: require('../../../assets/icons/right-arrow.svg'),
      leftArrow: require('../../../assets/icons/left-arrow.svg'),
      loading: false,
      showNextButton: false,
      currentUser: null,
      rateValues: [
        {
          name: 'Ruim',
          selected: false,
        },
        {
          name: 'Bom',
          selected: false,
        },
        {
          name: 'Ótimo',
          selected: false,
        }
      ]
    }
  },

  computed:{
    ...mapGetters('journeys', ['currentJourneyArticle'])
  },
  watch: {
    rateValues() {
      this.showNextButton = this.rateValues.some(rate => rate.selected)

    },
    expert() {
      this.getAnswer()
    }
  },
  async mounted(){
    this.currentUser = await getCurrentUser()

    this.getAnswer()
  },
  methods: {
    handleRate(index) {
      const newRateValues = this.rateValues.map((rateValue, i) => {
        if (index === i) {
          return {
            ...rateValue,
            selected: !rateValue.selected,
          }
        } else {
          return {
            ...rateValue,
            selected: false,
          }
        }
      })
      this.rateValues = newRateValues
    },
    async getAnswer() {
      this.loading = true
      const answer = await evaluationArticleService.getEvaluation(this.currentUser?.uid, this.currentJourneyArticle.id)

      if (this.currentStep === 1) {
        const newRateValues = this.rateValues.map((rateValue) => {
          if (rateValue.name === answer?.evaluationArticle?.evaluate) {
            return {
              ...rateValue,
              selected: true,
            }
          } else {
            return {
              ...rateValue,
              selected: false,
            }
        }
      })

      this.rateValues = newRateValues
      } else {
        const newRateValues = this.rateValues.map((rateValue) => {
          if (rateValue.name === answer?.evaluationExperts[0]?.evaluation) {
            return {
              ...rateValue,
              selected: true,
            }
          } else {
            return {
              ...rateValue,
              selected: false,
            }
          }
        })

        this.rateValues = newRateValues
      }
      this.loading = false
    },
    async saveAnswer() {
      this.loading = true
      const selectedRate = this.rateValues.find(rate => rate.selected)

      if (this.currentStep === 1) {
        await evaluationArticleService.saveEvaluationArticle(this.currentJourneyArticle.id,
        {
          peopleId: this.currentUser?.uid,
          evaluate: selectedRate.name
        })
      } else {
        await evaluationArticleService.saveEvaluationExpert(this.currentJourneyArticle.id,
        [{
          peopleId: this.currentUser?.uid,
          collaborator: 'default',
          question: 'COMO VOCÊ CLASSIFICARIA A PERFORMANCE DAS(OS) EXPERTS NESTA AULA?',
          evaluation: selectedRate.name
        }])
      }

      const newRateValues = this.rateValues.map((rateValue) => {
        return {
          ...rateValue,
          selected: false,
        }
      })
      this.rateValues = newRateValues

      this.nextStep()
      this.loading = false

    },
   
    
  }
})
</script>

<style lang="scss">
.middle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #383838;
  color: #fff;
  padding-bottom: 24px;
  text-align: center;
  position: relative;

  .loading-modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }

  .progress-bar {
    width: 100%;
    height: 8px;
    background: #929292;

    > section {
      height: 100%;
      background: #8dfe7e;
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 12px 10px 0;


    button {
      display: flex;
      align-content: center;
      justify-content: center;
      background: none;
      border: 0;
      color: #bfbfbf;
      cursor: pointer;

      &:first-child {
        img {
          margin-right: 8px;
        }
      }

      &:last-child {
        img {
          margin-left: 8px;
        }
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .content-length {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 8px;
  }

  > h1 {
    font-size: 16px;
    line-height: 24px;
    margin: 9px 0 0 0;
    text-transform: uppercase;
    text-align: center;
    width: 387px;
  }

  > p {
    margin: 16px 0 0 0;
    color: #e3e3e3;
    width: 387px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
  }

  .expert-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 0 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      border: 1.5px solid #e3e3e3;

      > img {
        width: 31px;
        height: 31px;
        border-radius: 50%;
      }
    }

    > span {
      display: inline-block;
      margin-left: 6px;
      font-size: 14px;
      font-weight: normal;
      color: #e3e3e3;
    }
  }

  .faces-container {
    display: flex;
    margin: 28px 0 0 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & + div {
        margin-left: 24px;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border: 2px solid #929292;
        border-radius: 50%;
        background: none;
        cursor: pointer;

        > img {
          width: 20px;
          height: 20px;
          filter: invert(66%) sepia(0%) saturate(196%) hue-rotate(273deg) brightness(89%) contrast(86%);
        }

        &:hover {
          border: 2px solid #f0f0f0;

          > img {
            filter: invert(99%) sepia(4%) saturate(296%) hue-rotate(226deg) brightness(114%) contrast(88%);
          }
        }
      }

      > span {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        color: #929292;
        text-transform: uppercase;
      }
    }

    .button-selected {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & + div {
        margin-left: 24px;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border: 2px solid #fff;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;

        > img {
          width: 20px;
          height: 20px;
          filter: invert(18%) sepia(2%) saturate(158%) hue-rotate(100deg) brightness(96%) contrast(86%);
        }

        &:hover {
          > img {
            filter: invert(18%) sepia(2%) saturate(158%) hue-rotate(100deg) brightness(96%) contrast(86%);
          }
        }
      }

      > span {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }

  > button {
    font-family: 'Rubik', sans-serif;
    margin-top: 16px;
    padding: 9px 32px;
    color: #000000;
    background: #8dfe7e;
    border-radius: 8px;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
      transform: translate(1px, 1px);
    }

    &:disabled {
      background: #666666;
      color: #333333;
      cursor: default;

      &:hover {
        filter: none;
        transform: none;
      }
    }
  }
}

@media(max-width: 800px) {
  .middle-content {
    > h1 {
      width: 90%;
    }

    > p {
      width: 90%;
    }
  }
}
</style>
