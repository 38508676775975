<template>
  <div ref="popover" class="popover"
       :style="`top:${getPopoverTop}px;left:${getPopoverLeft}px;opacity:${visible ? 100 : 0}`"
  >
    <div class="wrapper">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Popover',
  props: {
    id: {
      type: String,
      required: true,
      default: 'popover-x'
    },
    triggeredBy: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      triggeredByClientRect: null,
      popoverClientRect: null
    }
  },
  computed: {
    getPopoverTop () {
      if (!this.triggeredByClientRect || !this.popoverClientRect) return
      if (!this.visible) return 10000;
      const topMargin = 8
      return this.triggeredByClientRect.top - this.popoverClientRect.height - topMargin
    },
    getPopoverLeft () {
      if (!this.triggeredByClientRect || !this.popoverClientRect) return
      if (!this.visible) return 10000;
      return this.triggeredByClientRect.right - this.popoverClientRect.width
    }
  },
  watch: {
    visible (newValue) {
      if (newValue) {
        this.triggeredByClientRect = document.getElementById(this.triggeredBy).getBoundingClientRect()
        this.popoverClientRect = this.$refs.popover.getBoundingClientRect()
        this.attachScrollEventToAutoCloseOnScroll()
      }
    }
  },
  mounted () {
    const triggerElement = document.getElementById(this.triggeredBy)
    this.triggeredByClientRect = triggerElement.getBoundingClientRect()
    this.popoverClientRect = this.$refs.popover.getBoundingClientRect()

    let isMobile = window.matchMedia("(max-width: 800px)");
    isMobile = isMobile.matches

    if (isMobile) {
      triggerElement.addEventListener('click', this.toggleVisible)
    } else {
      triggerElement.addEventListener('mouseover', () => this.setVisible(true))
      triggerElement.addEventListener('mouseleave', () => this.setVisible(false))
    }
  },
  methods: {
    setVisible (isVisible) {
      this.visible = isVisible
    },
    toggleVisible () {
      this.setVisible(!this.visible)
    },
    attachScrollEventToAutoCloseOnScroll () {
      document.addEventListener('scroll', this.setVisibleFalseOnScroll)
    },
    removeScrollEvent () {
      document.removeEventListener('scroll', this.setVisibleFalseOnScroll)
    },
    setVisibleFalseOnScroll () {
      this.setVisible(false)
      this.removeScrollEvent()
    }
  }
}
</script>

<style lang="scss" scoped>
.popover {
  font-family: 'Rubik';
  font-size: 12px;
  line-height: 18px;
  padding: 12px;
  max-width: 244px;
  width: 100%;
  background-color: #8F8F8F;
  box-sizing: border-box;
  position: fixed;
  top: 100000px;
  left: 100000px;

  .wrapper {
    height: 100%;
  }
}
</style>