<template>
  <div id="topo" class="list-all">
    <div class="header">
      <base-header background-color="blue-primary">
        <heading-with-before color="white" :before-color-variation="true">
          {{ title }}
        </heading-with-before>
        <heading color="white" tag="h4">{{ subtitle }}</heading>
      </base-header>
    </div>
    <div class="main">
      <div class="cards">
        <onboarding-card
          v-for="(content, index) in onboardingContent"
          :key="index"
          :image-url="content.imageUrl"
          :image-description="content.imageDescription"
          :span="content.span"
          :title="content.title"
          :subtitle="content.subtitle"
          :route="content.route"
        ></onboarding-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OnboardingCard from '@/modules/Journeys/components/JourneyManual/OnboardingCard'

export default {
  name: 'ListAll',
  components: {
    OnboardingCard
  },
  data() {
    return {
      title: 'Manual do Curso',
      subtitle: 'Módulo Zero',
      onboardingContent: [
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/primeiros-passos-illustration.svg',
          imageDescription: '',
          span: 'Comece por aqui',
          title: 'Primeiros Passos',
          subtitle: '#básico #boasvindas #entenda #primeirospassos',
          route: {
            name: 'journey-manual-intro'
          }
        },
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/teraway-illustration.svg',
          imageDescription: '',
          span: 'Nosso Jeito',
          title: 'Teraway',
          subtitle: '#jornada #dna #humano #regras #visao #caminho',
          route: {
            name: 'journey-manual-teraway'
          }
        },
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/ferramentas-illustration.svg',
          imageDescription: '',
          span: 'Para você usar',
          title: 'Ferramentas',
          subtitle: '#ferramentas #framework #biblioteca #referencia #projeto',
          route: {
            name: 'journey-manual-toolkit'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['currentJourneyMoment'])
  },
  beforeMount() {
    const {
      journeys,
      modules,
      journeyManuals
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyManuals]

    this.setRouteBreadcrumb(breadcrumb)
  },
  methods: {
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.list-all {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .header {
    width: 100%;
    > .base-header {
      ::v-deep .container {
        max-width: 944px;
      }
    }
  }

  > .main {
    > .cards {
      width: 100%;
      max-width: $container-l;
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 208px 0;
      // margin-top: 40px;
      // margin-bottom: 208px;

      > a:first-of-type {
        margin-right: 16px;
        @include breakpoint-small {
          margin-right: unset;
        }
      }
    }

    > .button {
      width: 100%;
      max-width: $container-l;
      margin-top: 56px;

      > .button {
        width: 220px;
        left: 0;
      }
    }
    @include breakpoint-small {
      max-width: 300px;
    }
  }
}
</style>
