import firebase from 'firebase/app'
import 'firebase/firestore'
const { Timestamp } = firebase.firestore

export const setOptionalParameters = (entity, optionalAttributes) => {
  if (!optionalAttributes)
    return;

  for (var param in optionalAttributes) {
    if (entity[param] === undefined)
      continue;

    if (!entity[param]
      || (entity[param].constructor.name === 'Array' && entity[param].length === 0)
      || (entity[param].constructor.name === 'Object' && Object.keys(entity[param]).length === 0))
      entity[param] = optionalAttributes[param];
  }
};

export const getOptionalParametersFromDataSnapshot = (data, snapshot) => {
  return { ...data, ...{ id: snapshot.id } };
};

export const getDateFromTimestamp = (value) => {
  return value ? new Timestamp(value.seconds, value.nanoseconds).toDate() : null
};

export const getTimestampFromDate = (value) => {
  return value ? Timestamp.fromDate(value) : null
};