<template lang="html">
  <div class="footer-onboarding">
    <div class="cards">
      <onboarding-card v-for="(content, index) in onboardingContent"
                       v-if="currentRouteName !== content.route.name"
                       :key="index"
                       :image-url="content.imageUrl"
                       :image-description="content.imageDescription"
                       :span="content.span"
                       :title="content.title"
                       :subtitle="content.subtitle"
                       :route="content.route"
      >
      </onboarding-card>
    </div>
  </div>
</template>

<script>
import OnboardingCard from '@/modules/Journeys/components/JourneyManual/OnboardingCard'

export default {
  name: 'FooterOnboarding',
  components: {
    OnboardingCard
  },
  data() {
    return {
      onboardingContent: [
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/primeiros-passos-illustration.svg',
          imageDescription:
            'Ilustração com muitas cores e formas ao fundo, formando um círculo. No primeiro plano há sombra de silhueta de uma pessoa.',
          span: 'Comece por aqui',
          title: 'Primeiros Passos',
          subtitle: '#básico #boasvindas #entenda #primeirospassos',
          route: {
            name: 'journey-manual-intro'
          }
        },
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/teraway-illustration.svg',
          imageDescription:
            'A ilustração brinca com a perspectiva da pessoa que a observa. Apresenta quatro escadas que sobrem e descem para diferente direções, inclusive na diagonal. O início e fim de cada escada varia com quatro janelas. Em cada janela há um desenho de uma pessoa fazendo alguma atividade, como usando um notebook, lendo um livro ou observando.',
          span: 'Nosso Jeito',
          title: 'Teraway',
          subtitle: '#jornada #dna #humano #regras #visao #caminho',
          route: {
            name: 'journey-manual-teraway'
          }
        },
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/ferramentas-illustration.svg',
          imageDescription:
            'Em primeiro plano a imagem de uma pessoa suspensa. Ao seu redor, ites flutuam formando um círculo.',
          span: 'Para você usar',
          title: 'Ferramentas',
          subtitle: '#ferramentas #framework #biblioteca #referencia #projeto',
          route: {
            name: 'journey-manual-toolkit'
          }
        }
      ]
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-onboarding {
  width: 100%;
  background-color: $color-gray-20;
  display: flex;
  justify-content: center;

  > .cards {
    width: 100%;
    max-width: $container-l;
    margin-top: 47px;
    display: flex;
    flex-wrap: wrap;

    > a:first-of-type {
      margin-right: 16px;

      @include breakpoint-small {
        margin-right: 0;
        margin-bottom: 32px;
      }
    }

    @include breakpoint-small {
      width: 100%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
  }
  @include breakpoint-small {
    display: flex;
    flex-direction: column;
  }
}
</style>
