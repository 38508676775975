import { setOptionalParameters } from '../../utils/classUtils'

class People {
  constructor(name, company, roles, optionalAttributes) {
    this.name = name;
    this.company = company;
    this.roles = roles;

    setOptionalParameters(this, optionalAttributes)
  }

  id = "";
  description = "";
  jobTitle = "";
  pictureUrl = "";
  profileUrl = "";
}

export default People;
