import IconBase from '@/core/components/Icon/index.vue';
import { sentryInit } from '@/core/config/sentry';
import analytics from '@/core/services/analytics';
import { app } from '@/core/services/firebase';
import store from '@/core/store';
import '@/core/utils/webFontLoader';
import breadcrumb from '@/modules/Journeys/breadcrumb.js';
import trackevents from '@/modules/Journeys/trackevents';
import router from '@/routes';
import DesignSystem from '@somostera/tera-design-system';
import '@somostera/tera-design-system/dist/system/system.css';
import tokens from '@somostera/tera-design-system/dist/system/tokens/tokens.json';
import Vue from 'vue';
import VueMarkdown from 'vue-markdown';
import VueMasonry from 'vue-masonry-css';
import amplitude from 'amplitude-js';
import App from './App.vue';
sentryInit();
amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY || '');
Vue.use(analytics, { router, debug: true });
Vue.component('vue-markdown', VueMarkdown);
Vue.component('icon-base', IconBase);
Vue.use(VueMasonry);
Vue.use(DesignSystem);
Vue.prototype.teraTokens = tokens;
Vue.prototype.$breadcrumbTemplates = { journeys: breadcrumb };
Vue.prototype.$trackeventsTemplates = { events: trackevents };
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    beforeCreate() {
        app
            .auth()
            .onAuthStateChanged(user => {
            localStorage.setItem('lxs-v2-is-login-with-google', JSON.stringify(false));
            this.$store.dispatch('account/toggleWaitingLogin', false);
            if (!user) {
                if (router.currentRoute.path.indexOf('login') === -1) {
                    router.replace('/login');
                }
                this.$store.dispatch('account/setUser', null);
                this.$store.dispatch('account/setClaims', null);
                this.$store.dispatch('system/setRouteBreadcrumb', null);
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            app
                .auth()
                .currentUser.getIdToken()
                .then(token => {
                registerUserLastLogin(token);
            });
            this.$store.dispatch('account/setUser', JSON.parse(JSON.stringify(user)));
            amplitude.getInstance().setUserId(user.uid);
            amplitude.getInstance().logEvent('Logou no LXS', {
                loginType: 'automático',
                products: [],
                ClassCode: '',
                schools: [],
            });
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            app
                .auth()
                .currentUser.getIdTokenResult()
                .then(tokenResult => {
                this.$store.dispatch('account/setClaims', JSON.parse(JSON.stringify(tokenResult.claims)));
            })
                .catch(err => {
                console.error(err);
            });
        })
            .bind(this);
        if (app.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Por favor, informe seu e-mail novamente para confirmarmos sua segurança');
            }
            if (email) {
                app
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then(function (result) {
                    if (result.user) {
                        amplitude.getInstance().setUserId(result.user.uid);
                        amplitude.getInstance().logEvent('Logou no LXS', {
                            type: 'link de e-mail',
                            products: [],
                            ClassCode: '',
                            schools: [],
                        });
                        router.replace('/');
                    }
                })
                    .catch(function (error) {
                    alert('Parece que ocorreu algum erro com o seu processo de login. Por favor, tente novamente.');
                    console.error(error);
                });
            }
        }
    },
    render: h => h(App)
}).$mount('#app');
const registerUserLastLogin = (token) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fetch(process.env.VUE_APP_LOG_LOGIN_URL, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                __session: token
            },
            referrerPolicy: 'no-referrer'
        });
    }
    catch (e) { }
};
