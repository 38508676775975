<template>
  <div class="modules">
    <div
      id="ao-vivo"
      class="module-container"
    >
      <div class="text-container">
        <div class="text">
          <div class="headings">
            <div class="aside-left">
              <h2 class="heading -font-weight-light -font-text-transform-uppercase">Próximos encontros</h2>
              <div class="paragraph">
                <p>Participe ao vivo de encontros com Experts</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-container">
        <div v-if="upnextLives && upnextLives.length > 0">
          <div
            v-for="(jBlock, k) in upnextLives"
            :key="k + '_journeyBlocks'"
            class="card"
            @click.capture="() => handleCardClick(jBlock, moduleIndex)"
          >
            <on-demand-live-block-card
              :journey="currentUserJourney"
              :class-block="jBlock"
              :expert="getExpertById(jBlock.expertId)"
              :place="getPlaceById(jBlock.placeId)"
              :route-params="childRouteParams"
              :on-click="emitAmplitudeEvent"
            ></on-demand-live-block-card>
          </div>
        </div>
        <div v-else>
          <div class="empty-lives">
            <p><strong>Nenhum encontro disponível no momento.</strong></p>
            <p>Em breve seus encontros ao vivo aparecerão aqui.</p>
          </div>
        </div>
      </div>
      <div class="text-container replay-section">
        <div class="text">
          <div class="headings">
            <div class="aside-left">
              <h2 class="heading -font-weight-light -font-text-transform-uppercase">Assista novamente</h2>
              <div class="paragraph">
                <p>Reveja os encontros que já aconteceram</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-container">
        <div v-if="replayedLives && replayedLives.length > 0">
          <div
            v-for="(jBlock, k) in replayedLives"
            :key="k + '_journeyBlocks'"
            class="card"
            @click.capture="() => handleCardClick(jBlock, moduleIndex)"
          >
            <on-demand-live-block-card
              :journey="currentUserJourney"
              :class-block="jBlock"
              :expert="getExpertById(jBlock.expertId)"
              :place="getPlaceById(jBlock.placeId)"
              :route-params="childRouteParams"
              :on-click="emitAmplitudeEvent"
            ></on-demand-live-block-card>
          </div>
        </div>
        <div v-else>
          <div class="empty-lives">
            <p><strong>Nenhum encontro disponível para reassistir no momento.</strong></p>
            <p>Encontros passados poderão ser vistos novamente aqui.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import OnDemandLiveBlockCard from '@/modules/Journeys/components/OnDemandLiveBlockCard.vue'
  import Journey from '@/core/entities/models/Journey'
  import amplitude from 'amplitude-js'


  export default {
    name: 'OnDemandLivesVisualization',
    components: {
      OnDemandLiveBlockCard
    },
    props: {
      currentUserJourney: Journey,
      currentJourneyBlocks: Array,
      currentJourneyExperts: Array,
      currentJourneyPlaces: Array,
      currentJourneyArticles: Array,
      currentJourneyArticlesProgress: Array,
      childRouteParams: Object
    },
    computed: {
      currentLives() {
        if (!this.currentJourneyBlocks) return []

        return this.currentJourneyBlocks
          .filter((jb) => jb.type !== 'originals' && jb.type !== 'article' && jb.startTime)
          .sort((a, b) => (a.startTime.getTime() - b.startTime.getTime()))
      },
      upnextLives () {
        return this.currentLives
          .filter((journeyBlock) => {
            return journeyBlock.endTime && journeyBlock.startTime && this.getDateInUTC(journeyBlock.endTime) >= new Date()
          })
          .sort((a, b) => (a.startTime.getTime() - b.startTime.getTime()))
      },
      replayedLives () {
        const oneMonthAgo = new Date().setMonth(new Date().getMonth() - 1);

          return this.currentLives
          .filter((journeyBlock) => {
            return journeyBlock.endTime && journeyBlock.startTime && this.getDateInUTC(journeyBlock.endTime) < new Date() && this.getDateInUTC(journeyBlock.endTime) >= oneMonthAgo 
          })
          .sort((a, b) => (a.startTime.getTime() - b.startTime.getTime()))
      },
      ...mapGetters('journeys', ['getArticleById'])
    },
    methods: {
      getDateInUTC(date) {
        return new Date(
          Date.UTC(date.getUTCFullYear(), 
          date.getUTCMonth(),
          date.getUTCDate(), 
          date.getUTCHours() + 3,
          date.getUTCMinutes(),
          date.getUTCSeconds())
        );
      },
      getLivesBySection(section) {
        if (section === 'replays') {
          const oneMonthAgo = new Date().setMonth(new Date().getMonth() - 1);

          return this.currentLives
          .filter((journeyBlock) => {
            return journeyBlock.endTime && journeyBlock.startTime && journeyBlock.endTime < new Date() && journeyBlock.endTime >= oneMonthAgo 
          })
          .sort((a, b) => (a.startTime.getTime() - b.startTime.getTime()))
        }

        if (section === 'upnext') {
          return this.currentLives
          .filter((journeyBlock) => {
            return journeyBlock.endTime && journeyBlock.startTime && journeyBlock.endTime >= new Date()
          })
          .sort((a, b) => (a.startTime.getTime() - b.startTime.getTime()))
        }
        
      },
      getPreloadedArticle(articleRef) {
        return this.currentJourneyArticles.find((e) => e.id == articleRef.id)
      },
      getPreloadedArticleProgress(articleRef) {
        return this.currentJourneyArticlesProgress.find((e) => e.id == articleRef.id)
      },
      getExpertFromArticle(articleRef) {
        const article = this.getPreloadedArticle(articleRef)
        const expertsIds = article.participantsIds ? article.participantsIds : []
        return this.currentJourneyExperts.filter(e => expertsIds.includes(e.id))
      },
      getModuleLibraryRouteParams(moduleIndex) {
        return { ...this.childRouteParams, moduleIndex: moduleIndex + 1 }
      },
      moduleHasOriginals(moduleIndex) {
        return this.currentJourneyBlocks.some((jb) => jb.moduleIndex === moduleIndex && jb.type === 'originals')
      },
      getExpertById(id) {
        return this.currentJourneyExperts.find((e) => e.id === id)
      },
      getPlaceById(id) {
        return this.currentJourneyPlaces.find((p) => p.id === id)
      },
      emitAmplitudeEvent() {
        amplitude.getInstance().logEvent('journey_module_visualization_default_view')
      },

      async handleCardClick(block, moduleIndex) {
        await this.setCurrentModuleIndex(moduleIndex)

        if (block.type === 'article') {
          const { name } = this.getArticleById(block.articleRef.id)
          Vue.$logEvent({
            category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
            action: `clique:card:${block.type}`,
            label: `${String(block.moduleIndex + 1).padStart(2, '0')}:${name}`
          })
        } else {
          Vue.$logEvent({
            category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
            action: `clique:card:${block.type}`,
            label: `${String(block.moduleIndex + 1).padStart(2, '0')}:${block.name}`
          })
        }
      },
      handleOriginalsCardClick(moduleIndex) {
        const module = String(moduleIndex + 1).padStart(2, '0')
        Vue.$logEvent({
          category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
          action: 'clique:card:originals',
          label: `${module}:biblioteca_modulo_${module}`
        })
      },
      logOnboardingCardEvent() {
        Vue.$logEvent({
          category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
          action: 'clique:card:onboarding',
          label: '00:onboarding'
        })
      },
      ...mapActions('journeys', ['setCurrentModuleIndex']),

    }
  }
</script>

<style lang="scss" scoped>
  .module-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $space-xxl;
    margin-bottom: 64px;

    > .text-container {
      width: 100%;
      max-width: 752px;
      > .text {
        > .headings {
          display: flex;
        }
      }
    }

    > .card-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 752px;

      .card {
        margin-bottom: 16px;
      }
    }
  }

  .text {
  width: 100%;
  text-align: left;

  > .headings {
    display: flex;
    position: relative;
    margin-bottom: 24px;

    > .aside-right {
      width: 96px;
      top: -10px;
      transform: translateX(-100%);
      position: absolute;

      @include breakpoint-small {
        transform: none;
        position: inherit;
        margin-bottom: 2px;
      }
    }

    > .aside-left {
      width: 100%;
      max-width: 730px;
      height: 100%;
      margin-left: 0;
      position: relative;

      &::before {
        content: ' ';
        width: 6px;
        height: 100%;
        position: absolute;
        background-color: $color_black;
        left: -22px;
      }

      .paragraph {
        p {
          font-family: 'rubik';
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin: 8px 0 0;
          color: #383838;
        }
      }
    }

    @include breakpoint-small {
      max-width: 312px;
      flex-direction: column;
    }
  }
}

.replay-section {
  margin-top: 64px;
}

.empty-lives {
  height: 192px;
  width: 100%;
  max-width: 752px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  color: #707070;
  font-family: 'Rubik';
  padding: 2rem;
  box-sizing: border-box;

  p {
    margin: 8px 0 0 0;
  }
}

.heading {
  font-family: $font_rubik;
  text-transform: uppercase;
  font-weight: lighter;
  color: inherit;
  margin: 0;
}
h1.heading {
  font-size: $font-size-xl;
  line-height: $font-size-xl;
  @include breakpoint-small {
    font-size: $font-size-l;
    line-height: $font-size-l;
  }
}
h2.heading {
  font-size: $font-size-l;
  line-height: $font-size-l;
  @include breakpoint-small {
    font-size: $font-size-m;
    line-height: $font-size-m;
  }
}
h3.heading {
  font-size: $font-size-m;
  line-height: $font-size-m;
  @include breakpoint-small {
    font-size: $font-size-s;
    line-height: 20px;
  }
}
h4.heading {
  font-size: $font-size-s;
  line-height: 20px;
  @include breakpoint-small {
    font-size: $font-size-s;
    line-height: 20px;
  }
}
h5.heading {
  font-size: $font-size-xs;
  line-height: 20px;
}
</style>
