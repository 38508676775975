<template>
  <iframe ref="mf" :src="link" @load="iframeLoaded"></iframe>
</template>

<script>
import { firebase } from '@/core/services/firebase'

export default {
  name: 'MicroFrontend',
  props: {
    link: String,
    newMessage: Object
  },
  mounted () {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage (event) {
      console.log(event.data)
    },
    postMessage (message) {
      this.$refs.mf.contentWindow.postMessage(message, '*')
    },
    async iframeLoaded() {
      const token = await firebase.auth().currentUser.getIdToken();
      this.postMessage({ token })
      if (this.newMessage) {
        this.postMessage(this.newMessage)
      }
    }
  }
}
</script>

<style>
iframe {
  height: 100%;
  width: 100%;
  border: none;
}
</style>
