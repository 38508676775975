import Vue from 'vue'
import Vuex from 'vuex'
import { system } from '@/core/store/system'
import { account } from '@/core/store/account'
import { events } from '@/core/store/events'
import { journeys, journeysWatchers } from '@/modules/Journeys/store/journeys'
import { quiz } from '@/modules/Journeys/store/quiz'
import { certificates } from '@/modules/Journeys/store/certificates'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    journeys,
    system,
    account,
    events,
    quiz,
    certificates
  }
})

const watchers = { ...journeysWatchers }

for (var watcherName in watchers) {
  const watcherWithStore = watchers[watcherName](store)
  store.watch(watcherWithStore.state, watcherWithStore.watcher)
}

export default store
