import { setOptionalParameters } from '../../../utils/classUtils'

class ClassBlock {
  constructor(name, index, moduleIndex, teraId, type, optionalAttributes) {
    this.name = name;
    this.index = index;
    this.moduleIndex = moduleIndex;
    this.teraId = teraId;
    this.type = type;

    setOptionalParameters(this, optionalAttributes)
  }

  id = "";
  teraId = "";
  blockId = "";
  description = "";
  date = null;
  startTime = null;
  endTime = null;
  liveUrl = "";
  eventId = "";
  expertId = "";
  expertStatus = null;
  contentBeforeClass = [];
  contentAfterClass = "";
  askYourself = [];
  outcomes = [];
  downloads = [];
  placeId = "";
  liveQuestionLink = "";
  fileBeforeClass = [];
  mentorsIds = [];
  zoomMeetingId = '';
}

export default ClassBlock;
