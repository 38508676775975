<template>
  <div class="banner-container-anivertera">
    <div class="title-container">
      <div />
      <h1><span>50% OFF</span> no seu próximo curso</h1>
    </div>
    <div class="description-container">
      <section class="text">
        <p>
          Continue desenvolvendo habilidades para atuar no mercado!
          É só aplicar o cupom a seguir no momento do seu pagamento:
        </p>

        <div>
          <h2>Use o cupom:</h2>
          <h3>LIFELONGLEARNING</h3>
        </div>
      </section>

      <button class="button" @click="openSite">
        Aproveitar o desconto
      </button>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'AniverteraBanner',
    data() {
      return {
        siteUrl: 'https://somostera.com/#presente'
      }
    },
    methods: {
      openSite() {
        window.open(this.siteUrl, '_blank')
      }
    }
  })
</script>

<style lang="scss">
  .banner-container-anivertera {
    font-family: "Rubik", sans-serif;
    margin: 0 0 60px 0;
    width: 100%;
    display: block;
    padding: 10px;
    background: #8ffe81;
    box-sizing: border-box;

    .title-container {
      background: #fff;
      padding: 40px 64px 16px;

      > div {
        margin: 0;
        padding: 0;
        height: 8px;
        width: 48px;
        background: #000;
      }

      > h1 {
        font-size: 48px;
        max-width: 420px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 12px 0 0 0;
        padding: 0;
        font-weight: 900;
        letter-spacing: -0.02em;

        > span {
          background: #8ffe81;
          margin: 0;
          padding: 0;
        }
      }
    }

    .description-container {
      background: #8ffe81;
      padding: 16px 64px 40px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .text {
        > p {
          max-width: 576px;
          font-size: 20px;
          line-height: 1.5;
        }

        > div {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;

          > h2, h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 900;
            text-transform: uppercase;
            padding: 6px;
          }

          > h2 {
            background: #fff;
            border: 2px solid transparent;
          }

          > h3 {
            background: #8ffe81;
            border: 2px solid #000;
          }
        }
      }

      .button {
        font-family: "Rubik", sans-serif;
        background: #000;
        color: #fff;
        border: 0;
        padding: 12px 48px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    @media(max-width: 900px) {
      .title-container {
        align-items: center;
        justify-content: center;
        padding: 20px 20px 16px;

        > h1 {
          font-size: 30px;
        }
      }
      .description-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;

        .text {
          width: 100%;
          > p {
            margin: 0;
            background: #fff;
            padding: 0 20px 0;
            font-size: 16px;
            width: 100%;
            max-width: calc(100% - 40px);
          }

          > div {
            background: #fff;
            padding: 20px 20px 70px;

            > h2, h3 {
              font-size: 14px;
            }

            > h3 {
              background: #fff;
            }
          }
        }

        .button {
          margin: 100px 30px 30px;
          font-size: 14px;
          padding: 16px 40px;
        }
      }
    }

  }
</style>
