import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import Component from './Component.vue';
export const FileDownload = Node.create({
    name: 'file-download',
    group: 'block',
    atom: true,
    defaultOptions: {
        fileName: '',
        mimeType: '',
        url: '',
        fileSize: 0,
        componentSize: 'small',
        HTMLAttributes: {}
    },
    addAttributes() {
        return {
            fileName: {
                default: ''
            },
            mimeType: {
                default: ''
            },
            url: {
                default: null
            },
            fileSize: {
                default: 0
            },
            componentSize: {
                default: 'small',
                rendered: false
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div'
            }
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },
    addCommands() {
        return {
            setFileDownload: options => ({ commands }) => commands.insertContent({
                type: this.name,
                attrs: options
            })
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(Component);
    }
});
