<template>
  <card-router-link :route="routeToGo">
    <rectangular-card :background-color="backgroundColor" class="journey-block-card">
      <div class="responsive-image-container">
        <img
          class="image"
          :class="{ 'no-image': imageDidNotLoad }"
          :src="liveBlock.imageUrl || ''"
          :alt="liveBlock.name || ''"
          @error="imageLoadError"
          @load="imageLoadSuccess"
        />
      </div>
      <template v-slot:left>
        <div v-if="liveEnded" class="icon-container">
          <icon class="icon" name="ic_video" stroke="black" aria-label="Icon video box" />
        </div>
        <div v-else class="date-container">
          <span class="date">{{ formattedDay }}</span>
          <p class="month">{{ formattedMonth }}</p>
        </div>
      </template>
      <template v-slot:content>
        <div class="content-container">
          <heading class="title" tag="h3">{{ liveBlock.name }}</heading>
          <heading v-if="speakersNames" class="subtitle" font-weight="normal" tag="h4" text-transform="none">
            {{ `Com: ${speakersNames.join(' • ')}` }}
          </heading>
        </div>
      </template>
      <template v-slot:footer>
        <div class="info-container -font-gray-80">
          <heading v-if="!liveEnded" class="event-hours" font-weight="normal" tag="h4" text-transform="none">
            {{ formattedHoursAndMinutes }}
          </heading>
          <heading
            v-if="liveEnded && eventDuration"
            class="event-hours"
            tag="h4"
            font-weight="normal"
            text-transform="none"
          >
            {{ eventDuration }}
          </heading>
        </div>
      </template>
      <template v-slot:right>
        <div class="image-container">
          <img
            class="image"
            :src="liveBlock.imageUrl || ''"
            :alt="liveBlock.name || ''"
            :class="{ 'no-image': imageDidNotLoad }"
            @error="imageLoadError"
            @load="imageLoadSuccess"
          />
        </div>
      </template>
    </rectangular-card>
  </card-router-link>
</template>

<script>
import LiveBlock from '@/core/entities/models/JourneyBlocks/LiveBlock'
import CardRouterLink from '@/core/components/CardRouterLink'

export default {
  name: 'LiveBlockCard',
  components: {
    CardRouterLink
  },
  props: {
    route: {
      type: Object,
      default: null,
      validator: (value) => {
        if (value && typeof value === 'object') {
          return !!(value.name || value.href)
        }
        return value == null
      }
    },
    liveBlock: {
      type: LiveBlock,
      default: () => {
        return new LiveBlock('', 0, 0, '')
      },
      required: true
    }
  },
  data() {
    return {
      imageDidNotLoad: false,
      speakersNames: []
    }
  },
  computed: {
    routeToGo() {
      return {
        name: 'Live',
        params: {
          teraId: this.liveBlock.teraId,
          journeyBlockId: this.liveBlock.id
        }
      }
    },
    startTimeParsed() {
      return this.liveBlock.startTime ? this.liveBlock.startTime : null
    },
    endTimeParsed() {
      return this.liveBlock.endTime ? this.liveBlock.endTime : null
    },
    liveEnded() {
      if (!this.liveBlock.endTime || this.liveBlock.endTime > new Date()) {
        return false
      }

      var tzDifference = this.liveBlock.endTime.getTimezoneOffset()
      var offsetTime = new Date(this.liveBlock.endTime.getTime() + tzDifference * 60 * 1000)

      return offsetTime < new Date()
    },
    backgroundColor() {
      return this.liveBlock.isSpecialEvent ? 'green-to-blue' : undefined
    },
    formattedMonth() {
      if (!this.startTimeParsed) return '---'

      const monthNames = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEC']
      return monthNames[this.startTimeParsed.getMonth()]
    },
    formattedDay() {
      if (!this.startTimeParsed) return '--'

      return this.startTimeParsed.getDate().toString().padStart(2, '0')
    },
    formattedHours() {
      if (!this.startTimeParsed) return ''

      return `${this.startTimeParsed.getUTCHours()}H`
    },
    formattedHoursAndMinutes() {
      if (!this.startTimeParsed) return ''

      var hours = `${this.startTimeParsed.getUTCHours()}h${
        this.startTimeParsed.getUTCMinutes() > 0 ? this.startTimeParsed.getUTCMinutes() : ''
      }`
      if (this.endTimeParsed) {
        hours += ` - ${this.endTimeParsed.getUTCHours()}h${
          this.endTimeParsed.getUTCMinutes() > 0 ? this.endTimeParsed.getUTCMinutes() : ''
        }`
      }

      return hours
    },
    eventDuration() {
      if (!this.endTimeParsed || !this.startTimeParsed) return null

      let diff = (this.endTimeParsed.getTime() - this.startTimeParsed.getTime()) / 1000
      diff /= 60
      const durationInMinutes = Math.abs(Math.round(diff))

      const minutes = durationInMinutes % 60
      const hours = durationInMinutes >= 60 ? (durationInMinutes - minutes) / 60 : 0

      let cardDuration = ''

      if (hours > 0) {
        cardDuration += hours + 'h'
      }
      if (minutes > 0) {
        cardDuration += minutes + 'min'
      }

      return cardDuration
    }
  },
  mounted() {
    this.getSpeakersNames()
  },
  methods: {
    getSpeakersNames() {
      if (!this.liveBlock || !this.liveBlock.speakersRefs) return

      this.liveBlock.speakersRefs.forEach((speakerRef) => {
        if (speakerRef?.get) {
          speakerRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.speakersNames.push(doc.data().name)
              } else {
                console.log('No such document!')
              }
            })
            .catch((error) => {
              console.log('Error getting document:', error)
            })
        }
      })
    },
    imageLoadError() {
      this.imageDidNotLoad = true
    },
    imageLoadSuccess() {
      this.imageDidNotLoad = false
    }
  }
}
</script>

<style lang="scss" scoped>
@include breakpoint-small {
  v::deep .responsive-image-container {
    object-fit: none;
  }
}

.journey-block-card {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  > .responsive-image-container {
    display: none;
    min-height: 312px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    > .image {
      width: 100%;
      height: 100%;
      position: absolute;

      &.no-image {
        display: none !important;
      }
    }

    @include breakpoint-small {
      display: block;
      object-fit: cover;
    }
  }

  > .slot-container {
    > .aside-left {
      > .icon-container {
        padding: $space-s 0;
        box-sizing: inherit;
        display: flex;
        flex-wrap: wrap;
        flex-flow: column;
        align-items: center;
      }

      > .date-container {
        padding: $space-s 0;
        width: 80px;
        box-sizing: inherit;
        display: flex;
        flex-wrap: wrap;
        flex-flow: column;
        align-items: center;

        > .date {
          font-weight: $font-weight-light;
          font-family: $font-rubik;
          font-size: $font-size-m;
          line-height: $font-size-m;
        }

        > .month {
          margin-top: 4px;
          font-family: $font-rubik;
          font-size: $font-size-s;
        }
      }
    }

    > .content {
      > .content-container {
        padding: $space-s;
        box-sizing: inherit;

        @include breakpoint-small {
          padding-left: $space-xxs;
        }

        > .subtitle {
          margin-top: 8px;
        }
      }

      > .footer {
        > .info-container {
          padding: $space-s;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;

          @include breakpoint-small {
            padding-left: $space-xxs;
          }
        }
      }
    }

    > .aside-right {
      > .image-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        > .image {
          width: auto;
          height: 100%;
          position: absolute;
          transform: translateX(-50%) translateY(-50%);
          top: 50%;
          left: 50%;

          &.no-image {
            display: none !important;
          }
        }
      }
    }
  }
  &.-inactive {
    .image {
      filter: grayscale(1);
      opacity: 0.8;
    }
  }
}
</style>
