import { setOptionalParameters } from '../../utils/classUtils'

class Journey {
  constructor(name, courseInfo, teraId, startsAt, endsAt, hidden, optionalAttributes) {
    this.name = name;
    this.courseInfo = courseInfo;
    this.teraId = teraId;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    hidden = hidden;

    setOptionalParameters(this, optionalAttributes)
  }

  id = "";
  modules = [];
  category = "";
  bootcampLead = "";
  coordinatorId = "";
  facilitatorId = "";
  studentsIds = [];
  calendarId = "";
  calendarPublicUrl = "";
  journeyBlocks = [];
  visualization = "";
  zoomHostEmail = "";
  zoomHostId = "";
  credentialRules = {};
}

export default Journey;
