<template>
  <div class="last-step">
    <div class="clap-container">
      <img :src="clapIcon" />
    </div>

    <h1>Aula avaliada com sucesso!</h1>

    <p>
      Obrigada pelo seu feedback!
    </p>

    <button @click="backToFirstStep">Refazer</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'RateArticle',
  props: {
    changeStep: Function,
  },
  data() {
    return {
      clapIcon: require('../../../assets/icons/clap.svg'),
    }
  },
  computed: {
    ...mapGetters('journeys', ['currentJourneyArticle'])
  },
  mounted() {
    localStorage.setItem(`@tera/answered-${this.currentJourneyArticle.id}`, true)
  },
  methods: {
    backToFirstStep() {
      localStorage.removeItem(`@tera/answered-${this.currentJourneyArticle.id}`)
      this.changeStep(0)
    }
  }
})
</script>

<style lang="scss">

.last-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #383838;
  color: #fff;
  padding: 32px 0 24px;
  text-align: center;

  .clap-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #8dfe7e;
  }

  > h1 {
    font-size: 24px;
    margin: 24px 0 0 0;
    text-transform: uppercase;

  }

  > p {
    margin: 8px 0 0 0;
    color: #bfbfbf;
    width: 403px;
    text-align: center;
  }


  > button {
    font-family: 'Rubik', sans-serif;
    margin-top: 93px;
    padding: 9px 32px;
    color: #000000;
    background: #fff;
    border-radius: 8px;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
      transform: translate(1px, 1px);
    }
  }

  @media(max-width: 800px) {
    > h1 {
      width: 90%;
    }

    > p {
      width: 90%;
    }
  }
}


</style>
