<template>
  <div v-if="currentJourneyBlock" class="class">
    <div class="header">
      <base-header type="header" class="journey-header">
        <template v-slot:default>
          <heading-with-before color="white" :before-color-variation="true">
            {{ blockName }}
          </heading-with-before>
        </template>
        <template v-slot:footer>
          <heading tag="h4" color="white">{{ dateFormatted }}</heading>
        </template>
      </base-header>
    </div>
    <class-tab-menu :has-content="showContentTab" :active-tab="activeTab" @input="activeTab = $event"> </class-tab-menu>
    <transition name="fade">
      <main-container v-if="activeTab === 'about'" :key="`about-tab`">
        <class-live-embed
          v-if="currentJourneyBlock.startTime && currentJourneyBlock.endTime"
          :src="currentJourneyBlock.transmissionUrl"
          :journey-icon="currentUserJourney.courseInfo.iconUrl"
          :start-time="currentJourneyBlock.startTime"
          :end-time="currentJourneyBlock.endTime"
          :question-src="currentJourneyBlock.answersPlatformUrl"
        ></class-live-embed>
        <large-gradient-border-card v-for="(speaker, i) in speakersInfo" :key="i" class="expert-presentation-container">
          <template v-slot:left>
            <img class="expert-photo" :src="speaker.pictureUrl" :alt="`Palestrante: ${speaker.name}`" />
          </template>
          <template v-slot:content>
            <heading tag="h3" font-style="normal" font-weight="light">
              {{ speaker.name }}
            </heading>
            <heading
              class="role-and-company"
              tag="h4"
              font-style="normal"
              font-weight="light"
              text-transform="none"
              color="gray-80"
            >
              {{ speaker.jobTitle }} .
              {{ speaker.company }}
            </heading>
            <paragraph class="description -font-weight-normal">
              {{ speaker.description }}
            </paragraph>
            <a
              v-if="speaker.profileUrl"
              :href="speaker.profileUrl"
              target="_blank"
              class="linkedIn"
              @click="clickSpeaker"
            >
              <icon name="ic_linkedin" fill="color_white" />
            </a>
          </template>
        </large-gradient-border-card>
        <div v-if="currentJourneyBlock.description" class="description">
          <heading tag="h4">Sobre a aula</heading>
          <paragraph>{{ currentJourneyBlock.description }}</paragraph>
        </div>
        <div v-if="showDownloadableContents" class="downloadable-contents">
          <div v-if="userIsFacilitatorOrCoordinator" class="upload-downloadable-content">
            <label for="uploadmaterial">
              <input
                id="uploadmaterial"
                type="file"
                name="uploadmaterial"
                accept="image/jpeg,image/png,application/pdf"
                :disabled="isUploadingMaterial"
                @change="uploadDownloadableContents($event)"
              />
            </label>
          </div>
          <a
            v-for="(beforeClass, i) in currentJourneyBlock.downloads"
            :key="'downloads_' + i"
            class="styleless-link download-content-card"
            target="_blank"
            :href="beforeClass.url"
            @click="downloadClick(beforeClass)"
          >
            <small-rectangle-base-card>
              <template v-slot:content>
                <div class="heading">
                  <a
                    v-if="userIsFacilitatorOrCoordinator"
                    class="-delete"
                    href="#"
                    @click="removeDownloadableSpecificContent(beforeClass, i)"
                  >
                    X
                  </a>
                  <heading tag="h4" color="white" font-weight="normal">{{ beforeClass.name }}</heading>
                </div>
              </template>
              <template v-slot:footer>
                <p class="-font-gray-60 -font-rubik">
                  {{ beforeClass.sizeInMb + 'mb' }}
                </p>
              </template>
              <template v-slot:right>
                <icon name="ic_download_white" fill="color_white" />
              </template>
            </small-rectangle-base-card>
          </a>
          <a
            v-for="(beforeClass, i) in currentJourneyBlock.fileBeforeClass"
            :key="'fileBeforeClass_' + i"
            class="styleless-link download-content-card"
            target="_blank"
            :href="beforeClass.url"
            @click="downloadClick(beforeClass)"
          >
            <small-rectangle-base-card>
              <template v-slot:content>
                <div class="heading">
                  <a
                    v-if="userIsFacilitatorOrCoordinator"
                    class="-delete"
                    href="#"
                    @click="removeDownloadableSpecificContent(beforeClass, i)"
                  >
                    X
                  </a>
                  <heading tag="h4" color="white" font-weight="normal">{{ beforeClass.name }}</heading>
                </div>
              </template>
              <template v-slot:footer>
                <p class="-font-gray-60 -font-rubik">
                  {{ beforeClass.sizeInMb + 'mb' }}
                </p>
              </template>
              <template v-slot:right>
                <icon name="ic_download_white" fill="color_white" />
              </template>
            </small-rectangle-base-card>
          </a>
        </div>
        <div v-if="currentJourneyBlock.outcomes && currentJourneyBlock.outcomes.length > 0" class="objectives">
          <heading tag="h4">Objetivos</heading>
          <ul class="list">
            <li v-for="(outcome, i) in currentJourneyBlock.outcomes" :key="'outcomes_' + i" class="item">
              <span v-if="outcome">{{ outcome }}</span>
            </li>
          </ul>
        </div>
      </main-container>
      <main-container v-else :key="`content-tab`">
        <div v-if="currentJourneyBlock.contentBeforeClass.length > 0" class="content-before-class">
          <heading tag="h4">Prepare-se para a aula</heading>
          <div class="content-container">
            <div class="card-container">
              <div
                v-for="(content, i) in currentJourneyBlock.contentBeforeClass.filter((c) => c.type === 'originals')"
                :key="'contentBeforeClass_originals_' + i"
                @click="clickAfterClass(content.ref.title)"
              >
                <material-card
                  :title="getOriginalsTitleForBeforeClassContent(content)"
                  :subtitle="`Tera Originals`"
                  :route="{
                    name: 'Originals',
                    params: {
                      originalsUrl: getOriginalsURLForBeforeClassContent(content)
                    }
                  }"
                ></material-card>
              </div>
              <div
                v-for="(content, i) in currentJourneyBlock.contentBeforeClass.filter((c) => c.type !== 'originals')"
                :key="'contentBeforeClass_' + i"
                @click="clickAfterClass(content.title)"
              >
                <material-card
                  :title="content.title"
                  :subtitle="`${content.type[0].toUpperCase() + content.type.substring(1)} • ${
                    content.expectedMinutes
                  }min`"
                  :route="{ href: content.url, target: '_blank' }"
                ></material-card>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentJourneyBlock.contentAfterClass.length > 0" class="content-before-class">
          <heading tag="h4">Entenda mais</heading>
          <div class="content-container">
            <div class="card-container" @click="clickAfterClass('Biblioteca de Links para Aprofundamento')">
              <material-card
                title="Biblioteca de Links para Aprofundamento"
                subtitle="Link externo"
                :route="{
                  href: currentJourneyBlock.contentAfterClass,
                  target: '_blank'
                }"
              ></material-card>
            </div>
          </div>
        </div>
      </main-container>
    </transition>
  </div>
</template>

<script>
import MainContainer from '@/core/components/MainContainer'
import ClassTabMenu from '@/modules/Journeys/components/ClassTabMenu'
import MaterialCard from '@/modules/Journeys/components/MaterialCard'
import ClassLiveEmbed from '@/modules/Journeys/components/ClassLiveEmbed'

import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

export default {
  name: 'Live',
  components: {
    MainContainer,
    ClassTabMenu,
    MaterialCard,
    ClassLiveEmbed
  },
  props: {
    teraId: {
      type: String,
      required: true
    },
    journeyBlockId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'about',
      classSeen: false,
      isUploadingMaterial: false,
      speakersInfo: []
    }
  },
  computed: {
    userIsFacilitatorOrCoordinator() {
      if (!this.currentJourneyPeople) {
        return false
      }

      const personRoles = this.currentJourneyPeople.type || []
      return personRoles.includes('facilitator') || personRoles.includes('coordinator')
    },
    showDownloadableContents() {
      const { currentJourneyBlock } = this
      return (
        (currentJourneyBlock.fileBeforeClass && currentJourneyBlock.fileBeforeClass.length > 0) ||
        (currentJourneyBlock.downloads && currentJourneyBlock.downloads.length > 0) ||
        this.userIsFacilitatorOrCoordinator
      )
    },
    blockName() {
      return this.currentJourneyBlock.name
    },
    dateFormatted() {
      if (!this.currentJourneyBlock) {
        return ''
      }

      const { startTime, endTime } = this.currentJourneyBlock
      if (!startTime) {
        return ''
      }

      const datePadded = String(startTime.getDate()).padStart(2, '0')
      const monthName = monthNames[startTime.getMonth()]
      const fullYear = startTime.getFullYear()
      const startHours = startTime.getUTCHours()
      const startMinutes = String(startTime.getUTCMinutes()).padStart(2, '0')
      const startTimeFormatted = startMinutes === '00' ? `${startHours}h` : `${startHours}h${startMinutes}`

      const endHours = endTime.getUTCHours()
      const endMinutes = String(endTime.getUTCMinutes()).padStart(2, '0')
      const endTimeFormatted = endMinutes === '00' ? `${endHours}h` : `${endHours}h${endMinutes}`

      return `${datePadded} de ${monthName} de ${fullYear} ${startTimeFormatted} - ${endTimeFormatted}`
    },
    childRouteParams() {
      return { teraId: this.teraId }
    },
    showContentTab() {
      const { contentBeforeClass, contentAfterClass } = this.currentJourneyBlock
      return contentBeforeClass.length > 0 || contentAfterClass !== ''
    },
    ...mapGetters('journeys', [
      'currentUserJourney',
      'currentJourneyBlock',
      'currentJourneyMoment',
      'currentJourneyPeople',
      'journeyBlocksHasUpdates',
      'currentExpert',
      'currentPlace',
      'currentUserOriginals'
    ]),
    ...mapGetters('account', ['user', 'person'])
  },
  watch: {
    activeTab() {
      const label = this.activeTab === 'about' ? 'sobre' : 'conteudo'
      Vue.$logEvent({ category: 'lxs:live', action: 'clique:tab', label })
    }
  },
  beforeMount() {
    this.setCurrentTeraId(this.teraId)
    this.setCurrentJourneyBlockId(this.journeyBlockId)
  },
  mounted() {
    const { journeys, modules, journeyClass } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyClass]

    this.setRouteBreadcrumb(breadcrumb)
    this.getSpeakersInfo()
  },
  methods: {
    clickSpeaker() {
      Vue.$logEvent({
        category: 'lxs:live:sobre',
        action: 'clique:link',
        label: 'linkedin-palestrante'
      })
    },
    clickAfterClass(contentTitle) {
      Vue.$logEvent({
        category: 'lxs:live:conteudo',
        action: 'clique:link',
        label: contentTitle
      })
    },
    downloadClick(beforeClass) {
      Vue.$logEvent({
        category: 'lxs:live:sobre',
        action: 'clique:card',
        label: `${beforeClass.name}:download`
      })
    },
    getOriginalsTitleForBeforeClassContent(content) {
      if (!this.currentUserOriginals) return
      const originals = this.currentUserOriginals.find((o) => o.id === content.refId)
      return originals.title
    },
    getOriginalsURLForBeforeClassContent(content) {
      if (!this.currentUserOriginals) return
      const originals = this.currentUserOriginals.find((o) => o.id === content.refId)
      return originals.url
    },
    uploadDownloadableContents(evt) {
      if (!this.userIsFacilitatorOrCoordinator) return

      const file = evt.target.files ? evt.target.files[0] : undefined

      if (file.name.indexOf('#') > -1) {
        alert("Por favor, não utilize o caractere '#' no nome do arquivo.")
        evt.target.value = ''
        return
      }

      const name = prompt('Qual o nome do material?')

      if (name) {
        this.isUploadingMaterial = true
        try {
          this.uploadDownloadableContent({ file, name })
          this.isUploadingMaterial = false
        } catch (error) {
          console.log(error)
        }
      } else {
        alert('Por favor, digite o nome do material para download')
      }
    },
    removeDownloadableSpecificContent(beforeClass, index) {
      if (!this.userIsFacilitatorOrCoordinator) return

      const mustDelete = confirm(`Você tem certeza que deseja excluir o arquivo "${beforeClass.name}"?`)

      if (mustDelete) {
        try {
          this.deleteSpecificDownloadableContent(this.currentJourneyBlock.downloads[index])
        } catch (error) {
          console.log(error)
        }
      }
    },
    getSpeakersInfo() {
      if (!this.currentJourneyBlock || !this.currentJourneyBlock.speakersRefs) return

      this.currentJourneyBlock.speakersRefs.forEach((speakerRef) => {
        speakerRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.speakersInfo.push({
                name: doc.data().name,
                company: doc.data().company,
                jobTitle: doc.data().jobTitle,
                description: doc.data().description,
                pictureUrl: doc.data().pictureUrl,
                profileUrl: doc.data().profileUrl
              })
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      })
    },
    ...mapActions('journeys', [
      'setCurrentTeraId',
      'setCurrentJourneyBlockId',
      'updateJourneyBlocksWithStream',
      'uploadDownloadableContent',
      'deleteSpecificDownloadableContent'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: right 0.2s, opacity 0.3s;
}
.fade-leave-active {
  transition: right 0.2s, opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
  right: 100%;
}

.class {
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  .main-container {
    width: $container-m;
    margin: $space-l auto 0;
    box-sizing: border-box;

    @include breakpoint-small {
      margin: $space-s auto 0;
      width: 100%;
    }

    .content-before-class {
      & + .content-before-class {
        margin-top: $space-l;
      }
    }

    .content-container {
      margin-top: $space-s;

      > .card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > * {
          margin-bottom: $space-s;
        }
      }
    }
  }

  .class-reminder {
    padding: 24px $space-s;

    @include breakpoint-small {
      align-items: center;

      ::v-deep > .content {
        margin-top: 0 !important;
        margin-bottom: $space-xs;
      }
    }

    .reminder-enter-button {
      height: auto;
      ::v-deep {
        z-index: unset;
      }
    }

    @include breakpoint-small {
      ::v-deep .content {
        width: fit-content;
        text-align: center;
        margin-top: $space-xs;
      }
    }
  }

  .expert-presentation-container {
    margin-top: $space-l;
    margin-bottom: $space-xl;

    ::v-deep .content {
      position: relative;
      padding: $space-s;

      > .role-and-company {
        margin-top: $space-xs / 2;
      }

      > .description {
        margin-top: $space-s;
      }
    }

    .expert-photo {
      width: 100%;
      object-fit: cover;
    }

    .linkedIn {
      position: absolute;
      top: $space-s;
      right: $space-s;
    }
  }

  .upload-downloadable-content {
    width: 100%;
    max-width: 368px;
    height: 160px;
    padding-bottom: $space-xs;

    > label {
      width: 50%;
      cursor: pointer;

      &:hover:after {
        background-color: $color-black;
        transition: all 0.4s;
      }

      &::after {
        content: 'Adicionar arquivo para download';
        text-align: center;
        max-width: 368px;
        width: 100%;
        line-height: 20px;
        height: 160px;
        font-size: 17px;
        background-color: #666;
        color: #fff;
        font-family: 'Rubik';
        text-transform: uppercase;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        padding: 30px;
        transition: all 0.4s;
      }

      &.-uploading {
        background-color: #333;
        display: inline-block;
        padding: 25px 110px;
        &:after {
          content: ' ';
          display: block;
          width: 46px;
          height: 46px;
          margin: 1px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #fff transparent #fff transparent;
          animation: lxs-uploading-material 1.2s linear infinite;
        }
        @keyframes lxs-uploading-material {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      [type='file'] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
      }
    }
  }

  .downloadable-contents {
    margin-top: $space-xl;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .download-content-card {
      width: 100%;
      max-width: 368px;
      max-height: 160px;

      @include breakpoint-medium {
        margin-bottom: $space-s;
      }
      @include breakpoint-small {
        margin-bottom: $space-s;
      }
    }
  }
}

.objectives {
  margin-top: $space-xl;
}

::v-deep h4.heading + .paragraph,
h4.heading + .list {
  margin-top: $space-xs;
}

::v-deep .header .footer-container h4 a {
  word-break: break-all;
}

::v-deep .small-rectangle-base-card {
  height: 100% !important;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: $space-xs;

    > .heading {
      width: 100%;
      max-width: 256px;

      > .-delete {
        font-family: $font-rubik;
        color: $color-white;
        float: right;

        &:hover {
          color: $color-gray-60;
        }
      }

      > h4 {
        max-width: 288px;
        position: initial !important;
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
      }
    }

    > .footer {
      font-family: $font-rubik;
      position: initial !important;
      bottom: initial !important;
      min-height: initial !important;
      margin-top: auto;
    }
  }

  .aside-right {
    position: initial !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;

    > span {
      position: initial !important;
    }
  }
}

.styleless-link {
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
}

.list {
  padding-left: $space-s;

  > .item {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

::v-deep .main-container {
  padding-bottom: 120px;
}

a {
  color: $color-blue-primary;
  text-decoration: none;

  &:visited {
    color: $color-blue-primary;
  }
}
</style>
