<template>
  <card-router-link :route="routeToGo" :journey="journey" :block="classBlock" @click="onClick()">
    <rectangular-card :background-color="backgroundColor" :class="cardClass">
      <div class="responsive-image-container">
        <img
          v-if="hasExpert"
          class="image"
          :class="{ 'no-image': imageDidNotLoad }"
          :src="expert.pictureUrl || ''"
          :alt="expert.name || ''"
          @error="imageLoadError"
          @load="imageLoadSuccess"
        />
      </div>
      <template v-slot:left>
        <div v-if="isDpwAndJourneyEnded" class="icon-container">
          <icon
            class="icon"
            name="ic_video"
            stroke="black"
            aria-label="Icon video box"
          />
        </div>
        <div v-else-if="showOnlyHour" class="date-container">
          <span class="date">{{ formattedHours }}</span>
        </div>
        <div v-else class="date-container">
          <span class="date">{{ formattedDay }}</span>
          <p class="month">{{ formattedMonth }}</p>
        </div>
      </template>
      <template v-slot:content>
        <div class="content-container">
          <heading class="title" tag="h3">{{ classBlock.name }}</heading>
          <heading
            v-if="hasExpert"
            class="subtitle"
            font-weight="normal"
            tag="h4"
            text-transform="none"
          >
            {{ `Com: ${expert.name}` }}
          </heading>
        </div>
      </template>
      <template v-slot:footer>
        <div :class="['info-container', infoContainerClass]">
          <heading
            v-if="placeName"
            class="journey-block-info"
            font-weight="normal"
            tag="h4"
            text-transform="none"
          >
            {{ placeName }}
          </heading>
          <heading
            v-if="!showOnlyHour"
            class="event-hours"
            font-weight="normal"
            tag="h4"
            text-transform="none"
          >
            {{ formattedHoursAndMinutes }}
          </heading>
          <heading
            v-if="showOnlyHour && eventDuration"
            class="event-hours"
            tag="h4"
            font-weight="normal"
            text-transform="none"
          >
            Duração: {{ eventDuration }}
          </heading>
        </div>
      </template>
      <template v-slot:right>
        <div class="image-container">
          <img
            v-if="hasExpert"
            class="image"
            :src="expert.pictureUrl || ''"
            :alt="expert.name || ''"
            :class="{ 'no-image': imageDidNotLoad }"
            @error="imageLoadError"
            @load="imageLoadSuccess"
          />
        </div>
      </template>
    </rectangular-card>
  </card-router-link>
</template>

<script>
import ClassBlock from '@/core/entities/models/JourneyBlocks/ClassBlock'
import CardRouterLink from '@/core/components/CardRouterLink'

import Journey from '@/core/entities/models/Journey'

import journeyService from '@/modules/Journeys/services/journeysService'

const monthNames = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEC'
]

export default {
  name: 'ClassBlockCard',
  components: {
    CardRouterLink
  },
  props: {
    onClick: {
      type: Function,
      default: () => {
        return 
      }
    },
    route: {
      type: Object,
      default: null,
      validator: (value) => {
        if (value && typeof value === 'object') {
          return !!(value.name || value.href)
        }
        return value == null
      }
    },
    classBlock: {
      type: ClassBlock,
      default: () => {
        return new ClassBlock('', 0, 0, '')
      },
      required: true
    },
    expert: {
      type: Object,
      default: () => {
        return { name: '', pictureUrl: '' }
      }
    },
    place: {
      type: Object,
      default: () => {
        return { name: '' }
      }
    },
    journey: Journey
  },
  data() {
    return {
      imageDidNotLoad: false
    }
  },
  computed: {
    isDpwAndJourneyEnded() {
      return journeyService.isDpwAndJourneyEnded(this.journey)
    },
    routeToGo() {
      return {
        name: 'Class',
        params: {
          teraId: this.classBlock.teraId,
          journeyBlockId: this.classBlock.id
        }
      }
    },
    hasExpert() {
      return (
        this.classBlock.expertStatus &&
        this.classBlock.expertStatus === 'aceito'
      )
    },
    placeName() {
      if (
        this.currentJourneyPlaces &&
        this.currentJourneyPlaces.length &&
        this.classBlock.placeId
      ) {
        const place = this.currentJourneyPlaces.find(
          (e) => e.id === this.classBlock.placeId
        )

        return place.name
      }

      return ''
    },
    startTimeParsed() {
      return this.classBlock.startTime ? this.classBlock.startTime : null
    },
    endTimeParsed() {
      return this.classBlock.endTime ? this.classBlock.endTime : null
    },
    isActive() {
      if (
        (this.journeyIsEvent && this.journey.endsAt < new Date()) ||
        !this.classBlock.endTime
      ) {
        return true
      }

      var tzDifference = this.classBlock.endTime.getTimezoneOffset()
      var offsetTime = new Date(
        this.classBlock.endTime.getTime() + tzDifference * 60 * 1000
      )

      return offsetTime > new Date()
    },
    journeyIsEvent() {
      return this.journey.category === 'event'
    },
    showOnlyHour() {
      return (
        this.journey.visualization === 'full-time' &&
        this.journey.endsAt > new Date()
      )
    },
    backgroundColor() {
      return this.classBlock.type === 'masterclass' && this.isActive
        ? 'green-to-blue'
        : undefined
    },
    formattedMonth() {
      if (!this.startTimeParsed) return '---'

      return monthNames[this.startTimeParsed.getMonth()]
    },
    formattedDay() {
      if (!this.startTimeParsed) return '--'

      return this.startTimeParsed.getDate().toString().padStart(2, '0')
    },
    formattedHours() {
      if (!this.startTimeParsed) return ''

      return `${this.startTimeParsed.getUTCHours()}H`
    },
    formattedHoursAndMinutes() {
      if (!this.startTimeParsed) return ''

      let hours = `${this.startTimeParsed.getUTCHours()}h${
        this.startTimeParsed.getUTCMinutes() > 0
          ? this.startTimeParsed.getUTCMinutes()
          : ''
      }`
      if (this.endTimeParsed) {
        hours += ` - ${this.endTimeParsed.getUTCHours()}h${
          this.endTimeParsed.getUTCMinutes() > 0
            ? this.endTimeParsed.getUTCMinutes()
            : ''
        }`
      }

      return hours
    },
    eventDuration() {
      if (!this.endTimeParsed || !this.startTimeParsed) return null

      let diff =
        (this.endTimeParsed.getTime() - this.startTimeParsed.getTime()) / 1000
      diff /= 60
      const durationInMinutes = Math.abs(Math.round(diff))

      const minutes = durationInMinutes % 60
      const hours =
        durationInMinutes >= 60 ? (durationInMinutes - minutes) / 60 : 0

      let cardDuration = ''

      if (hours > 0) {
        cardDuration += hours + 'h'
      }
      if (minutes > 0) {
        cardDuration += minutes + 'min'
      }

      return cardDuration
    },
    cardClass() {
      return this.isActive
        ? 'journey-block-card'
        : 'journey-block-card -font-gray-60 -inactive'
    },
    infoContainerClass() {
      return this.isActive ? '-font-gray-80' : '-font-gray-60'
    }
  },
  methods: {
    imageLoadError() {
      this.imageDidNotLoad = true
    },
    imageLoadSuccess() {
      this.imageDidNotLoad = false
    }
  }
}
</script>

<style lang="scss" scoped>
@include breakpoint-small {
  v::deep .responsive-image-container {
    object-fit: none;
  }
}

.journey-block-card {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  > .responsive-image-container {
    display: none;
    min-height: 312px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    > .image {
      width: 100%;
      height: 100%;
      position: absolute;

      &.no-image {
        display: none !important;
      }
    }

    @include breakpoint-small {
      display: block;
      object-fit: cover;
    }
  }

  > .slot-container {
    > .aside-left {
      > .icon-container {
        padding: $space-s 0;
        box-sizing: inherit;
        display: flex;
        flex-wrap: wrap;
        flex-flow: column;
        align-items: center;
      }

      > .date-container {
        padding: $space-s 0;
        width: 80px;
        box-sizing: inherit;
        display: flex;
        flex-wrap: wrap;
        flex-flow: column;
        align-items: center;

        > .date {
          font-weight: $font-weight-light;
          font-family: $font-rubik;
          font-size: $font-size-m;
          line-height: $font-size-m;
        }

        > .month {
          margin-top: 4px;
          font-family: $font-rubik;
          font-size: $font-size-s;
        }
      }
    }

    > .content {
      > .content-container {
        padding: $space-s;
        box-sizing: inherit;

        @include breakpoint-small {
          padding-left: $space-xxs;
        }

        > .subtitle {
          margin-top: 8px;
        }
      }

      > .footer {
        > .info-container {
          padding: $space-s;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;

          @include breakpoint-small {
            padding-left: $space-xxs;
          }
        }
      }
    }

    > .aside-right {
      > .image-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        > .image {
          width: auto;
          height: 100%;
          position: absolute;
          transform: translateX(-50%) translateY(-50%);
          top: 50%;
          left: 50%;

          &.no-image {
            display: none !important;
          }
        }
      }
    }
  }
  &.-inactive {
    .image {
      filter: grayscale(1);
      opacity: 0.8;
    }
  }
}
</style>
