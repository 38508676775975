<template>
  <div></div>
</template>

<script>
import { firebase } from '@/core/services/firebase'
import { mapActions } from 'vuex'
import journeyService from '@/modules/Journeys/services/journeysService'
import { showCommunityOnNavbar } from '@/core/services/remote-config'

export default {
  name: 'LoginCommunity',

  async mounted () {
    this.toggleLoading(true)

    const user = firebase.auth().currentUser

    const journeyPeople = await journeyService.getJourneyPeopleByAuthId(user.uid)

    if (journeyPeople.length === 0) {
      console.error('User not found in any journey')
      return
    }

    for (const journey of journeyPeople) {
      if (showCommunityOnNavbar(journey.teraId)) {
        let jwt = await this.loginToCommunity()
        jwt = await jwt.text()

        window.location = `https://comunidade.somostera.com/api/auth/sso?jwt=${jwt}`
      }
    }

    console.error('User not found in any journey')
    return
  },
  methods: {
    ...mapActions('journeys', ['toggleLoading']),
    async loginToCommunity () {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let token = await firebase.auth().currentUser.getIdToken();

        return fetch(process.env.VUE_APP_LOGIN_COMMUNITY, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            __session: token
          },
          referrerPolicy: 'no-referrer'
        })

      } catch (e) { }
    }
  }
}
</script>

<style></style>
