import Place from './index';

const placeConverter = {
  fromFirestore (snapshot, options) {
    const data = snapshot.data(options);
    var place = new Place(
      data.address,
      data.name,
      data.photo
    );

    return place;
  }
};

export default placeConverter;
