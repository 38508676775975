const state = {
  routeBreadcrumb: null,
  breadcrumbKey: 'default',
  routeContext: '',
  routeHistory: []
}

const getters = {
  routeBreadcrumb: state => {
    return state.routeBreadcrumb
  },
  routeContext: state => {
    return state.routeContext
  },
  routeHistory: state => {
    return state.routeHistory
  },
  routeHasHistory: state => {
    return state.routeHistory.length > 0
  }
}

const actions = {
  setRouteBreadcrumb({ commit }, routeBreadcrumb) {
    commit('setRouteBreadcrumb', routeBreadcrumb)
  },
  async setRouteContext({ commit }, context) {
    commit('setRouteContext', context)
  },
  addRouteHistory({ commit }, routeHistory) {
    commit('addRouteHistory', routeHistory)
  },
  popRouteHistory({ commit }) {
    commit('popRouteHistory')
  },
  resetState({ commit }) {
    commit('resetState')
  }
}

const mutations = {
  setRouteBreadcrumb(state, routeBreadcrumb) {
    state.routeBreadcrumb = routeBreadcrumb
  },
  setRouteContext(state, context) {
    state.routeContext = context
  },
  addRouteHistory(state, routeHistory) {
    state.routeHistory.push(routeHistory)
  },
  popRouteHistory(state) {
    state.routeHistory.pop()
  }
}

export const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
