import articleBlockConverter from "./ArticleBlock/converters.js";
import classBlockConverter from "./ClassBlock/converters.js";
import liveBlockConverter from "./LiveBlock/converters.js";
import originalsBlockConverter from "./OriginalsBlock/converters.js";

const blockConverterFactory = {
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    switch (data.type) {
      case "originals":
        return originalsBlockConverter.fromFirestore(snapshot, options);
      case "article":
        return articleBlockConverter.fromFirestore(snapshot, options);
      case "live":
        return liveBlockConverter.fromFirestore(snapshot, options);
      default:
        return classBlockConverter.fromFirestore(snapshot, options);
    }
  },
};

export default blockConverterFactory;
