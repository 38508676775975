var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import f from 'firebase';
import { app } from '@/core/services/firebase';
const remote = f.remoteConfig(app);
remote.settings = {
    fetchTimeoutMillis: 60000,
    minimumFetchIntervalMillis: 1
};
remote.defaultConfig = ({
    'sync_class': '',
    'showBannerFreemium': '',
    'showAniverteraBanner': false,
    'show_community_navbar': '',
    'quiz_limited_answers': '',
    'show_progress_bottom_bar': '',
    'show_progress_footer': '',
    'show_referral_banner': false,
    'show_user_progress_on_card': '',
    'tabs_lives_instead_of_modules': ''
});
(() => __awaiter(void 0, void 0, void 0, function* () {
    yield remote.fetchAndActivate();
}))();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const syncClass = (teraId) => {
    return true;
};
export const showCommunityBanner = (teraId) => {
    const courses = "DPLOD_REM_2022_1,DPLOD_REM_2023_1,DPDOD_REM_2023_1,DACOD_REM_2023_1,PDCOD_REM_2023_1".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const showEvaluationArticle = (teraId) => {
    const evaluationArticleOnlyOnDemand = true;
    const teraIdIsOnDemand = teraId.toUpperCase().includes('OD', 3);
    if (!evaluationArticleOnlyOnDemand)
        return true;
    if (evaluationArticleOnlyOnDemand && teraIdIsOnDemand)
        return true;
    return false;
};
export const freemiumBanner = (teraId) => {
    const showBanner = false;
    const teraIdIsFreemium = teraId.toUpperCase().includes('FR', 3);
    return teraIdIsFreemium && showBanner;
};
export const showCommunityOnNavbar = (teraId) => {
    const courses = "TSTIN_REM_2022_2,DPLOD_REM_2022_1,DMDPE_REM_2022_2,DMDPE_REM_2022_3,DMDPE_REM_2022_4,PKTPE_REM_2022_9,PKTPE_REM_2022_10,DPLOD_REM_2022_2,DPLOD_REM_2023_1,DPDOD_REM_2023_1,DACOD_REM_2023_1,PDCOD_REM_2023_1".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const shouldUseQuizWithLimitedAnswers = (teraId) => {
    const courses = "TSTIN_REM_2022_2,DPLOD_REM_2022_1,DPLOD_REM_2022_2".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const showProgressBottomBar = (teraId) => {
    const courses = "TSTIN_REM_2022_2,DPLOD_REM_2022_1,DPLOD_REM_2022_2,DPLOD_REM_2023_1,DPDOD_REM_2023_1,DACOD_REM_2023_1,PDCOD_REM_2023_1".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const showProgressOnFooter = (teraId) => {
    const courses = "TSTIN_REM_2022_2,DPLOD_REM_2022_1,DPLOD_REM_2022_2,DPLOD_REM_2023_1,DPDOD_REM_2023_1,DACOD_REM_2023_1,PDCOD_REM_2023_1".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const showReferralBanner = () => {
    return remote.getValue('show_referral_banner').asBoolean();
};
export const showUserProgressOnCard = (teraId) => {
    const courses = "TSTIN_REM_2022_2,DPLOD_REM_2022_1,DMDPE_REM_2022_2,DMDPE_REM_2022_3,DMDPE_REM_2022_4,DPLOD_REM_2022_2,DPLOD_REM_2023_1,DPDOD_REM_2023_1,DACOD_REM_2023_1,PDCOD_REM_2023_1".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const tabsWithLivesInsteadOfModules = (teraId) => {
    const courses = "DPLOD_REM_2023_1,DPDOD_REM_2023_1,DACOD_REM_2023_1,PDCOD_REM_2023_1,DPLFR_REM_2022_1,DPDFR_REM_2023_1".split(',');
    if (courses[0] === 'all') {
        return true;
    }
    for (const element of courses) {
        if (element.trim() === teraId) {
            return true;
        }
    }
    return false;
};
export const showAniverteraBanner = (userJourneys) => {
    const showBanner = false;
    const teraIdsFreeCourses = ['DPWEV', 'MKTEV', 'IPMAD', 'FR'];
    const hasPaidJourney = userJourneys === null || userJourneys === void 0 ? void 0 : userJourneys.some(journey => {
        const isFree = teraIdsFreeCourses.some(freeTeraId => journey.teraId.toUpperCase().includes(freeTeraId));
        return !isFree;
    });
    return showBanner && hasPaidJourney;
};
