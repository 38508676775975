<template>
  <div class="banner-container-community">
    <img :src="baloonIcon" />

    <h1>Ficou alguma dúvida?</h1>

    <p>
      Acesse a nossa comunidade para compartilhar perguntas, experiências e aprendizados durante a sua jornada.
    </p>

    <button @click="goToCommunity">
      Acessar comunidade
    </button>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import amplitude from 'amplitude-js'


  export default Vue.extend({
    name: 'CommunityBanner',
    data() {
      return {
        baloonIcon: require('../../../assets/baloon.svg'),
        communityUrl: 'https://comunidade.somostera.com/collection/PEuFhZndOE8L'
      }
    },
    computed: {
      ...mapGetters('journeys', ['currentTeraId', 'currentModuleIndex']),

    },
    methods: {
      goToCommunity() {
        amplitude.getInstance().logEvent(
          `Clicou no banner da comunidade`,
          {
            modulo: this.currentModuleIndex + 1,
            teraId: this.currentTeraId,
          }
        )
        window.open(this.communityUrl, '_blank')
      }
    }
  })
</script>

<style lang="scss">
  .banner-container-community {
    font-family: "Rubik", sans-serif;
    width: 100%;
    display: block;
    padding: 16px 45px;
    background: #f2f2f2;
    box-sizing: border-box;
    max-width: 560px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h1 {
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      margin: 8px 0 0;
      text-transform: uppercase;
      letter-spacing: -0.02em;
    }

    > p {
      margin: 8px 0 0;
      text-align: center;
      font-size: 14px;
      line-height: 21px;
    }

    > button {
      font-family: "Rubik", sans-serif;
      background: #8ffe81;
      color: #000;
      margin: 16px 0 0;
      border: 0;
      padding: 12px 24px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }

    @media(max-width: 900px) {
      padding: 16px 12px;
    }

  }
</style>
