export const getNumberOfWords = chapters => {
  let words = 0

  if (chapters) {
    chapters.forEach(chapter => {
      if (chapter.content && chapter.content.content) {
        chapter.content.content.forEach(node => {
          if (node && node.content) {
            node.content.forEach(content => {
              if (content.text) {
                words += content.text.split(' ').length
              }
            })
          }
        })
      }
    })
  }

  return words
}

export const getReadingTime = chapters => {
  if (chapters) {
    const AVERAGE_SPEED = 260 // words per minute average
    const numberOfWords = getNumberOfWords(chapters)

    return Math.ceil(numberOfWords / AVERAGE_SPEED) + getDurationOfVideos(chapters)
  }

  return 0
}

export const getNumberOfVideos = chapters => {
  let numberOfVideos = 0
  if (chapters) {
    chapters.forEach(chapter => {
      if (chapter.content && chapter.content.content) {
        chapter.content.content.forEach(node => {
          if (node && node.type) {
            if (node.type.toLowerCase().includes('custom-video')) {
              numberOfVideos += 1
            }
          }
        })
      }
    })
  }
  return numberOfVideos
}

export const getDurationOfVideos = chapters => {
  let minutes = 0
  if (chapters) {
    chapters.forEach(chapter => {
      if (chapter.content && chapter.content.content) {
        chapter.content.content.forEach(node => {
          if (node && node.type) {
            if (node.type.toLowerCase().includes('custom-video')) {
              minutes += Math.ceil(node.attrs.duration / 60)
            }
          }
        })
      }
    })
  }
  return minutes
}

export const getProgressPercentage = (numberOfChapters, numberOfChaptersDone) => {
  if (numberOfChaptersDone > numberOfChapters) return 100

  return Math.floor((numberOfChaptersDone / numberOfChapters) * 100)
}
