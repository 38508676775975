<template>
  <div class="navbar">
    <div class="content">
      <div class="logo">
        <router-link :to="{ name: 'Journeys' }">
          <img src="@/assets/logo_lxs_v2.svg" alt="Logo da Tera" @click="logEvent('logo-tera')" />
        </router-link>
      </div>
      <div class="navbar-items">
        <ul>
          <li v-if="showCommunityOnNavbar">
            <a href="https://app.somostera.com/community" target="_blank">Comunidade</a>
          </li>
        </ul>
      </div>

      <div class="user">
        <span @click="showUserMenu">
          <img v-show="activeNotificationDot" class="notification-visibility notification"
               src="../../assets/circle_notification.svg"
          />
          <img v-if="user && !imageDidNotLoad" :src="imageDidNotLoad ? userGeneratedProfilePicture : user.photoURL"
               @error="imageLoadError" @load="imageLoadSuccess"
          />
          <img v-else :src="userGeneratedProfilePicture" />
        </span>
        <transition name="fade-to-bottom">
          <div v-if="userMenuIsOpen" v-on-clickaway="hideUserMenu" class="user-menu">
            <ul>
              <li v-if="showCommunityOnNavbar" class="mobile">
                <a href="https://app.somostera.com/community" target="_blank">Comunidade</a>
              </li>
              <li @click="openHelpForms">
                Ajuda
              </li>
              <li @click="openTerms">
                Termos de uso
              </li>
              <li @click="logout">Sair</li>
              <li>Tera - LXS</li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from '@/core/services/firebase'
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import Vue from 'vue'
import amplitude from 'amplitude-js'
import { showCommunityOnNavbar } from '@/core/services/remote-config'

export default {
  name: 'Navbar',
  mixins: [clickaway],
  status: 'prototype',
  release: '0.0.1',
  data () {
    return {
      userMenuIsOpen: false,
      activeNotificationDot: false,
      imageDidNotLoad: false
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['userJourneys']),
    userGeneratedProfilePicture () {
      if (!this.user) return ''
      return `https://ui-avatars.com/api/?name=${encodeURIComponent(
        this.user.displayName || ''
      )}&background=000000&color=fff&length=1&bold=true&format=svg`
    },
    showCommunityOnNavbar () {
      for (const journey of this.userJourneys) {
        if (showCommunityOnNavbar(journey.teraId)) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    logout () {
      this.logEvent('sair')
      Vue.$setUser(null)
      app.auth().signOut()
      this.$store.dispatch('account/resetState')
    },
    showUserMenu () {
      this.logEvent('foto-estudante')
      this.userMenuIsOpen = true
    },
    hideUserMenu () {
      this.userMenuIsOpen = false
    },
    imageLoadError () {
      this.imageDidNotLoad = true
    },
    imageLoadSuccess () {
      this.imageDidNotLoad = false
    },
    openHelpForms () {
      window.open('https://share.hsforms.com/1a-274l0QTPi-X8616i7iow4fdio')
      amplitude.getInstance().logEvent('Clicou em botão de ajuda')
    },
    openTerms () {
      window.open('https://somostera.com/termos-de-uso')
      amplitude.getInstance().logEvent('Clicou nos termos de uso')
    },
    logEvent (label) {
      Vue.$logEvent({ category: 'lxs:geral', action: 'clique:header', label })
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-to-bottom-enter-active,
.fade-to-bottom-leave-active {
  transition: all 0.2s ease;
}

.fade-to-bottom-enter,
.fade-to-bottom-leave-to {
  opacity: 0;
}

.fade-to-bottom-enter,
.fade-to-bottom-leave-to {
  transform: translateY(-15px);
}

.navbar {
  width: 100%;
  height: 60px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 10;
  position: sticky;
  top: 0;

  >.content {
    max-width: $container-xl;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 10px;

      @media (min-width: $container_xl) {
        margin: 0;
      }

      a {
        display: flex;
      }

      >.router-link-active {
        img {
          width: 100%;
          height: 32px;
        }
      }
    }

    .navbar-items {
      flex-grow: 2;
      align-items: center;
      justify-content: flex-end;
      display: inline-flex;
      height: 60px;

      ul {
        list-style: none;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        height: 100%;

        @media (max-width: $container_s) {
          display: none;
        }

        li {
          font-size: 16px;
          font-family: 'Rubik', sans-serif;
          line-height: 20px;
          text-transform: uppercase;
          width: 140px;
          color: $color-black;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #8ffe81
          }

          a {
            text-decoration: none;
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            color: $color-black;

            &.router-link-active {
              font-weight: bold;
            }

            &:active,
            &:focus,
            &:visited {
              color: $color-black;
            }
          }
        }
      }
    }

    .notification-visibility {
      visibility: hidden;
      width: 10px !important;
      height: 10px !important;
      position: relative;
      top: -25px;
      left: 40px;
    }

    .notification {
      visibility: visible;
    }

    .notification-deactivate {
      visibility: hidden;
    }

    .user {
      display: inline-flex;
      padding: 10px;

      // .help-button {
      //   margin-right: 46px;
      //   background: none;
      //   border: 0;
      //   text-transform: uppercase;
      //   font-weight: normal;
      //   cursor: pointer;
      //   text-decoration: none;
      //   color: #000;
      //   font-family: 'Rubik';
      //   font-size: 14px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }

      @media (min-width: $container_xl) {
        padding: 10px 0 10px 10px;
      }

      img {
        border-radius: 100%;
        width: 38px;
        height: 38px;
        object-fit: cover;
        cursor: pointer;
      }

      .user-menu {
        background: #fff;
        box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
        min-width: 160px;
        position: absolute;
        margin-top: 40px;
        margin-left: -120px;
        z-index: 10;
        box-sizing: border-box;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            padding: 10px 15px;
            cursor: pointer;

            &.mobile {
              display: none;
            }

            @media (max-width: $container_s) {
              &.mobile {
                display: block;
              }
            }

            &:hover {
              background-color: #8ffe81
            }

            a {
              text-decoration: none;
              color: $color-black;
              width: 100%;
              display: block;

              &.router-link-active {
                font-weight: bold;
              }

              &:active,
              &:focus,
              &:visited {
                color: $color-black;
              }
            }

            &:last-child {
              padding: 15px 15px 10px 15px;
              color: $color-gray-60;
              cursor: default;
              font-size: 12px;
              user-select: none;

              &:hover {
                background-color: initial;
              }

              &::before {
                content: '';
                height: 1px;
                width: 130px;
                margin-top: -15px;
                position: fixed;
                background: radial-gradient($color-gray-20, $color-white);
              }
            }
          }
        }
      }
    }
  }
}
</style>
