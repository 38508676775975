import ClassBlock from './index';
import { getOptionalParametersFromDataSnapshot, getDateFromTimestamp } from '../../../utils/classUtils'

const classBlockConverter = {
  fromFirestore (snapshot, options) {
    const data = snapshot.data(options);
    var classBlock = new ClassBlock(
      data.name,
      data.index,
      data.moduleIndex,
      data.teraId,
      data.type,
      getOptionalParametersFromDataSnapshot(data, snapshot)
    );

    classBlock.startTime = getDateFromTimestamp(data.startTime);
    classBlock.endTime = getDateFromTimestamp(data.endTime);
    classBlock.date = getDateFromTimestamp(data.date);

    return classBlock;
  }
};

export default classBlockConverter;
