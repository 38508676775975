import { db } from '@/core/services/firebase'

const dbPeople = db.collection('people')

export default {
  getPerson: async uid => {
    try {
      const personDocumentSnapshot = await dbPeople.doc(uid).get()
      if (!personDocumentSnapshot.exists) return
      return {
        ...personDocumentSnapshot.data(),
        id: personDocumentSnapshot.id
      }
    } catch (err) {
      console.error(err)
    }
  }
}
