<template>
  <div class="progress-scroll-tracker">
    <popover id="popover-scroll" triggeredBy="popover-image-scroll">
      <span v-if="isQuizNecessaryForCredential">
        Para conquistar o certificado você precisa completar 100% das aulas e desafios. Além disso, sua taxa de acerto nas
        questões deverá ser acima de 80%.
      </span>
      <span v-else>
        Para conquistar o certificado você precisa completar 100% das aulas e desafios.
      </span>
    </popover>

    <div class="wrapper">
      <div class="criteria">
        <p v-if="!isLoadingProgress" class="header">
          {{ currentJourneyProgress.completedBlocks }}/{{
            currentJourneyProgress.blocks
          }}
        </p>
        <p v-else class="header">
          <progress-bar color="#FFFFFF" size="18" :percent="25" :showOnlyProgress="true" :rotating="true" />
        </p>
        <p class="description" :class="{ loading: isLoadingProgress }">Aulas concluídas</p>
      </div>
      <div class="criteria">
        <p v-if="!isLoadingProgress" class="header">
          {{ currentJourneyProgress.completedChallenges }}/{{
            currentJourneyProgress.challenges
          }}
        </p>
        <p v-else class="header">
          <progress-bar color="#FFFFFF" size="18" :percent="25" :showOnlyProgress="true" :rotating="true" />
        </p>
        <p class="description" :class="{ loading: isLoadingProgress }">Desafios práticos</p>
      </div>
      <div v-if="isQuizNecessaryForCredential" class="criteria">
        <p v-if="!isLoadingProgress" class="header" :class="{ warning: isQuizSuccessRatioBelowDesirable }">
          {{
            currentJourneyProgress.quizSuccessRatio
          }}%
        </p>
        <p v-else class="header">
          <progress-bar color="#FFFFFF" size="18" :percent="25" :showOnlyProgress="true" :rotating="true" />
        </p>
        <p class="description" :class="{ loading: isLoadingProgress, warning: isQuizSuccessRatioBelowDesirable }">
          de acertos em {{
            currentJourneyProgress.completedQuizzes
          }}/{{ currentJourneyProgress.quizzes }} questões
        </p>
      </div>
      <div class="overall">
        <p v-if="!isLoadingProgress" class="header">
          {{ currentJourneyProgress.ratio }}% Completo
          <img id="popover-image-scroll" class="icon" src="@/assets/icons/exclamation_mark_grey.svg" />
        </p>
        <p v-else class="header loading">
          Atualizando...
          <img id="popover-image-scroll" class="icon" src="@/assets/icons/exclamation_mark_grey.svg" />
        </p>
        <div class="progress-bar" :class="{ loading: isLoadingProgress }">
          <div class="progress" :style="`width: ${currentJourneyProgress.ratio}%`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/modules/Journeys/components/ProgressBar'
import Popover from '@/core/components/Popover.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProgressScrollTracker',
  components: {
    ProgressBar,
    Popover
  },
  computed: {
    ...mapGetters('journeys',
      ['currentUserProgressByJourney',
        'currentUserJourney',
        'currentJourneyBlocks',
        'currentJourneyArticles',
        'isLoadingProgress'
      ]),
    currentJourneyProgress () {
      if (!this.currentUserProgressByJourney || this.currentUserProgressByJourney.length == 0) {
        return this.getDefaultState()
      }

      const progress = this.currentUserProgressByJourney.find(p => p.teraId === this.currentUserJourney.teraId)

      if (!progress) {
        return this.getDefaultState()
      }

      return progress
    },
    isQuizSuccessRatioBelowDesirable () {
      return this.currentJourneyProgress.quizSuccessRatio < 80
    },
    isQuizNecessaryForCredential () {
      const credentialRules = this.currentUserJourney.credentialRules
      if (!credentialRules) {
        return false
      }

      return credentialRules.quizSuccessRatio && credentialRules.quizSuccessRatio > 0 
    }
  },
  methods: {
    getDefaultState () {
      const chapters = this.currentJourneyArticles.map(a => a.chapters).flat()
      const totalQuizzes = chapters.filter(c => c.quizId).map(c => c.quizId)

      return {
        blocks: this.currentJourneyBlocks?.length,
        completedBlocks: 0,
        challenges: this.currentJourneyArticles.reduce((acc, data) => { return data.type === "challenge" ? acc + 1 : acc }, 0),
        completedChallenges: 0,
        quizzes: totalQuizzes.length,
        completedQuizzes: 0,
        quizSuccessRatio: 0,
        ratio: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes gradient-loading {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.progress-scroll-tracker {
  width: 100%;
  bottom: 0;
  position: fixed;
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;

  .wrapper {
    max-width: 1136px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    padding: 0 10px;
  }

  .criteria {
    @media (max-width: 800px) {
      display: none;
    }

    .header {
      font-family: 'Rubik';
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: #fff;
      margin: 0 0 4px 0;
    }

    .description {
      font-family: 'Rubik';
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      max-width: 100px;
      width: 100%;
      text-transform: uppercase;
      margin: 0;

      &.loading {
        color: #8F8F8F;
      }
    }

    .warning {
      color: #FFB280
    }
  }

  .overall {
    margin: 0 0 0 20px;
    flex-grow: 2;

    @media (max-width: 800px) {
      margin: 0 20px;
    }

    .header {
      font-family: 'Rubik';
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #8FFE81;
      text-transform: uppercase;
      margin: 0 0 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.loading {
        text-transform: capitalize;
      }

      .icon {
        margin: 0 0 0 4px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .progress-bar {
      width: 100%;
      background-color: #575757;
      border-radius: 90px;
      height: 8px;

      &.loading {
        background: linear-gradient(270deg, #8F8F8F 0%, #575757 100%);
        background-size: 400% 400%;
        animation: gradient-loading 3s ease infinite;

        .progress {
          display: none;
        }
      }

      .progress {
        background-color: #8FFE81;
        border-radius: 10px;
        height: 8px;
      }
    }
  }
}
</style>
