<template>
  <div>
    <div id="fist-animation"></div>
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'FistAnimation',
  mounted() {
    lottie.loadAnimation({
      container: document.getElementById('fist-animation'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/animation/fist.json')
    })
  }
}
</script>

<style>
#fist-animation {
  width: 50px;
  height: 50px;
}
</style>
