import { setOptionalParameters } from "../../../utils/classUtils";
import ClassBlock from "../ClassBlock";

class LiveBlock extends ClassBlock {
  constructor(name, index, moduleIndex, teraId, optionalAttributes) {
    super(name, index, moduleIndex, teraId, "live", optionalAttributes);
    setOptionalParameters(this, optionalAttributes);
  }

  image = "";
  imageUrl = "";
  isSpecialEvent = false;
  placeRef = "";
  speakersId = [];
  speakersRefs = [];
  transmissionUrl = "";
  answersPlatformUrl = "";
}

export default LiveBlock;
