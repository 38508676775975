import Journey from './index';
import { getOptionalParametersFromDataSnapshot, getDateFromTimestamp } from '../../utils/classUtils';

const journeyConverter = {
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    var journey = new Journey(
      data.name,
      data.courseInfo,
      data.teraId,
      getDateFromTimestamp(data.startsAt),
      getDateFromTimestamp(data.endsAt),
      data.hidden,
      getOptionalParametersFromDataSnapshot(data, snapshot)
    );

    journey.modules = journey.modules.map((m) => {
      if (m.date) {
        m.date = getDateFromTimestamp(m.date);
      }

      return m
    });

    return journey;
  }
};

export default journeyConverter;
