<template>
  <div class="modules">
    <div
      v-for="(module, moduleIndex) in currentUserJourney.modules"
      :id="'module-' + (moduleIndex + 1)"
      :key="moduleIndex + '_modules'"
      class="module-container"
    >
      <div class="text-container">
        <module-description :key="moduleIndex" :order="moduleIndex + 1" :title="module.name" :description="module.description || ''">
        </module-description>
      </div>
      <div class="card-container">
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

        <div
          v-for="(jBlock, k) in getJourneyBlocksByModuleIndex(moduleIndex)"
          v-if="
            currentJourneyBlocksWithoutOriginals &&
              currentJourneyBlocksWithoutOriginals.length > 0 &&
              jBlock.type !== 'originals'
          "
          :key="k + '_journeyBlocks'"
          class="card"
          @click.capture="() => handleCardClick(jBlock, moduleIndex)"
        >
          <article-card
            v-if="jBlock.type === 'article'"
            :article="getPreloadedArticle(jBlock.articleRef)"
            :articleProgress="getPreloadedArticleProgress(jBlock.articleRef)"
            :experts="getExpertFromArticle(jBlock.articleRef)"
            :teraId="childRouteParams.teraId"
            :isOptional="jBlock.isOptional"
            :moduleId="moduleIndex"
          ></article-card>
          <live-block-card
            v-else-if="jBlock.type === 'live'"
            :live-block="jBlock"
            :route-params="childRouteParams"
          >
          </live-block-card>
          <journey-block-card
            v-else
            :journey="currentUserJourney"
            :class-block="jBlock"
            :expert="getExpertById(jBlock.expertId)"
            :place="getPlaceById(jBlock.placeId)"
            :route-params="childRouteParams"
            :on-click="emitAmplitudeEvent"
          ></journey-block-card>
        </div>
        <div v-if="moduleHasOriginals(moduleIndex)" class="card" @click="() => handleOriginalsCardClick(moduleIndex)">
          <card-router-link
            :route="{
              name: 'ModuleLibrary',
              params: getModuleLibraryRouteParams(moduleIndex)
            }"
          >
            <info-card
              image-url="https://storage.googleapis.com/tera-lxs-images/illustration-biblioteca-módulo.svg"
              icon-name="ic_slides_white"
              :title="`Biblioteca: módulo ${String(moduleIndex + 1).padStart(2, '0')}`"
              subtitle="Uma seleção de conteúdos para você ampliar seus conhecimentos nesse módulo."
            ></info-card>
          </card-router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import CardRouterLink from '@/core/components/CardRouterLink.vue'
  import ModuleDescription from '@/modules/Journeys/components/ModuleDescription.vue'
  import JourneyBlockCard from '@/modules/Journeys/components/ClassBlockCard.vue'
  import ArticleCard from '@/modules/Journeys/components/ArticleCard.vue'
  import LiveBlockCard from '@/modules/Journeys/components/LiveBlockCard.vue'
  import Journey from '@/core/entities/models/Journey'
  import amplitude from 'amplitude-js'


  export default {
    name: 'ModuleVisualizationDefault',
    components: {
      JourneyBlockCard,
      ModuleDescription,
      CardRouterLink,
      ArticleCard,
      LiveBlockCard
    },
    props: {
      currentUserJourney: Journey,
      currentJourneyBlocks: Array,
      currentJourneyExperts: Array,
      currentJourneyPlaces: Array,
      currentJourneyArticles: Array,
      currentJourneyArticlesProgress: Array,
      childRouteParams: Object
    },
    computed: {
      currentJourneyBlocksWithoutOriginals() {
        if (!this.currentJourneyBlocks) return []

        return this.currentJourneyBlocks
          .filter((jb) => jb.type !== 'originals')
          .sort((a, b) => (a.index > b.index ? 1 : -1))
      },
      ...mapGetters('journeys', ['getArticleById'])
    },
    methods: {
      getJourneyBlocksByModuleIndex(moduleIndex) {
        return this.currentJourneyBlocksWithoutOriginals
          .filter((journeyBlock) => {
            return journeyBlock.moduleIndex === moduleIndex
          })
          .sort((a, b) => (a.index > b.index ? 1 : -1))
      },
      getPreloadedArticle(articleRef) {
        return this.currentJourneyArticles.find((e) => e.id == articleRef.id)
      },
      getPreloadedArticleProgress(articleRef) {
        return this.currentJourneyArticlesProgress.find((e) => e.id == articleRef.id)
      },
      getExpertFromArticle(articleRef) {
        const article = this.getPreloadedArticle(articleRef)
        const expertsIds = article.participantsIds ? article.participantsIds : []
        return this.currentJourneyExperts.filter(e => expertsIds.includes(e.id))
      },
      getModuleLibraryRouteParams(moduleIndex) {
        return { ...this.childRouteParams, moduleIndex: moduleIndex + 1 }
      },
      moduleHasOriginals(moduleIndex) {
        return this.currentJourneyBlocks.some((jb) => jb.moduleIndex === moduleIndex && jb.type === 'originals')
      },
      getExpertById(id) {
        return this.currentJourneyExperts.find((e) => e.id === id)
      },
      getPlaceById(id) {
        return this.currentJourneyPlaces.find((p) => p.id === id)
      },
      emitAmplitudeEvent() {
        amplitude.getInstance().logEvent('journey_module_visualization_default_view')
      },

      async handleCardClick(block, moduleIndex) {
        await this.setCurrentModuleIndex(moduleIndex)

        if (block.type === 'article') {
          const { name } = this.getArticleById(block.articleRef.id)
          Vue.$logEvent({
            category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
            action: `clique:card:${block.type}`,
            label: `${String(block.moduleIndex + 1).padStart(2, '0')}:${name}`
          })
        } else {
          Vue.$logEvent({
            category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
            action: `clique:card:${block.type}`,
            label: `${String(block.moduleIndex + 1).padStart(2, '0')}:${block.name}`
          })
        }
      },
      handleOriginalsCardClick(moduleIndex) {
        const module = String(moduleIndex + 1).padStart(2, '0')
        Vue.$logEvent({
          category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
          action: 'clique:card:originals',
          label: `${module}:biblioteca_modulo_${module}`
        })
      },
      logOnboardingCardEvent() {
        Vue.$logEvent({
          category: `lxs:${this.currentUserJourney.courseInfo.acronym}`,
          action: 'clique:card:onboarding',
          label: '00:onboarding'
        })
      },
      ...mapActions('journeys', ['setCurrentModuleIndex']),

    }
  }
</script>

<style lang="scss" scoped>
  .module-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $space-xxl;
    margin-bottom: 64px;

    > .text-container {
      width: 100%;
      max-width: 752px;
      > .text {
        > .headings {
          display: flex;
        }
      }
    }

    > .card-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 752px;

      > .card {
        margin-bottom: 16px;
      }
    }
  }
</style>
