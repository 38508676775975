var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modules"},_vm._l((_vm.currentUserJourney.modules),function(module,moduleIndex){return _c('div',{key:moduleIndex + '_modules',staticClass:"module-container",attrs:{"id":'module-' + (moduleIndex + 1)}},[_c('div',{staticClass:"text-container"},[_c('module-description',{key:moduleIndex,attrs:{"order":moduleIndex + 1,"title":module.name,"description":module.description || ''}})],1),_c('div',{staticClass:"card-container"},[_vm._l((_vm.getJourneyBlocksByModuleIndex(moduleIndex)),function(jBlock,k){return (
          _vm.currentJourneyBlocksWithoutOriginals &&
            _vm.currentJourneyBlocksWithoutOriginals.length > 0 &&
            jBlock.type !== 'originals'
        )?_c('div',{key:k + '_journeyBlocks',staticClass:"card",on:{"!click":function($event){return (() => _vm.handleCardClick(jBlock, moduleIndex)).apply(null, arguments)}}},[(jBlock.type === 'article')?_c('article-card',{attrs:{"article":_vm.getPreloadedArticle(jBlock.articleRef),"articleProgress":_vm.getPreloadedArticleProgress(jBlock.articleRef),"experts":_vm.getExpertFromArticle(jBlock.articleRef),"teraId":_vm.childRouteParams.teraId,"isOptional":jBlock.isOptional,"moduleId":moduleIndex}}):(jBlock.type === 'live')?_c('live-block-card',{attrs:{"live-block":jBlock,"route-params":_vm.childRouteParams}}):_c('journey-block-card',{attrs:{"journey":_vm.currentUserJourney,"class-block":jBlock,"expert":_vm.getExpertById(jBlock.expertId),"place":_vm.getPlaceById(jBlock.placeId),"route-params":_vm.childRouteParams,"on-click":_vm.emitAmplitudeEvent}})],1):_vm._e()}),(_vm.moduleHasOriginals(moduleIndex))?_c('div',{staticClass:"card",on:{"click":() => _vm.handleOriginalsCardClick(moduleIndex)}},[_c('card-router-link',{attrs:{"route":{
            name: 'ModuleLibrary',
            params: _vm.getModuleLibraryRouteParams(moduleIndex)
          }}},[_c('info-card',{attrs:{"image-url":"https://storage.googleapis.com/tera-lxs-images/illustration-biblioteca-módulo.svg","icon-name":"ic_slides_white","title":`Biblioteca: módulo ${String(moduleIndex + 1).padStart(2, '0')}`,"subtitle":"Uma seleção de conteúdos para você ampliar seus conhecimentos nesse módulo."}})],1)],1):_vm._e()],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }