<template>
  <div :key="'chapter_' + chapter" :class="containerClassWithTransition">
    <div v-if="chapter === 0" class="header-container">
      <base-header>
        <heading-with-before color="white" :before-color-variation="true">
          {{ originalsByChapter.title }}
        </heading-with-before>
      </base-header>
      <base-header></base-header>
      <div
        v-if="currentOriginals.illustration !== ''"
        class="illustration-container"
      >
        <img
          class="illustration"
          :src="currentOriginals.illustration"
          :alt="currentOriginals.illustration"
        />
      </div>
    </div>
    <div v-else :key="'chapter_' + chapter" class="header-container">
      <base-header background-color="blue-primary">
        <heading-with-before color="white" :before-color-variation="true">
          {{ originalsByChapter.title }}
        </heading-with-before>
        <heading class="chapter-subtitle" color="white" tag="h4">
          {{ originalsByChapter.description }}
        </heading>
      </base-header>
    </div>
    <vue-markdown
      :source="originalsByChapter.content"
      :prerender="prerender"
      :postrender="postrender"
      class="main"
    ></vue-markdown>
    <footer
      v-if="currentOriginals.chapters && currentOriginals.chapters.length > 0"
      class="footer"
    >
      <div v-if="currentOriginals" class="originals-chapters-container">
        <originals-chapter-card
          :is-current-chapter="chapter === 0"
          :index="0"
          :url="currentOriginals.url"
          :title="currentOriginals.title"
          :subtitle="currentOriginals.subtitle"
          :route-params="{ teraId }"
        ></originals-chapter-card>
        <originals-chapter-card
          v-for="(originalsChapter, i) in currentOriginals.chapters"
          :key="'chapter_' + (i + 1)"
          :is-current-chapter="chapter === i + 1"
          :index="i + 1"
          :url="currentOriginals.url"
          :title="originalsChapter.title"
          :subtitle="originalsChapter.subtitle"
          :expected-minutes="Number(originalsChapter.expectedMinutes)"
          :route-params="routeParams"
          :route-name="routeName"
        ></originals-chapter-card>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import OriginalsChapterCard from '@/modules/Journeys/components/OriginalsChapterCard.vue'
import { scrollPercent } from '@/core/utils/scroll-tracker'

let fromInside = false

export default {
  name: 'Originals',
  components: {
    OriginalsChapterCard
  },
  props: {
    teraId: {
      type: String
    },
    moduleIndex: {
      type: Number
    },
    originalsUrl: {
      type: String,
      required: true
    },
    chapter: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      title: 'Originals',
      isChangingChapter: false,
      originalsSeen: false,
      scrollPercentage: {
        10: false,
        25: false,
        50: false,
        75: false,
        100: false
      }
    }
  },
  computed: {
    containerClassWithTransition() {
      return !this.isChangingChapter ? 'originals' : 'originals -fade-out'
    },
    originalsByChapter() {
      if (this.currentOriginals) {
        if (this.chapter === 0) {
          return this.currentOriginals
        } else {
          return this.currentOriginals.chapters[this.chapter - 1]
        }
      } else {
        return null
      }
    },
    routeParams() {
      const params = {}

      if (this.teraId) {
        params.teraId = this.teraId
      }
      if (this.moduleIndex) {
        params.moduleIndex = this.moduleIndex
      }

      return params
    },
    routeName() {
      return this.$route.name.includes('Journey')
        ? 'JourneyOriginalsChapter'
        : 'OriginalsChapter'
    },
    ...mapGetters('journeys', [
      'currentUserOriginals',
      'currentOriginals',
      'originalsIsLoading'
    ]),
    ...mapGetters('account', ['user'])
  },
  watch: {
    chapter() {
      this.setCurrentOriginalsChapter(this.chapter)
      this.scrollToTop()

      this.setBreadcrumb()
    }
  },
  beforeMount() {
    this.scrollToTop()
    this.setCurrentOriginalsUrl(this.originalsUrl)
    if (this.teraId) {
      this.setCurrentTeraId(this.teraId)
    }
    if (this.moduleIndex) {
      this.setCurrentModuleIndex(this.moduleIndex)
    }
    if (this.chapter) {
      this.setCurrentOriginalsChapter(this.chapter)
    }
  },
  mounted() {
    this.setBreadcrumb()
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    this.scrollPercentage = {
      10: false,
      25: false,
      50: false,
      75: false,
      100: false
    }

    window.removeEventListener('scroll', this.onScroll)
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('journeys')) {
      fromInside = true
    }
    next()
  },
  methods: {
    onScroll() {
      const percent = scrollPercent()
      let label = ''

      if (percent === 10 && !this.scrollPercentage['10']) {
        label = '10'
        this.scrollPercentage['10'] = true
      }

      if (percent === 25 && !this.scrollPercentage['25']) {
        label = '25'
        this.scrollPercentage['25'] = true
      }

      if (percent === 50 && !this.scrollPercentage['50']) {
        label = '50'
        this.scrollPercentage['50'] = true
      }

      if (percent === 75 && !this.scrollPercentage['75']) {
        label = '75'
        this.scrollPercentage['75'] = true
      }

      if (percent === 100 && !this.scrollPercentage['100']) {
        label = '100'
        this.scrollPercentage['100'] = true
      }

      if (label !== '') {
        Vue.$logEvent({
          category: 'lxs:originals',
          action: `scroll:${this.originalsByChapter.title}`,
          label
        })
        label = ''
      }
    },
    setBreadcrumb() {
      const {
        journeys,
        modules,
        moduleLibrary,
        originals,
        originalsChapter
      } = this.$breadcrumbTemplates.journeys
      let breadcrumb = []
      if (this.chapter === 0) {
        breadcrumb = fromInside
          ? [journeys, modules, moduleLibrary, originals]
          : [journeys, originals]
      } else {
        breadcrumb = fromInside
          ? [journeys, modules, moduleLibrary, originals, originalsChapter]
          : [journeys, originals, originalsChapter]
      }

      this.setRouteBreadcrumb(breadcrumb)
    },
    prerender(text) {
      if (
        text.indexOf('&&EXERCICIO') > -1 &&
        text.indexOf('&&FIMEXERCICIO') > -1
      ) {
        text = text.replace('&&EXERCICIO', '&&EXERCICIO\n')
        text = text.replace('&&FIMEXERCICIO', '\n&&FIMEXERCICIO')
      }
      return text
    },
    postrender(text) {
      const paragraphs = text.split('<p>')

      const newParagraphs = paragraphs.map((p, index) => {
        let nextParagraph = paragraphs[index + 1]

        const isYoutube = p.indexOf('<a href="https://www.youtube.com') > -1
        if (isYoutube) {
          p = p.replace('<a', '<iframe')
          p = p.replace('</a>', '</iframe>')
          p = p.replace('href', 'src')
          p = p.replace('/watch?v=', '/embed/')
        }

        const isAudioFile = p.indexOf('.mp3"') > -1
        if (isAudioFile) {
          p = p.replace(
            '<a',
            '<audio controls="controls" class="audio"><source'
          )
          p = p.replace('</a>', ' type="audio/mpeg"></audio>')
          p = p.replace('href', 'src')
        }

        if (nextParagraph) {
          const hasBlockquote = p.indexOf('<blockquote>') > -1
          if (hasBlockquote) {
            const isQuote = nextParagraph.indexOf('</blockquote>\n<ul>') > -1
            if (isQuote) {
              nextParagraph = '<p class="quote">' + nextParagraph
              nextParagraph = nextParagraph.replace('</blockquote>', '')
              nextParagraph = nextParagraph.replace(
                '<ul>\n<li>',
                '<p class="-text-center">'
              )
              nextParagraph = nextParagraph.replace('</li>\n</ul>', '</p></p>')
              p = p.replace('<blockquote>', '')

              paragraphs[index + 1] = nextParagraph
            }
          }

          const nextParagraphIsExercise = nextParagraph.indexOf('&amp;&amp;EXERCICIO') > -1
          if (nextParagraphIsExercise) {
            nextParagraph = nextParagraph.replace(
              '&amp;&amp;EXERCICIO',
              '<div class="exercise"><h4 class="title">EXERCÍCIO</h4><p>'
            )
          }
          const endExercise = nextParagraph.indexOf('&amp;&amp;FIMEXERCICIO') > -1
          if (endExercise) {
            nextParagraph = nextParagraph.replace(
              '&amp;&amp;FIMEXERCICIO</p>',
              '</p></div>'
            )
          }

          paragraphs[index + 1] = nextParagraph
        }

        return p
      })

      return newParagraphs.join('<p>')
    },
    scrollToTop() {
      this.isChangingChapter = true
      const timeout = setTimeout(() => {
        window.scrollTo({
          x: 0,
          y: 0
        })
        this.isChangingChapter = false
        clearTimeout(timeout)
      }, 1000)
    },
    ...mapActions('journeys', [
      'setCurrentTeraId',
      'setCurrentOriginalsUrl',
      'setCurrentOriginalsChapter',
      'setCurrentModuleIndex',
      'updateJourneyBlocksWithStream'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.test-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: $container-l;
  margin: 0 auto;
  // overflow-x: hidden;
  > .image {
    margin-bottom: $space-xl;
  }
  > .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: $color-gray-20;
    margin-top: $space-xl;
    > .router-link-active {
      margin: 0 16px 30px 0;
    }
  }
}
$default-spacing: 24px;
.originals {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &.-fade-out {
    opacity: 0;
  }

  > .main,
  .illustration-container,
  .originals-chapters-container {
    max-width: $container-m;
    width: 100%;
    margin: 0 auto;
    @include breakpoint-small {
      width: 100%;
    }
  }

  > .main a {
    color: $color-black;
  }

  > .main {
    color: black;
    text-align: left;
    width: 100%;
    margin-top: 40px;

    * {
      padding: $default-spacing 0;
      margin: 0;
      // color: black;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-rubik;
      font-weight: $font-weight-bold;
    }

    h3 {
      font-size: 24px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 24px;
      padding-top: 0;
      padding-bottom: 48px;
      text-transform: uppercase;
      padding-bottom: 14px;
    }

    h4 {
      font-style: normal;
      font-size: $font-size-s;
      line-height: $line-height-default;
      text-transform: $font-text-transform-uppercase;
      padding-bottom: 8px;

      & + * {
        padding-top: 0px;
      }
    }

    hr {
      border: none;
      border-top: 1px solid #ccc;

      padding-top: 0;
      margin-top: $default-spacing;

      &.toolkit-hr {
        padding-bottom: 61px;
      }
      // &.toolkit-hr:last-child {
      //   padding-bottom: 0;
      // }
    }

    ul {
      padding-left: 20px;
    }

    ol,
    ul {
      font-size: $font-size-s;
      line-height: $line-height-m;
      font-family: $font-georgia;

      > li {
        position: relative;
        line-height: 24px;

        > p {
          padding: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > ol {
      counter-reset: counter;
      padding-left: 0;

      > li {
        margin-left: 33px;
        margin-bottom: 28px;
        color: $color-black;
        list-style: none;

        &::before {
          font-family: $font-rubik;
          font-style: normal;
          font-weight: $font-weight-bold;
          font-size: $font-size-s;
          line-height: $line-height-m;
          counter-increment: counter;
          content: counter(counter, decimal-leading-zero);
          position: absolute;
          left: -33px;
        }
      }
    }

    p {
      color: red;
      font-size: $font-size-s;
      line-height: $space-m;
      font-family: $font-georgia;

      > strong {
        font-weight: bold;
      }

      &.-text-center {
        padding-bottom: 60px !important;
        font-weight: $font-weight-bold;
      }

      &.toolkit {
        padding-bottom: 60px !important;
      }

      &.toolkit-objective {
        padding-bottom: 23px;
      }

      img {
        width: 100%;
        height: auto;
      }

      iframe {
        width: 100%;
        height: 450px;
        border: none;

        @media (max-width: 800px) {
          height: 100%;
        }
      }
    }

    > a {
      color: blue;

      &:hover {
        text-decoration: underline blue;
      }

      &:visited {
        color: purple;

        &:hover {
          text-decoration: underline purple;
        }
      }
    }

    > blockquote {
      > p {
        color: red;
        font-size: 24px;
        line-height: 36px;
        position: relative;
        text-transform: uppercase;

        &::before {
          content: '';
          background: $color-black;
          left: -39px;
          width: 8px;
          height: 100%;
          position: absolute;
        }
      }
    }

    > .quote {
      width: 800px;
      margin-left: -120px;
      color: blue;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 24px;
      @media (max-width: 800px) {
        width: 100%;
        margin-left: 0;
      }
    }

    > .text-center {
      padding: 0;
      font-size: 16px;
    }

    > .audio {
      width: 100%;
    }

    ::v-deep.exercise {
      width: 100%;
      padding: 48px 95px;
      margin-bottom: 48px;
      box-sizing: border-box;
      background-color: #3a3a3a;
      position: relative;
      @media (max-width: 800px) {
        width: calc(100% + 30px);
        margin-left: -15px;
        padding: 20px 30px;
      }

      > p {
        color: white;
        margin: 0;
        padding: 24px 0;

        > strong {
          color: white;
        }
      }

      > .title {
        color: white;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        padding: 0;

        & + * {
          color: $color-white;
          padding-top: 8px;
        }
      }

      > .question {
        font-size: 24px;
        font-weight: lighter;
        word-spacing: 3px;
        line-height: 1.3em;
      }

      > img {
        padding-top: 0;
      }
      > ul {
        li {
          color: $color-white !important;
        }
      }
      > ol {
        li {
          color: $color-white !important;
        }
      }
    }
  }

  > .header-container {
    > .illustration-container {
      width: 752px;
      height: 272px;
      margin-top: -40px;
      background-color: $color-blue-primary;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        margin: 0 auto;
        display: flex;
        width: 100%;
        max-width: 176px;
        max-height: 176px;

        @include breakpoint-small {
          max-width: 120px;
          max-height: 120px;
        }
      }
      // margin-bottom: 24px;
      // > .illustration {
      //   margin-left: 50%;
      //   transform: translate(-50%, 25%);
      //   width: auto;
      //   max-height: 176px;
      //   height: 100%;
      // }

      @include breakpoint-small {
        max-width: 300px;
        max-height: 176px;
      }
    }
    .chapter-subtitle {
      margin-top: 8px;
    }
  }
  > .footer {
    width: 100%;
    background-color: $color-gray-20;
    padding: 32px 0 81px;
    margin-top: $space-m;
    > .originals-chapters-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > * {
        margin-top: 16px;
      }
      @include breakpoint-small {
        max-width: 300px;
      }
    }
  }
}
header {
  h1 {
    font-size: 48px;
    line-height: 47px;
    font-weight: 300;
    &.title {
      padding-top: $default-spacing;
      padding-bottom: 8px;
      text-transform: uppercase;
    }
  }
  h4 {
    margin-bottom: 60px;
  }
  h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    padding-top: 0;
  }
}
.main,
ol {
  margin-bottom: 60px;
}
.chapter-header {
  .author {
    font-size: 12px;
    text-transform: uppercase;
  }
}
.originals-section {
  background-color: gray;
  margin-left: calc(calc(560px - 100vw) / 2);
  width: 100vw;
  box-sizing: border-box;
  @media (max-width: 800px) {
    margin-left: -15px;
    padding: 40px 5px;
  }
  &.-teraway-principles {
    padding: 40px calc(calc(100vw - 900px) / 2);
    margin-bottom: 35px;
    > .masonry-container {
      flex-direction: row-reverse;
      .card.-info {
        margin: 49px auto 0;
      }
    }
  }
}
.glossary {
  .subtitle {
    text-transform: capitalize;
    margin: 14px 0 7px;
  }
  .text::first-letter {
    text-transform: uppercase;
  }
}

::v-deep.main {
  color: black;
  text-align: left;
  width: 100%;
  * {
    padding: $default-spacing 0;
    margin: 0;
    // color: black;
  }
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    text-align: left;
    font-family: $font-rubik;
    font-weight: $font-weight-bold;
  }
  > h3 {
    font-size: 24px;
    text-transform: uppercase;
  }
  > h2 {
    font-size: 16px;
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 48px;
    text-transform: uppercase;
    padding-bottom: 14px;
  }
  > h4 {
    font-style: normal;
    font-size: $font-size-s;
    line-height: $line-height-default;
    text-transform: $font-text-transform-uppercase;
    padding-bottom: 8px;
    & + * {
      padding-top: 0px;
    }
  }
  > hr {
    border: none;
    border-top: 1px solid #ccc;
    padding-top: 0;
    margin-top: $default-spacing;
    &.toolkit-hr {
      padding-bottom: 61px;
    }
    // &.toolkit-hr:last-child {
    //   padding-bottom: 0;
    // }
  }
  > ul {
    padding: unset;
    padding-left: 20px;
  }
  > ol,
  ul {
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-family: $font-georgia;
    > li {
      position: relative;
      line-height: 24px;
      padding: unset;
      > p {
        padding: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  > ol {
    counter-reset: counter;
    padding-left: 0;
    > li {
      margin-left: 33px;
      color: $color-black;
      list-style: none;
      padding-bottom: 24px;
      &::before {
        font-family: $font-rubik;
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: $font-size-s;
        line-height: $line-height-m;
        counter-increment: counter;
        content: counter(counter, decimal-leading-zero);
        position: absolute;
        left: -33px;
      }
    }
  }
  p {
    width: 100%;
    text-align: left;
    color: $color-black;
    font-size: $font-size-s;
    line-height: $space-m;
    font-family: $font-georgia;

    > strong {
      text-align: left;
      font-weight: bold;
    }
    &.-text-center {
      padding-bottom: 60px !important;
      font-weight: $font-weight-bold;
    }
    &.toolkit {
      padding-bottom: 60px !important;
    }
    &.toolkit-objective {
      padding-bottom: 23px;
    }
    img {
      width: 100%;
      height: auto;
    }
    iframe {
      width: 100%;
      height: 450px;
      border: none;

      @media (max-width: 800px) {
        height: 100%;
      }
    }
  }
  > a {
    color: blue;
    &:hover {
      text-decoration: underline blue;
    }
    &:visited {
      color: purple;
      &:hover {
        text-decoration: underline purple;
      }
    }
  }
  > blockquote {
    > p {
      color: black;
      font-size: 24px;
      line-height: 24px;
      position: relative;
      text-transform: uppercase;
      font-family: $font-rubik;
      padding: unset;
      &::before {
        content: '';
        background: $color-black;
        left: -39px;
        width: 8px;
        height: 100%;
        position: absolute;
      }
    }
  }
  > .quote {
    width: 800px;
    color: $color-blue-primary;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 24px;
    @media (max-width: 800px) {
      width: 100%;
      margin-left: 0;
    }
  }
  > .text-center {
    padding: 0;
    font-size: 16px;
  }
  > .exercise {
    width: 100%;
    padding: 48px 95px;
    margin-bottom: 48px;
    color: white;
    box-sizing: border-box;
    background-color: #3a3a3a;
    position: relative;
    @media (max-width: 800px) {
      width: calc(100% + 30px);
      margin-left: -15px;
      padding: 20px 30px;
    }

    > * {
      color: $color-white;

      > * {
        color: $color-white;
      }
    }

    > p {
      margin: 0;
      padding-bottom: 24px;

      > a {
        color: $color-blue-primary !important;
      }
    }

    > ul {
      > li {
        > p {
          color: $color-white !important;
        }
      }
    }
    > .title {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      padding: 0;
      & + * {
        padding-top: 8px;
      }
    }
    > .question {
      font-size: 24px;
      font-weight: lighter;
      word-spacing: 3px;
      line-height: 1.3em;
    }
    > img {
      padding-top: 0;
    }
  }

  @include breakpoint-small {
    max-width: 300px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
::v-deep.main a {
  color: $color-black;
}
::v-deep audio.audio {
  width: 100%;
}
</style>
