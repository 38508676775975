const state = {
  currentSelectedIndexToOption: null,
  currentQuizOption: {},
  currentResponse: null,
  currentQuizOptions: [],
  isSecondChance: false,
}
const actions = {
  setSelectedIndexOption({ commit }, indexOption) {
    commit('setSelectedIndexToOption', indexOption)
  },
  setSelectedOption({ commit }, Option) {
    commit('setSelectedToOption', Option)
  },
  setCurrentResponse({ commit }, Response) {
    commit('setResponse', Response)
  },
  setCurrentQuizOptions({ commit }, Options) {
    commit('setQuizOptions', Options)
  },
  setIsSecondChance({ commit }, Chance) {
    commit('setSecondChance', Chance)
  },
}

const mutations = {
  setSelectedIndexToOption: (state, indexOption) => {
    state.currentSelectedIndexToOption = indexOption
  },

  setSelectedToOption: (state, Option) => {
    state.currentQuizOption = Option
  },

  setResponse: (state, Response) => {
    state.currentResponse = Response
  },

  setQuizOptions: (state, Options) => {
    state.currentQuizOptions = Options
  },

  setSecondChance: (state, Chance) => {
    state.isSecondChance = Chance
  }
}

const getters = {
  getSelectedIndexOption: state => {
    return state.currentSelectedIndexToOption
  },

  getSelectedOption: state => {
    return state.currentQuizOption
  },

  getSelectedResponse: state => {
    return state.currentResponse
  },

  getQuizOptions: state => {
    return state.currentQuizOptions
  },

  getIsSecondChance: state => {
    return state.isSecondChance
  }
}


export const quiz = {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
}
