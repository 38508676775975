<template>
  <node-view-wrapper class="video-wrapper">
    <vue-plyr ref="plyr" v-if="showPlayer" :options="options">
      <video controls playsinline :data-poster="node.attrs.poster">
        <source size="1080" :src="node.attrs.src" type="video/mp4" />
        <source size="720" :src="node.attrs.hd" type="video/mp4" />
        <source size="480" :src="node.attrs.sd" type="video/mp4" />
      </video>
    </vue-plyr>
  </node-view-wrapper>
</template>

<script>
  import Vue from 'vue'
  import VuePlyr from 'vue-plyr'
  import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
  import 'vue-plyr/dist/vue-plyr.css'
  import amplitude from 'amplitude-js'
  import { videoUrl } from '@/core/services/firebase'

  export default Vue.extend({
    name: 'Video',
    components: {
      NodeViewWrapper,
      VuePlyr
    },
    props: nodeViewProps,
    data() {
      return {
        showPlayer: false,
        options: {
          controls: [
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'pip', // Picture-in-picture (currently Safari only)
            'fullscreen' // Toggle fullscreen
          ],
          speed: { selected: 1, options: [0.25, 0.5, 1, 1.25, 1.5, 2] },
          options: { quality: { default: '720p' } }
        }
      }
    },
    async beforeMount() {
      const urlVideo = this.node.attrs.src
      let folder = '/public%2Fvideos%2F'

      if (urlVideo.search(folder) === -1) {
        folder = '/public%2Fmedia%2F'
      }

      const baseUrl = urlVideo.split(folder)

      let name = ''

      if (baseUrl[1].search('.mov') === -1) {
        name = baseUrl[1].split('.mp4')
      } else {
        name = baseUrl[1].split('.mov')
      }

      const urlHd = baseUrl[0] + folder + name[0] + '%2F1280x720_' + name[0] + '.mp4'
      const urlSd = baseUrl[0] + folder + name[0] + '%2F854x480_' + name[0] + '.mp4'

      const video1080p = await videoUrl(this.node.attrs.src)
      const video720p = await videoUrl(urlHd)
      const video480p = await videoUrl(urlSd)
      
      this.node.attrs.src = video1080p
      this.node.attrs.hd = video720p
      this.node.attrs.sd = video480p

      this.showPlayer = true
    },
    async mounted() {
      this.$refs.plyr.player.on('ended', () => {
        amplitude.getInstance().logEvent('Concluiu Video')
      })
    }
  })
</script>
