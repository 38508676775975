var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { db } from '@/core/services/firebase';
const collection = db.collection('articles');
export default {
    saveEvaluationArticle(articleId, evaluation) {
        return __awaiter(this, void 0, void 0, function* () {
            if (evaluation.createdAt === undefined)
                evaluation.createdAt = new Date();
            const articleRef = yield collection.doc(articleId).collection('article_evaluations').doc();
            yield articleRef.set(evaluation);
        });
    },
    saveEvaluationExpert(articleId, experts) {
        return __awaiter(this, void 0, void 0, function* () {
            const articleRef = yield collection.doc(articleId).collection('article_experts_evaluations').doc();
            yield Promise.all(experts.map((evaluationExpert) => {
                if (evaluationExpert.createdAt === undefined)
                    evaluationExpert.createdAt = new Date();
                return articleRef.set(evaluationExpert);
            }));
        });
    },
    getEvaluation(peopleId, articleId) {
        return __awaiter(this, void 0, void 0, function* () {
            const evaluationArticleRef = yield collection
                .doc(articleId)
                .collection('article_evaluations')
                .where('peopleId', '==', peopleId)
                .limit(1)
                .orderBy('createdAt', 'desc')
                .get();
            const evaluationArticle = evaluationArticleRef.docs.map((doc) => doc.data());
            const evaluationExpertRef = yield collection
                .doc(articleId)
                .collection('article_experts_evaluations')
                .where('peopleId', '==', peopleId)
                .limit(1)
                .orderBy('createdAt', 'desc')
                .get();
            return {
                evaluationArticle: evaluationArticle[0],
                evaluationExperts: evaluationExpertRef.docs.map((doc) => doc.data())
            };
        });
    }
};
