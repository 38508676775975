import { setOptionalParameters } from '../../utils/classUtils'

class Originals {
  constructor(optionalAttributes) {
    setOptionalParameters(this, optionalAttributes)
  }

  id = "";
  chapters = [];
  content = "";
  illustration = "";
  subtitle = "";
  title = "";
  url = [];
}

export default Originals;
