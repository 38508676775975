import LiveBlock from "./index";
import {
  getOptionalParametersFromDataSnapshot,
  getDateFromTimestamp,
} from "../../../utils/classUtils";

const liveBlockConverter = {
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    var liveBlock = new LiveBlock(
      data.name,
      data.index,
      data.moduleIndex,
      data.teraId,
      getOptionalParametersFromDataSnapshot(data, snapshot)
    );

    liveBlock.startTime = getDateFromTimestamp(data.startTime);
    liveBlock.endTime = getDateFromTimestamp(data.endTime);
    liveBlock.date = getDateFromTimestamp(data.date);

    return liveBlock;
  },
};

export default liveBlockConverter;
