<template>
  <div class="toolkit">
    <div class="toolkit-download-rectangle">
      <div class="container">
        <div class="text">
          <heading class="title">{{ title }}</heading>
          <paragraph class="paragraph" font-family="rubik">
            {{ paragraph }}
          </paragraph>
          <a :href="downloadUrl" target="_blank">
            <button class="button" type="button" name="button" @click="handleToolkitDownloadCardClick">
              Download • {{ fileSize }}
            </button>
          </a>
        </div>
        <div class="image">
          <img :src="imageUrl" alt="" />
          <div class="image -layer"></div>
        </div>
      </div>
    </div>
    <div class="description">
      <heading tag="h4" class="title-paragraph">Objetivo</heading>
      <paragraph class="paragraph">{{ objective }}</paragraph>
      <hr class="hr" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ToolkitDownloadCard',
  props: {
    title: String,
    paragraph: String,
    objective: String,
    imageUrl: String,
    downloadUrl: String,
    fileSize: String
  },
  methods: {
    handleToolkitDownloadCardClick() {
      Vue.$logEvent({
        category: 'lxs:manual-curso:ferramentas',
        action: 'clique:botao',
        label: `${this.title}:download`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.toolkit {
  > .toolkit-download-rectangle {
    height: 460px;
    width: 944px;
    background-color: #e6e6e6;
    background: $color-green-to-blue;
    margin-bottom: 24px;
    padding-top: $space-l;

    > .container {
      display: flex;
      flex-flow: row nowrap;
      width: 300px;

      > .text {
        @include breakpoint-small {
          height: fit-content;
          width: 300px;
        }

        > .title {
          height: auto;
          width: 260px;
          font-weight: bold;
          font-size: 48px;
          line-height: 57px;
          text-transform: uppercase;
          margin: -63px 0 7px 96px;
          padding-top: 0;
          @include breakpoint-small {
            margin: 16px 0 0 16px;
          }
        }

        > .paragraph {
          width: 100%;
          max-width: 260px;
          height: auto;
          font-size: 16px;
          line-height: 26px;
          margin: auto;
          padding: 7px 26px 32px 96px;
          @include breakpoint-small {
            margin: 8px 0 0 16px;
            padding: 0;
          }
        }

        > a {
          > .button {
            height: 36px;
            width: 252px;
            color: $color-white;
            background-color: $color-black;
            border-radius: 4px;
            border: 1px solid $color-black;
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
            text-align: center;
            margin-left: 96px;
            text-transform: uppercase;
            cursor: pointer;

            @include breakpoint-small {
              margin: 59px 0 0 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      > .image {
        height: 360px;
        width: 394px;
        margin-left: 50%;

        > img {
          height: 342px;
          width: 438px;
          object-fit: cover;
          object-position: 0 0;
          position: absolute;
          margin: 40px 0 0;
          @include breakpoint-small {
            height: 168px;
            width: 280px;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            margin-top: -10px;
            left: 0;
            right: 0;
          }
        }

        &.-layer {
          height: 342px;
          width: 394px;
          object-fit: cover;
          object-position: -20% 0;
          position: absolute;
          margin: 40px 0 0;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0.7) 900%
          );
          @include breakpoint-small {
            display: none;
          }
        }
        @include breakpoint-small {
          height: 168px;
          width: 280px;
          margin: -159px 16px 0 10px;
        }
      }

      @include breakpoint-small {
        flex-flow: column-reverse;
        height: fit-content;
        padding: 15px 0;
        align-items: center;
        width: auto;
      }

      .toolkit:last-child {
        hr.toolkit-hr {
          padding: 0;
        }
      }
    }

    @include breakpoint-small {
      height: fit-content;
      width: 100%;
      min-width: 300px;
      margin: 110px auto $space-m;
    }
  }

  > .description {
    margin: 0 auto;
    padding-bottom: 96px;
    max-width: 752px;

    > .title-paragraph {
      margin-bottom: 8px;
    }

    > .paragraph {
      font-family: $font-georgia;
      line-height: 24px;
    }

    > .hr {
      margin-top: 32px;
    }

    @include breakpoint-small {
      padding-bottom: 40px;
    }
  }

  &:first-of-type {
    margin-top: 20px;
  }

  @include breakpoint-small {
    padding: 0;
  }
}
</style>
