var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-multiple-empty-lines */
import f from 'firebase';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
const authConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseUrl: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    storageBucket: 'videos-tera-platform'
};
const app = f.initializeApp(authConfig);
const db = app.firestore();
db.settings({ experimentalForceLongPolling: true });
export { db };
export const analytics = app.analytics();
const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = app.auth().onAuthStateChanged((user) => {
            try {
                unsubscribe();
                resolve(user);
            }
            catch (err) {
                reject(err);
            }
        }, reject);
    });
};
export const videoUrl = (url) => __awaiter(void 0, void 0, void 0, function* () {
    const formatedUrl = decodeURIComponent(url
        .replace('https://firebasestorage.googleapis.com/v0/b/videos-tera-platform/o', '')
        .replace(/%2F/g, '/')
        .replace('?alt=media', ''));
    const response = yield firebase.storage(app).ref(formatedUrl).getDownloadURL();
    return response;
});
const { Timestamp, FieldValue, FieldPath } = f.firestore;
export { Timestamp, FieldValue, FieldPath, app, getCurrentUser, firebase };
