<template>
  <div class="rate-container">
    <div v-if="currentStep === 0" class="first-step">
      <div class="faces-container">
        <section>
          <img :src="sadFace" />
        </section>
        <section>
          <img :src="neutralFace" />
        </section>
        <section>
          <img :src="happyFace" />
        </section>
      </div>

      <h1>o que achou desta aula?</h1>

      <p>Deixe seu feedback para continuarmos melhorando sua experiência.</p>

      <div class="time-container">
        <img :src="clockIcon" />
        <p>1 min</p>
      </div>

      <button @click="nextStep">Avaliar</button>
    </div>

    <rate-step
      v-else-if="currentStep === 1"
      :currentStep="currentStep"
      :totalSteps="2"
      :prevStep="prevStep"
      :nextStep="nextStep"
    />

    <rate-step
      v-else-if="currentStep === 2"
      :currentStep="currentStep"
      expert
      :totalSteps="2"
      :prevStep="prevStep"
      :nextStep="nextStep"
    />

    <rate-last-step v-else :changeStep="changeStep" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import RateStep from './RateStep.vue'
  import RateLastStep from './RateLastStep.vue'
  import amplitude from 'amplitude-js'

  export default Vue.extend({
    name: 'RateArticle',
    components: {
      RateStep,
      RateLastStep
    },
    data() {
      return {
        sadFace: require('../../../assets/icons/sad_face.svg'),
        neutralFace: require('../../../assets/icons/neutral_face.svg'),
        happyFace: require('../../../assets/icons/happy_face.svg'),
        clockIcon: require('../../../assets/icons/clock.svg'),
        clapIcon: require('../../../assets/icons/clap.svg'),
        currentStep: 0
      }
    },
    computed: {
      ...mapGetters('journeys', ['currentJourneyArticle'])
    },
    mounted() {
      const isFinish = localStorage.getItem(`@tera/answered-${this.currentJourneyArticle.id}`)

      if (isFinish) {
        this.changeStep(3)
      }
    },
    methods: {
      nextStep() {
        this.currentStep++
        amplitude.getInstance().logEvent('Clicou em avaliar aula')
      },
      prevStep() {
        this.currentStep--
      },
      changeStep(stepNumber) {
        this.currentStep = stepNumber
      }
    }
  })
</script>

<style lang="scss">
  .rate-container {
    font-family: 'Rubik', sans-serif;
    margin: 10px auto 0;
    width: 560px;

    .first-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #383838;
      color: #fff;
      padding: 32px 0 24px;
      text-align: center;

      .faces-container {
        display: flex;

        > section {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background: #fff;

          & + section {
            margin-left: 8px;
          }
        }
      }

      > h1 {
        font-size: 24px;
        margin: 24px 0 0 0;
        text-transform: uppercase;
        text-align: center;
      }

      > p {
        margin: 8px 0 0 0;
        color: #bfbfbf;
        width: 403px;
        text-align: center;
      }

      .time-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 0 0;

        > p {
          text-transform: uppercase;
          margin: 0 0 0 4px;
        }
      }

      > button {
        font-family: 'Rubik', sans-serif;
        margin-top: 20px;
        padding: 9px 32px;
        color: #000000;
        background: #8dfe7e;
        border-radius: 8px;
        border: 0;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          filter: brightness(0.8);
          transform: translate(1px, 1px);
        }
      }
    }

    .last-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #383838;
      color: #fff;
      padding: 32px 0 24px;
      text-align: center;

      .clap-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: #8dfe7e;
      }

      > h1 {
        font-size: 24px;
        margin: 24px 0 0 0;
        text-transform: uppercase;
      }

      > p {
        margin: 8px 0 0 0;
        color: #bfbfbf;
        width: 403px;
        text-align: center;
      }

      > button {
        font-family: 'Rubik', sans-serif;
        margin-top: 93px;
        padding: 9px 32px;
        color: #000000;
        background: #fff;
        border-radius: 8px;
        border: 0;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          filter: brightness(0.8);
          transform: translate(1px, 1px);
        }
      }
    }

    @media (max-width: 800px) {
      width: 80%;
      margin: 10px auto 20px;

      .first-step {
        > h1 {
          width: 90%;
        }

        > p {
          width: 90%;
        }
      }

      .last-step {
        > h1 {
          width: 90%;
        }

        > p {
          width: 90%;
        }
      }
    }
  }
</style>
