<template>
  <main class="sidebar-view">
    <article-chapter-sidebar v-if="currentJourneyArticle" :articleChapters="chapters" :article="currentJourneyArticle" />
    <article class="article-container">
      <editor-view v-if="currentJourneyArticle && currentChapterIndex > -1 && isChapter "
                   :content="currentChapter.content"
      />

      <quiz-view v-if="currentJourneyArticle && currentChapterIndex > -1 && isQuiz && !isChapter"
                 :quiz="currentChapter"
      />

      <rate-article-v2 v-if="currentChapterIsLastChapter && !isQuiz && showEvaluation" />
      <rate-article v-if="currentChapterIsLastChapter && !isQuiz && !showEvaluation" />

      <community-banner v-show="currentChapterIsLastChapter && showCommunityBannerArticle" />

      <div v-if="currentChapterIsLastChapter && !isQuiz" class="button-container">
        <hr />
        <button type="button" @click="setLastChapter">Voltar para o curso</button>
      </div>
    </article>
  </main>
</template>

<script>
import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {showEvaluationArticle, showCommunityBanner} from '@/core/services/remote-config'

import ArticleChapterSidebar from '@/modules/Journeys/components/Article/ArticleChapterSidebar.vue'
import EditorView from '@/modules/Journeys/components/Article/EditorView.vue'
import QuizView from '@/modules/Journeys/components/Article/QuizView.vue'
import RateArticle from '@/modules/Journeys/components/RateArticle.vue'
import RateArticleV2 from '@/modules/Journeys/components/RateArticleV2.vue'
import CommunityBanner from '@/modules/Journeys/components/CommunityBanner.vue'

import {scrollPercent} from '@/core/utils/scroll-tracker'

export default {
  name: 'Article',
  components: {
    ArticleChapterSidebar,
    QuizView,
    EditorView,
    RateArticle,
    RateArticleV2,
    CommunityBanner
  },
  props: {
    teraId: String,
    articleId: String,
    chapterIndex: {type: Number, default: 0}
  },
  data() {
    return {
      scrollPercentage: {
        10: false,
        25: false,
        50: false,
        75: false,
        100: false
      }
    }
  },
  computed: {
    isChapter() {
      if (!this.currentChapter) return false
      return (this.currentChapter.contentType && this.currentChapter.contentType === 'chapter') || !this.currentChapter.contentType
    },
    isQuiz() {
      if (!this.currentChapter) return false
      return (this.currentChapter.contentType && this.currentChapter.contentType === 'quiz')
    },
    chapters() {
      if (!this.currentJourneyArticle) return
      return this.currentJourneyArticle.chapters
    },
    currentChapter() {
      if (!this.currentJourneyArticle) return
      return this.currentJourneyArticle.chapters[this.currentChapterIndex]
    },
    showEvaluation() {
      return showEvaluationArticle(this.teraId)
    },
    showCommunityBannerArticle() {
      return showCommunityBanner(this.teraId)
    },
    ...mapGetters('journeys', ['currentJourneyArticle', 'currentModuleIndex', 'currentChapterIndex', 'currentChapterIsLastChapter'])
  },
  watch: {
    currentChapter() {
      this.scrollPercentage = {
        10: false,
        25: false,
        50: false,
        75: false,
        100: false
      }
    }
  },
  beforeMount() {
    this.setRouteBreadcrumb(null)
  },
  async mounted() {
    await this.setCurrentTeraId(this.teraId)
    await this.setCurrentArticleId(this.articleId)
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    this.scrollPercentage = {
      10: false,
      25: false,
      50: false,
      75: false,
      100: false
    }

    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const percent = scrollPercent()
      let label = ''

      if (percent === 10 && !this.scrollPercentage['10']) {
        label = '10'
        this.scrollPercentage['10'] = true
      }

      if (percent === 25 && !this.scrollPercentage['25']) {
        label = '25'
        this.scrollPercentage['25'] = true
      }

      if (percent === 50 && !this.scrollPercentage['50']) {
        label = '50'
        this.scrollPercentage['50'] = true
      }

      if (percent === 75 && !this.scrollPercentage['75']) {
        label = '75'
        this.scrollPercentage['75'] = true
      }

      if (percent === 100 && !this.scrollPercentage['100']) {
        label = '100'
        this.scrollPercentage['100'] = true
      }

      if (label !== '') {

        let log = this.currentJourneyArticle ? (this.currentJourneyArticle.name ? this.currentJourneyArticle.name : this.currentJourneyArticle.id) : ''
        const title = this.currentChapter ? this.currentChapter.title : ''

        Vue.$logEvent({
          category: `lxs:conteudos:${log}`,
          action: `scroll:${title}`,
          label
        })
        label = ''
      }
    },
    setLastChapter() {

      let log = this.currentJourneyArticle ? (this.currentJourneyArticle.name ? this.currentJourneyArticle.name : this.currentJourneyArticle.id) : ''

      Vue.$logEvent({
        category: `lxs:conteudos:${log}`,
        action: 'clique:botao',
        label: 'concluir'
      })
      this.articleToNextChapter()
      this.$router.go(-1)
    },
    ...mapActions('journeys', ['setCurrentTeraId', 'setCurrentArticleId', 'updateJourneyBlocksWithStream', 'articleToNextChapter']),
    ...mapActions('system', ['setRouteBreadcrumb']),
    ...mapMutations('journeys', ['setCurrentChapter'])
  }
}
</script>

<style lang="scss">
.sidebar-view {
  min-height: 100%;

  .article-container {
    width: calc(100% - 260px);
    margin-left: 260px;
    padding-bottom: 32px;

    .button-container {
      display: flex;
      flex-direction: column;
      margin: 24px auto 32px;
      max-width: 560px;
      width: 100%;

      hr {
        border: 0;
        border-top: 2px solid #e0e0e0;
        border-bottom: none;
        width: 100%;
        margin-top: 0;
      }

      button {
        align-self: flex-end;
        background: #000;
        box-shadow: 0px 6px 12px rgba(51, 51, 51, 0.25);
        border: 0;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 700;
        height: 48px;
        line-height: 20px;
        margin-top: 16px;
        padding: 8px;
        text-transform: uppercase;
        width: 280px;

        &:hover {
          transform: translate(1px, 1px);
        }
      }
    }
  }

  @media (max-width: 800px) {
    .article-container {
      margin: 0px;
      width: 100%;

      .button-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0px 16px;
        max-width: 560px;
        width: auto;

        hr {
          border: 0;
          border-top: 2px solid #e0e0e0;
          border-bottom: none;
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }
}
</style>
