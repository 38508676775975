var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { db, getCurrentUser } from '@/core/services/firebase';
const collection = db.collection('quizzes');
export default {
    getQuizById(quizId) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataQuiz = yield collection.doc(quizId).get();
            const quizOptionRef = yield collection
                .doc(quizId)
                .collection('QuizOptions')
                .get();
            const options = quizOptionRef.docs
                .map(d => {
                return d.data();
            })
                .sort((a, b) => a.index - b.index);
            return Object.assign(Object.assign({}, dataQuiz.data()), { options: options });
        });
    },
    getLastAnswer(quizId) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield getCurrentUser();
            const quizAnswerRef = yield collection
                .doc(quizId)
                .collection('QuizAnswer')
                .where('peopleId', '==', user === null || user === void 0 ? void 0 : user.uid)
                .limit(1)
                .orderBy('createdAt', 'desc')
                .get();
            const answer = quizAnswerRef.docs.map(c => {
                return c.data();
            });
            return Object.assign({}, answer[0]);
        });
    },
    getTotalAnswers(quizId, maxQuizOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield getCurrentUser();
            const quizAnswerRef = yield collection
                .doc(quizId)
                .collection('QuizAnswer')
                .where('peopleId', '==', user === null || user === void 0 ? void 0 : user.uid)
                .limit(maxQuizOptions)
                .orderBy('createdAt', 'desc')
                .get();
            return quizAnswerRef.docs.length;
        });
    },
    saveAnswer(quizId, answer) {
        return __awaiter(this, void 0, void 0, function* () {
            if (answer.createdAt === undefined)
                answer.createdAt = new Date();
            const quizAnswerRef = yield collection
                .doc(quizId)
                .collection('QuizAnswer')
                .doc();
            yield quizAnswerRef.set(answer);
        });
    }
};
