import { Node } from '@tiptap/core';
import { mergeAttributes, VueNodeViewRenderer } from '@tiptap/vue-2';
import Component from './Component.vue';
export const Video = Node.create({
    name: 'custom-video',
    group: 'block',
    atom: true,
    defaultOptions: {
        allowfullscreen: true,
        HTMLAttributes: {}
    },
    addAttributes() {
        return {
            src: {
                default: null
            },
            poster: {
                default: null
            },
            frameborder: {
                default: 0
            },
            allowFullscreen: {
                default: this.options.allowfullscreen,
                parseHTML: () => {
                    return {
                        allowFullscreen: this.options.allowfullscreen
                    };
                }
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'video[src, poster]'
            }
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['video', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },
    addCommands() {
        return {
            setVideo: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options
                });
            }
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(Component);
    }
});
