<template>
  <div v-if="isVisible" class="banner-container-freemium-journey">
    <div class="content-container">
      <span />

      <h1>Quer acessar todos os conteúdos?</h1>
      <h2>Certifique-se em {{ courseName }} com {{ discountPercentage }} OFF</h2>

      <p>
        No curso completo você tem acesso a:
      </p>

      <div class="tags-container">
        <div
          v-for="(tag) in tags"
          :key="tag"
          class="tag"
        >
          {{ tag }}
        </div>
      </div>

      <button @click="handleClickBuyButton">
        <span>
          Inscreva-se no curso completo
        </span>
        <img :src="rightArrowGreen" />
      </button>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import amplitude from 'amplitude-js'
    import { calcDiscount } from '@/modules/Journeys/utils/calcDiscount'

  export default Vue.extend({
    name: 'FreemiumJourneyBanner',
    props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
    data() {
      return {
        blueBanner: require('../../../assets/blueBanner.png'),
        rightArrowGreen: require('../../../assets/icons/rightArrowGreen.svg'),
        tags: [
          'Comunidade vitalícia',
          'Solução de cases reais',
          'Conteúdo completo',
          'Workshops',
          'Mentorias',
          'Aulas ao vivo',
          'Projetos e cases práticos',
        ],
        siteUrl: 'https://somostera.com/',
        courseName: 'nossos cursos',
        nextClassTeraId: '',
      }
    },
    computed: {
      ...mapGetters('journeys', ['currentTeraId']),

      discountPercentage() {
        return calcDiscount(this.currentTeraId)
      }
    },
    mounted() {
      const courseNameDictionary = {
        dpl: `Product Management`,
        pdc: `Product Discovery`,
        uxd: `UX Design`,
        dpd: `Product Design`,
        dte: `Fundamentos de análise de dados`,
        dsc: `Ciência de Dados`,
        dta: `Data Analytics`,
        dfs: `Programação Full Stack`
      }

      const acronym = String(this.currentTeraId).substr(0, 3).toLowerCase()

      if(acronym){

        this.courseName = courseNameDictionary[acronym]

        fetch(`https://us-central1-tera-platform.cloudfunctions.net/url-courses-info?course=${acronym}`)
          .then(response => response.json())
          .then(data => {
            this.siteUrl = data.siteUrl

            const nextClass = data?.journeys[0]

            this.nextClassTeraId = nextClass?.teraId

          }).catch(error => {
            console.log(error)
            this.siteUrl = 'https://somostera.com/'
            this.nextClassTeraId = ''
          })
      }else{
        console.log('acronym', acronym)
        this.siteUrl = 'https://somostera.com/'
        this.nextClassTeraId = ''
      }
    },
    methods: {
      handleClickBuyButton() {
        const link = this.nextClassTeraId
          ? `https://pay.somostera.com/checkout/${this.nextClassTeraId}/enroll?grsf=&referrer=`
          : this.siteUrl

        window.open(link)

        amplitude.getInstance().logEvent(
          'Clicou no banner da jornada',
          {
            teraId: this.currentTeraId,
          }
        )
      }
    }
  })
</script>

<style lang="scss">
  .banner-container-freemium-journey {
    font-family: 'Rubik', sans-serif;
    box-sizing: border-box;
    width: 95%;
    max-width: 1300px;
    margin: 40px auto;
    background: #bfefb3;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      color: #000000;
      flex: 1;
      max-width: 700px;

      > span {
        height: 8px;
        width: 40px;
        background: #171717;
      }

      > h1, h2 {
        margin: 5px 0 0;
        text-transform: uppercase;
        font-size: 32px;
      }

      > h2 {
        margin: 0;
        font-weight: normal;
      }

      > p {
        margin: 25px 0 0;
      }

      .tags-container {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0;
        color: #0e180c;
        gap: 8px;

        .tag {
          margin: 0;
          padding: 8px 12px;
          border-radius: 99999px;
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          background: #26e30d;
        }
      }

      > button {
        font-family: 'Rubik', sans-serif;
        text-align: left;
        box-sizing: border-box;
        margin: 24px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        padding: 12px 25px;
        border-radius: none;
        border: 0;
        color: #ffffff;
        background: #000000;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        gap: 40px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background: #282828;
        }
      }
    }

    @media (max-width: 1218px) {
      flex-direction: column;

      .img-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        > img {
          align-self: center;
        }
      }
    }

    @media (max-width: 720px) {
      .img-container {
        > img {
          width: 100%;
        }
      }

      .content-container {
        > button {
          font-size: 16px;
        }
      }
    }
  }
</style>
