<template>
  <div v-if="editor" class="editor">
    <editor-content class="preview-container" :editor="editor" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-2'

import StarterKit from '@tiptap/starter-kit'
import { Underline } from '@tiptap/extension-underline'
import { Typography } from '@tiptap/extension-typography'
import { TextAlign } from '@tiptap/extension-text-align'
import { Link } from '@tiptap/extension-link'
import { Image } from '@tiptap/extension-image'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { lowlight } from 'lowlight/lib/core'
import { Video } from '@/modules/Journeys/components/Article/components/Video'
import { PageBreak } from '@/modules/Journeys/components/Article/components/PageBreak'
import { FileDownload } from '@/modules/Journeys/components/Article/components/FileDownload'
import { Quote } from '@/modules/Journeys/components/Article/components/Quote'
import { ColouredCardList } from '@/modules/Journeys/components/Article/components/ColouredCardList'

export default Vue.extend({
  name: 'EditorView',
  components: {
    EditorContent
  },
  props: {
    content: Object
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          StarterKit,
          Underline,
          TextAlign,
          Link,
          Typography,
          CodeBlockLowlight.configure({
            lowlight
          }),
          PageBreak,
          Video,
          Image,
          FileDownload,
          Quote,
          ColouredCardList
        ]
      })
    }
  },
  watch: {
    content() {
      this.editor.commands.clearContent()
      this.editor.commands.setContent(this.content)
    }
  },
  async mounted() {
    if (this.content) {
      this.editor.commands.setContent(this.content)
      this.editor.setEditable(false)
    }
  }
})
</script>

<style lang="scss">
:root {
  --plyr-color-main: #04a0c3;
  --plyr-font-family: 'Rubik';
}

.editor {
  height: 100%;
  font-family: 'Rubik', sans-serif;

  .preview-container {
    margin: 0 auto;
    padding: 32px 48px 0;
    width: 560px;

    .ProseMirror {
      > * {
        margin: 0;
        padding: 0;
      }

      h1 {
        border-left: 8px solid #99fb8c;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -2%;
        line-height: 48px;
        margin-left: -24px;
        padding-left: 16px;
        text-transform: uppercase;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      > p {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }

      a {
        color: #04a0c3 !important;
        font-weight: bold;
        text-decoration: underline !important;
      }

      img {
        width: 100%;
        margin-bottom: 24px;
      }

      .video-wrapper {
        height: auto;
        overflow: hidden;
        width: 100%;
        margin-bottom: 24px;

        .plyr {
          &__video-wrapper {
            width: 100%;
          }
        }

        video {
          height: auto;
          width: 100%;
        }
      }

      ol {
        padding-left: 0;
        margin-bottom: 40px;

        li {
          background: var(--bg-color);
          color: var(--fg-color);
          line-height: 24px;
          list-style-position: inside;
          padding: 16px;
          margin: 16px 0;

          & + li {
            margin-top: 16px;
          }

          &::marker {
            font-weight: bold;
          }

          p {
            display: inline;
          }
        }

        &.white-list {
          counter-reset: list-counter;
          list-style: none;
          margin: 0;
          padding: 0;

          & + p {
            margin-top: 24px;
          }

          li {
            counter-increment: list-counter;
            display: flex;
            margin-top: 16px;

            &:first-child {
              margin-top: 0;
            }

            &:nth-child(n + 10)::before {
              content: counter(list-counter);
            }

            &::before {
              content: '0' counter(list-counter);
              font-size: 32px;
              font-family: 'Rubik', sans-serif;
              font-weight: bold;
              letter-spacing: -2%;
              line-height: 32px;
              margin-right: 8px;
              min-width: 48px;
            }

            > p {
              margin: 0;
            }
          }
        }
      }

      ul {
        margin-bottom: 24px;

        p {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
        }

        > li + li {
          margin-top: 16px;
        }
      }

      .quote-component {
        margin-bottom: 40px;
      }

      pre {
        background: #0d0d0d;
        border-radius: 4px;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        max-width: 100%;
        padding: 12px 16px;
        margin-bottom: 24px;
        display: block;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 16px;
        }

        .hljs-comment,
        .hljs-quote {
          color: #bbbbbb;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
          color: #f98181;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
          color: #fbbc88;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
          color: #b9f18d;
        }

        .hljs-title,
        .hljs-section {
          color: #faf594;
        }

        .hljs-keyword,
        .hljs-selector-tag {
          color: #70cff8;
        }

        .hljs-emphasis {
          font-style: italic;
        }

        .hljs-strong {
          font-weight: 700;
        }
      }

      div {
        > .container {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .editor {
    width: 100%;

    .preview-container {
      width: 80%;
      padding: 0 16px 32px;
      top: 104px;

      .ProseMirror {
        margin-top: 116px;

        h1 {
          margin-left: 0;
          font-size: 26px;
          line-height: initial;
        }

        h2 {
          font-size: 18px;
        }

        h3 {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
