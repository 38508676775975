import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import Component from './Component.vue';
export const Quote = Node.create({
    name: 'quote',
    group: 'block',
    atom: true,
    draggable: true,
    defaultOptions: {
        quote: '',
        author: '',
        isPreview: false,
        HTMLAttributes: {}
    },
    addAttributes() {
        return {
            quote: {
                default: ''
            },
            author: {
                default: ''
            },
            isPreview: {
                default: false
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'quote'
            }
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['quote', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },
    addCommands() {
        return {
            setQuote: options => ({ commands }) => commands.insertContent({
                type: this.name,
                attrs: options
            })
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(Component);
    }
});
