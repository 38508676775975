<template>
  <div>
    <div
      v-if="getSelectedResponse === null"
      :class="
        optionData.index === getSelectedIndexOption
          ? 'quiz-option-container-selected'
          : 'quiz-option-container'
      "
      @click="handleSelectOption(optionData)"
    >
      <div class="check-icon-container">
        <span>
          <img :src="successIcon" class="success-icon" />
          <img :src="failIcon" class="fail-icon" />
          <div class="selected" />
        </span>
      </div>

      <p>{{ optionData.option }}</p>
    </div>

    <div
      v-else
      :class="
        optionData.index !== getSelectedIndexOption
          ? 'quiz-option-container-default'
          : getSelectedResponse
            ? 'quiz-option-container-success'
            : 'quiz-option-container-fail'
      "
    >
      <div class="check-icon-container">
        <span>
          <img :src="successIcon" class="success-icon" />
          <img :src="failIcon" class="fail-icon" />
          <div class="selected" />
        </span>
      </div>

      <p>{{ optionData.option }}</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'QuizOption',
  props: {
    optionData: Object
  },
  data() {
    return {
      successIcon: require('../../../assets/icons/success_circle.svg'),
      failIcon: require('../../../assets/icons/fail_circle.svg')
    }
  },
  computed: {
    ...mapGetters('quiz', ['getSelectedIndexOption', 'getSelectedResponse'])
  },
  methods: {
    handleSelectOption(Option) {
      this.setSelectedIndexOption(Option.index)
      this.setSelectedOption(Option)
    },
    ...mapActions('quiz', ['setSelectedIndexOption', 'setSelectedOption'])
  }
})
</script>

<style lang="scss">
.quiz-option-container {
  font-family: 'Rubik', sans-serif;
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  border: 2px solid #bbbbbb;
  border-radius: 8px;
  color: #383838;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border: 2px solid #000000;
    color: #000000;
    transition: all 0.2s;

    .check-icon-container {
      > span {
        border: 2px solid #000000;
      }
    }
  }

  .check-icon-container {
    > span {
      display: block;
      margin-right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #bbbbbb;

      > img, div {
        display: none;
      }
    }
  }
}

.quiz-option-container-selected {
  font-family: 'Rubik', sans-serif;
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  border: 2px solid #000000;
  border-radius: 8px;
  color: #000000;
  background: #f0f0f0;

  .check-icon-container {
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #000000;

      > img {
        display: none;
      }

      > div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #000000;
      }
    }
  }
}

.quiz-option-container-success {
  font-family: 'Rubik', sans-serif;
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 8px;
  color: #000000;
  background: rgba(3, 150, 130, 0.2);

  .check-icon-container {
    > span {
      display: block;
      margin-right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid transparent;

      > img, div {
        display: none;
      }

      .success-icon {
        display: block;
      }
    }
  }
}

.quiz-option-container-fail {
  font-family: 'Rubik', sans-serif;
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 8px;
  color: #383838;
  background: #fcd3cd;

  .check-icon-container {
    > span {
      display: block;
      margin-right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid transparent;

      > img, div {
        display: none;
      }

      .fail-icon {
        display: block;
      }
    }
  }
}

.quiz-option-container-default {
  font-family: 'Rubik', sans-serif;
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  border: 2px solid #bbbbbb;
  border-radius: 8px;
  color: #383838;

  .check-icon-container {
    > span {
      display: block;
      margin-right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #bbbbbb;

      > img, div {
        display: none;
      }
    }
  }
}

</style>
