<template>
  <div class="middle-content-open">
    <div v-if="loading" class="loading-modal-open">
      <loading-animation />
    </div>

    <div class="progress-bar-open">
      <section :style="`width: ${(((currentStep + 1) * 100) / totalSteps)}%`" />
    </div>

    <div class="buttons-container-open">
      <button v-show="currentStep > 0" @click="prevStep">
        <img :src="backIcon" />
      </button>
    </div>


    <h1>Gostaria de pontuar algo?</h1>

    <p>
      Você pode dar seu feedback usando o formato Que Bom/Que Pena/Que Tal
    </p>

    <div class="input-container">
      <textarea v-model="currentRate" />
    </div>

    <button @click="saveAnswer">
      Enviar
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getCurrentUser } from '@/core/services/firebase'
import evaluationArticleService, { EvaluationType } from '@/modules/Journeys/services/evaluationArticleV2'
import LoadingAnimation from '@/modules/Journeys/components/LoadingAnimation.vue'

export default Vue.extend({
  name: 'RateOpenField',
  components: {
    LoadingAnimation
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    prevStep: {
      type: Function,
      required: true
    },
    nextStep: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      backIcon: require('../../../assets/icons/caret_left.svg'),
      loading: false,
      currentUser: null,
      currentRate: '',
      userAlreadyRated: false
    }
  },

  computed:{
    ...mapGetters('journeys', ['currentJourneyArticle', 'currentTeraId'])
  },
  watch: {
    currentStep() {
      this.currentRate = ''
    },
  },
  async mounted(){
    this.currentUser = await getCurrentUser()

    this.getAnswer()
  },
  methods: {
    async getAnswer() {

      this.loading = true

      const answer = await evaluationArticleService.getEvaluationByType(
        this.currentTeraId,
        this.currentJourneyArticle.id,
        this.currentUser.uid,
        EvaluationType.COMMENT
      )

      if (answer?.text.length > 0) {
        this.currentRate = answer.text
        this.userAlreadyRated = true
      }

      this.loading = false
    },
    async saveAnswer() {
       if (this.currentRate.length <= 0) {
        this.nextStep()
        return
      }

      this.loading = true

      const evaluation = {
        teraId: this.currentTeraId,
        blockId: this.currentJourneyArticle.id,
        studentId: this.currentUser.uid,
        text: this.currentRate,
        type: EvaluationType.COMMENT
      }

      if (this.userAlreadyRated) {
        console.log('TESTE', evaluation)

        await evaluationArticleService.updateEvaluationText(
          evaluation.teraId,
          evaluation.blockId,
          evaluation.studentId,
          evaluation.text,
        )
      } else {
        await evaluationArticleService.saveEvaluation(evaluation)
      }

      this.currentRate = ''
      this.nextStep()
      this.loading = false
    },
  }
})
</script>

<style lang="scss">
.middle-content-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #171717;
  color: #fff;
  padding-bottom: 24px;
  text-align: center;
  position: relative;

  .loading-modal-open {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }

  .progress-bar-open {
    width: 100%;
    height: 8px;
    background: #242424;

    > section {
      height: 100%;
      background: #8ffe81;
    }
  }

  .buttons-container-open {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 12px 10px 0;
    height: 20px;

    button {
      display: flex;
      align-content: center;
      justify-content: center;
      background: none;
      border: 0;
      color: #bfbfbf;
      cursor: pointer;

      &:first-child {
        img {
          margin-right: 8px;
        }
      }

      &:last-child {
        img {
          margin-left: 8px;
        }
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  > h1 {
    font-size: 24px;
    line-height: 1.2;
    margin: 56px 0 0 0;
    text-transform: uppercase;
    text-align: center;
    width: 90%;
  }

  > p {
    margin: 8px 0 0 0;
    width: 90%;
    text-align: center;
    font-size: 16px;
    text-transform: none;
  }

  .input-container {
    margin-top: 24px;
    width: 100%;

    > textarea {
      font-family: 'Rubik', sans-serif;
      box-sizing: border-box;
      width: 90%;
      height: 120px;
      background: transparent;
      border: 1px solid #dbdbdb;
      border-radius: 2px;
      color: #fff;
      resize: none;
      padding: 16px;
    }
  }

  > button {
    font-family: 'Rubik', sans-serif;
    margin: 54px 24px 0 0;
    padding: 8px 24px;
    color: #000000;
    background: #8ffe81;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-end;

    &:hover {
      filter: brightness(0.8);
      transform: translate(1px, 1px);
    }

    &:disabled {
      background: #666666;
      color: #333333;
      cursor: default;

      &:hover {
        filter: none;
        transform: none;
      }
    }
  }
}

@media(max-width: 800px) {
  .middle-content-open {
    > button {
      margin: 54px 24px 0 0;
    }
  }
}
</style>
