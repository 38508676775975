import OriginalsBlock from './index';

const originalsBlockConverter = {
  fromFirestore (snapshot, options) {
    const data = snapshot.data(options);

    return new OriginalsBlock(
      data.index,
      data.moduleIndex,
      data.originalsRef,
      data.teraId
    );
  }
};

export default originalsBlockConverter;
