<template>
  <card-router-link :route="routeToGo" :journey="journey" :block="classBlock" @click="onClick()">
    <div class="rectangular-card" :class="{'active': isActive}">
      <div class="responsive-image-container">
        <img v-if="hasExpert" class="image" :class="{ 'no-image': imageDidNotLoad }" :src="expert.pictureUrl || ''"
             :alt="expert.name || ''" @error="imageLoadError" @load="imageLoadSuccess"
        />
      </div>
      <div class="slot-container">
        <div class="aside-left">
          <div class="date-container" :class="{'active': isActive}">
            <span class="date">{{ formattedDay }}</span>
            <p class="month">{{ formattedMonth }}</p>
          </div>
        </div>
        <div class="content">
          <div class="content-container">
            <heading class="title" tag="h3">{{ classBlock.name }}</heading>
            <heading v-if="hasExpert" class="subtitle" font-weight="normal" tag="h4" text-transform="none">
              {{ `com ${expert.name}` }}{{ expert.company ? ` • ${expert.company}` : '' }}
            </heading>
          </div>

          <div class="footer">
            <heading class="event-hours" font-weight="normal" tag="h4"
                     text-transform="none"
            >
              {{ isActive ? formattedHoursAndMinutes : '&nbsp;' }}
            </heading>
            <heading class="event-type" tag="h4" font-weight="normal"
                     text-transform="none"
            >
              {{ isActive ? 'Encontro com Expert • Ao Vivo' : 'Encontro com Expert • Replay' }}
            </heading>
          </div>
        </div>
        <div class="aside-right">
          <div class="image-container">
            <img v-if="hasExpert" class="image" :src="expert.pictureUrl || ''" :alt="expert.name || ''"
                 :class="{ 'no-image': imageDidNotLoad }" @error="imageLoadError" @load="imageLoadSuccess"
            />
          </div>
        </div>
      </div>
    </div>
  </card-router-link>
</template>

<script>
import ClassBlock from '@/core/entities/models/JourneyBlocks/ClassBlock'
import CardRouterLink from '@/core/components/CardRouterLink'

import Journey from '@/core/entities/models/Journey'

const monthNames = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEC'
]

export default {
    name: "OnDemandLiveBlockCard",
    status: "prototype",
    release: "0.0.1",
    components: {
        CardRouterLink
    },
    props: {
        onClick: {
            type: Function,
            default: () => {
                return
            }
        },
        route: {
            type: Object,
            default: null,
            validator: (value) => {
                if (value && typeof value === 'object') {
                    return !!(value.name || value.href)
                }
                return value == null
            }
        },
        classBlock: {
            type: ClassBlock,
            default: () => {
                return new ClassBlock('', 0, 0, '')
            },
            required: true
        },
        expert: {
            type: Object,
            default: () => {
                return { name: '', pictureUrl: '' }
            }
        },
        place: {
            type: Object,
            default: () => {
                return { name: '' }
            }
        },
        journey: Journey
    },
    data () {
        return {
            imageDidNotLoad: false
        }
    },
    computed: {
        routeToGo () {
            return {
                name: 'Class',
                params: {
                    teraId: this.classBlock.teraId,
                    journeyBlockId: this.classBlock.id
                }
            }
        },
        hasExpert () {
            return (
                this.classBlock.expertStatus &&
                this.classBlock.expertStatus === 'aceito'
            )
        },
        startTimeParsed () {
            return this.classBlock.startTime ? this.classBlock.startTime : null
        },
        endTimeParsed () {
            return this.classBlock.endTime ? this.classBlock.endTime : null
        },
        isActive () {
            var tzDifference = this.classBlock.endTime.getTimezoneOffset()
            var offsetTime = new Date(
                this.classBlock.endTime.getTime() + tzDifference * 60 * 1000
            )

            return offsetTime > new Date()
        },
        formattedMonth () {
            if (!this.startTimeParsed) return '---'

            return monthNames[this.startTimeParsed.getMonth()]
        },
        formattedDay () {
            if (!this.startTimeParsed) return '--'

            return this.startTimeParsed.getDate().toString().padStart(2, '0')
        },
        formattedHoursAndMinutes () {
            if (!this.startTimeParsed) return ''

            let hours = `${this.startTimeParsed.getUTCHours()}h${this.startTimeParsed.getUTCMinutes() > 0
                    ? this.startTimeParsed.getUTCMinutes()
                    : ''
                }`
            if (this.endTimeParsed) {
                hours += ` - ${this.endTimeParsed.getUTCHours()}h${this.endTimeParsed.getUTCMinutes() > 0
                        ? this.endTimeParsed.getUTCMinutes()
                        : ''
                    }`
            }

            return hours
        }
    },
    methods: {
        imageLoadError () {
            this.imageDidNotLoad = true
        },
        imageLoadSuccess () {
            this.imageDidNotLoad = false
        }
    }
}
</script>

<style lang="scss" scoped>
.rectangular-card {
    width: 100%;
    max-width: 752px;
    height: 192px;
    box-sizing: border-box;
    display: flex;

    @include breakpoint-small {
        height: auto;
        min-height: 232px;
        max-height: 544px;
    }

    >.slot-container {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        display: flex;

        @include breakpoint-small {
            height: 232px;
        }

        >.content {
            width: 480px;
            position: relative;

            @include breakpoint-small {
                width: 100%;
                height: 100%;
            }

            >.footer {
                width: 100%;
                min-height: 40px;
                position: absolute;
                bottom: $space-xxs;

                @include breakpoint-small {
                    bottom: $space-s;
                    padding-left: $space-xxs;
                    padding-right: $space-xxs;
                }

                >.event-type {
                    margin-top: 4px;
                }
            }
        }

        >.aside-left,
        .aside-right {
            height: 100%;
        }

        >.aside-left {
            width: 100%;
            width: 80px;
            left: 0;

            @include breakpoint-small {
                min-width: 64px;
            }
        }

        >.aside-right {
            width: 192px;

            @include breakpoint-small {
                display: none;
            }
        }
    }
}
.rectangular-card {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    background: #DBDBDB;
    color: #000;

    &.active {
        background: #8FFE81;
    }

    >.responsive-image-container {
        display: none;
        min-height: 312px;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        >.image {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;

            &.no-image {
                display: none !important;
            }
        }

        @include breakpoint-small {
            display: block;
            object-fit: cover;
        }
    }

    >.slot-container {
        >.aside-left {
            >.icon-container {
                padding: $space-s 0;
                box-sizing: inherit;
                display: flex;
                flex-wrap: wrap;
                flex-flow: column;
                align-items: center;
            }

            >.date-container {
                padding: $space-s 0;
                width: 80px;
                box-sizing: inherit;
                display: flex;
                flex-wrap: wrap;
                flex-flow: column;
                align-items: center;
                color: #707070;

                &.active {
                    color: #000;
                }

                >.date {
                    font-weight: $font-weight-light;
                    font-family: $font-rubik;
                    font-size: $font-size-m;
                    line-height: $font-size-m;
                }

                >.month {
                    margin-top: 4px;
                    font-family: $font-rubik;
                    font-size: $font-size-s;
                }
            }
        }

        >.content {
            >.content-container {
                padding: $space-s $space-m $space-s 0;
                box-sizing: inherit;

                @include breakpoint-small {
                    padding-left: $space-xxs;
                    padding-right: $space-xxs;
                }

                >.subtitle {
                    margin-top: 8px;
                }
            }
        }

        >.aside-right {
            >.image-container {
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 100%;

                >.image {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transform: translateX(-50%) translateY(-50%);
                    top: 50%;
                    left: 50%;
                    object-fit: cover;

                    &.no-image {
                        display: none !important;
                    }
                }
            }
        }
    }

    &.-inactive {
        .image {
            filter: grayscale(1);
            opacity: 0.8;
        }
    }
}
</style>