<template>
  <div class="quiz-feedback-container">
    <header v-if="!quizWillBeUsedForEvalutationPurposes" :style="typeFeedback ? 'display: flex' : 'display: none'">
      <clapping-animation v-if="type === 'success'" />
      <fist-animation v-else />

      <h2 v-if="type === 'success'">é isso aí!</h2>
      <h2 v-else-if="!quizWillBeUsedForEvalutationPurposes || remainingTries > 0">Continue tentando!</h2>
      <h2 v-else>Fim das tentativas</h2>
    </header>
    <header v-else :style="typeFeedback ? 'display: flex' : 'display: none'">
      <clapping-animation v-if="type === 'success'" />
      <fist-animation v-else-if="remainingTries > 0" />

      <h2 v-if="type === 'success'">é isso aí!</h2>
      <h2 v-else-if="remainingTries > 0">Continue tentando!</h2>
      <h2 v-else>Fim das tentativas</h2>
    </header>

    <div v-if="!quizWillBeUsedForEvalutationPurposes">
      <p v-if="type === 'success'">
        {{ text }}
      </p>
      <p v-else>
        Ah, se necessário, dê uma olhada no conteúdo visto na aula e, quando sentir segurança, tente outra vez.
      </p>
    </div>
    <div v-else>
      <p v-if="type === 'success'">
        {{ text }}
      </p>
      <p v-else-if="remainingTries > 0">
        Ah, se necessário, dê uma olhada no conteúdo visto na aula e, quando sentir segurança, tente outra vez.
      </p>
      <p v-else>
        Não desanime, clique em "ver gabarito" para visualizar a alternativa correta!
        <br />
        Recomendamos revisitar as aulas já vistas ou fazer uma pergunta na comunidade da Tera sobre este tema.
      </p>
    </div>
    
    


    <button v-if="(type === 'fail' && getIsSecondChance) && !quizWillBeUsedForEvalutationPurposes" @click="handleShowResponse">
      ver gabarito
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ClappingAnimation from './ClappingAnimation.vue'
import FistAnimation from './FistAnimation.vue'

export default Vue.extend({
  name: 'QuizFeedback',
  components: {
    ClappingAnimation,
    FistAnimation,
  },
  props: {
    type: String,
    text: String,
    quizWillBeUsedForEvalutationPurposes: Boolean,
    remainingTries: Number
  },
  data() {
    return {
      clapIcon: require('../../../assets/icons/clap_icon.svg'),
      clapAnimation: require('../../../assets/animation/clapping.json'),
      punchIcon: require('../../../assets/icons/punch_icon.svg'),
      typeFeedback: this.type
    }
  },
  computed: {
    ...mapGetters('quiz', ['getQuizOptions', 'getIsSecondChance'])
  },
  watch: {
    getQuizOptions() {
      this.typeFeedback = this.type
    }
  },
  mounted() {
    this.typeFeedback = this.type
  },
  methods: {
    handleShowResponse() {
      this.correctOption = this.getQuizOptions.find(option => option.itCorrect)
      this.setSelectedIndexOption(this.correctOption.index)
      this.setSelectedOption(this.correctOption)
      this.setCurrentResponse(true)
      this.typeFeedback = undefined

    },
    ...mapActions('quiz', ['setSelectedIndexOption', 'setSelectedOption', 'setCurrentResponse', 'setCurrentQuizOptions']),
  }
})
</script>

<style lang="scss">
.quiz-feedback-container {
  font-family: 'Rubik', sans-serif;

  > header {
    display: none;
    align-items: center;
    margin-bottom: 12px;
  
    > div {
      margin: 0 12px 12px 0;
    }

    > h2 {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  p {
    margin: 0;
    color: #333333;
    line-height: 24px;
  }

  > button {
    margin-top: 8px;
    border: 0;
    background: none;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
  }
}


</style>
