<template>
  <div class="progress-footer-tracker">
    <popover id="popover-footer" triggeredBy="popover-image-footer">
      <span v-if="isQuizNecessaryForCredential">
        Para conquistar o certificado você precisa completar 100% das aulas e desafios. Além disso, sua taxa de acerto nas
        questões deverá ser acima de 80%.
      </span>
      <span v-else>
        Para conquistar o certificado você precisa completar 100% das aulas e desafios.
      </span>
    </popover>

    <div class="image-wrapper">
      <img src="@/assets/on_demand/certificate.jpg" />
    </div>
    <div class="certificate-info">
      <div class="wrapper">
        <p v-if="isAccepted && currentJourneyCertificate" class="header">
          Parabéns! Certificado disponível!
        </p>
        <p v-else-if="isAccepted && !currentJourneyCertificate" class="header">
          Só um instante, estamos emitindo o seu certicado...
        </p>
        <p v-else class="header">
          Conquiste seu certificado:
        </p>
        <div class="overall">
          <p v-if="!isLoadingProgress" class="header">
            {{ currentJourneyProgress.ratio }}% Completo <img id="popover-image-footer" class="icon"
                                                              src="@/assets/icons/exclamation_mark_grey.svg"
            />
          </p>
          <p v-else class="header loading">
            Atualizando...
            <img id="popover-image-footer" class="icon" src="@/assets/icons/exclamation_mark_grey.svg" />
          </p>
          <div class="progress-bar" :class="{ loading: isLoadingProgress }">
            <div class="progress" :style="`width: ${currentJourneyProgress.ratio}%`"></div>
          </div>
        </div>
        <div class="criteria">
          <ul>
            <li :class="{ loading: isLoadingProgress }">
              <progress-bar v-if="isLoadingProgress" color="#FFFFFF" size="22" :percent="25" :showOnlyProgress="true"
                            :rotating="true"
              />

              <progress-bar v-else-if="blocksPercent < 99" color="#99FB8C" size="22" :percent="blocksPercent"
                            :showOnlyProgress="true"
              />
              <div v-else class="circle">
                <icon-base name="check" size="18" color="#99FB8C" />
              </div>
              {{ currentJourneyProgress.completedBlocks }}/{{
                currentJourneyProgress.blocks
              }} aulas
            </li>
            <li :class="{ loading: isLoadingProgress }">
              <progress-bar v-if="isLoadingProgress" color="#FFFFFF" size="22" :percent="25" :showOnlyProgress="true"
                            :rotating="true"
              />

              <progress-bar v-else-if="challengesPercent < 99" color="#99FB8C" size="22" :percent="challengesPercent"
                            :showOnlyProgress="true"
              />
              <div v-else class="circle">
                <icon-base name="check" size="18" color="#99FB8C" />
              </div> {{
                currentJourneyProgress.completedChallenges
              }}/{{ currentJourneyProgress.challenges }} desafios práticos
            </li>
            <li v-if="isQuizNecessaryForCredential"
                :class="{ loading: isLoadingProgress, warning: !isLoadingProgress && isQuizSuccessRatioBelowDesirable }"
            >
              <progress-bar v-if="isLoadingProgress" color="#FFFFFF" size="22" :percent="25" :showOnlyProgress="true"
                            :rotating="true"
              />

              <progress-bar v-else-if="currentJourneyProgress.quizSuccessRatio < 80" color="#99FB8C" size="22"
                            :percent="currentJourneyProgress.quizSuccessRatio" :showOnlyProgress="true"
              />
              <div v-else class="circle">
                <icon-base name="check" size="18" color="#99FB8C" />
              </div>
              {{
                currentJourneyProgress.quizSuccessRatio
              }}% acertos em {{ currentJourneyProgress.completedQuizzes }}/{{
                currentJourneyProgress.quizzes
              }}
              questões
            </li>
          </ul>
        </div>
        <div class="actions">
          <a v-if="isAccepted && currentJourneyCertificate" class="open" :href="currentJourneyCertificate.url"
             target="_blank"
          >Ver certificado</a>
          <button v-else-if="isAccepted && !currentJourneyCertificate" disabled class="open">
            Emitindo
            certificado...
          </button>
          <button v-else disabled class="open">Ver certificado</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/modules/Journeys/components/ProgressBar'
import Popover from '@/core/components/Popover.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProgresFooterTracker',
  components: {
    ProgressBar,
    Popover,
    Popover
  },
  data () {
    return {
      showProgressHelpDialog: false
    }
  },
  computed: {
    ...mapGetters('journeys',
      ['currentUserProgressByJourney',
        'currentUserJourney',
        'currentJourneyBlocks',
        'currentJourneyArticles',
        'isLoadingProgress'
      ]),
    ...mapGetters('certificates',
      ['certificates',
        'isLoadingCertificate'
      ]),
    currentJourneyProgress () {
      if (!this.currentUserProgressByJourney || this.currentUserProgressByJourney.length == 0) {
        return this.getDefaultState()
      }

      const progress = this.currentUserProgressByJourney.find(p => p.teraId === this.currentUserJourney.teraId)

      if (!progress) {
        return this.getDefaultState()
      }

      return progress
    },
    challengesPercent () {
      return (this.currentJourneyProgress.completedChallenges / this.currentJourneyProgress.challenges) * 100
    },
    blocksPercent () {
      return (this.currentJourneyProgress.completedBlocks / this.currentJourneyProgress.blocks) * 100
    },
    isAccepted () {
      if (this.isQuizNecessaryForCredential) {
        return this.currentJourneyProgress.ratio === 100 && this.currentJourneyProgress.quizSuccessRatio >= 80
      }
      
      return this.currentJourneyProgress.ratio === 100
    },
    isQuizSuccessRatioBelowDesirable () {
      return this.currentJourneyProgress.quizSuccessRatio < 80
    },
    currentJourneyCertificate () {
      const certificateForCurrentJourney = this.certificates.find((cert) => cert.teraId === this.currentUserJourney.teraId)

      return certificateForCurrentJourney || null
    },
    isQuizNecessaryForCredential () {
      const credentialRules = this.currentUserJourney.credentialRules
      if (!credentialRules) {
        return false
      }

      return credentialRules.quizSuccessRatio && credentialRules.quizSuccessRatio > 0 
    }
  },
  mounted () {
    this.bindCertificates()
  },
  methods: {
    ...mapActions('certificates', ['bindCertificates']),
    getDefaultState () {
      const chapters = this.currentJourneyArticles.map(a => a.chapters).flat()
      const totalQuizzes = chapters.filter(c => c.quizId).map(c => c.quizId)

      return {
        blocks: this.currentJourneyBlocks?.length,
        completedBlocks: 0,
        challenges: this.currentJourneyArticles.reduce((acc, data) => { return data.type === "challenge" ? acc + 1 : acc }, 0),
        completedChallenges: 0,
        quizzes: totalQuizzes.length,
        completedQuizzes: 0,
        quizSuccessRatio: 0,
        ratio: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes gradient-loading {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.progress-footer-tracker {
  width: 100%;
  height: 100%;
  max-height: 436px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: relative;
  box-sizing: border-box;

  .image-wrapper {
    @media (max-width: 800px) {
      display: none;
    }

    width: 100%;
    max-width: 50%;
    height: 100vh;
    max-height: 436px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

  }

  .certificate-info {
    width: 100%;

    .wrapper {
      max-width: 328px;
      max-height: 436px;
      width: 100%;
      height: 100vh;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;

      @media (max-width: 800px) {
        max-width: 100%;
      }

      .header {
        font-family: 'Rubik';
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
      }

      .overall {
        width: 100%;
        flex-grow: 2;
        margin: 12px 0 0 0;

        .header {
          font-family: 'Rubik';
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          color: #8FFE81;
          text-transform: uppercase;
          margin: 0 0 8px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.loading {
            text-transform: capitalize;
          }

          .icon {
            margin: 0 0 0 4px;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }

        .progress-bar {
          width: 100%;
          background-color: #575757;
          border-radius: 90px;
          height: 8px;

          &.loading {
            background: linear-gradient(270deg, #8F8F8F 0%, #575757 100%);
            background-size: 400% 400%;
            animation: gradient-loading 3s ease infinite;

            .progress {
              display: none;
            }
          }

          .progress {
            background-color: #8FFE81;
            border-radius: 10px;
            height: 8px;
          }
        }
      }

      .criteria {
        width: 100%;

        ul,
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          color: #fff;
          font-family: 'Rubik';
          font-size: 14px;
          line-height: 20px;

          &.loading {
            color: #707070;
          }

          &.warning {
            color: #FFB280
          }

          .circle {
            height: 20px;
            padding: 0 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        li {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 8px 0 0 0;
        }
      }

      .actions {
        width: 100%;
        margin: 24px 0 0 0;

        .open {
          background-color: #8FFE81;
          color: #000;
          height: 48px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Rubik';
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          border: none;
          cursor: pointer;

          &:active,
          &:focus,
          &:visited {
            color: #000;
          }

          &:hover {
            transform: translate(1px, 1px);
          }

          &:disabled {
            background-color: #000;
            color: #707070;
            border: 2px solid #707070;
            cursor: not-allowed;

            &:hover {
              transform: translate(0, 0);
            }
          }
        }
      }
    }
  }
}
</style>
