<template>
  <div class="middle-content">
    <div v-if="loading" class="loading-modal">
      <loading-animation />
    </div>

    <div class="progress-bar">
      <section :style="`width: ${(((currentStep + 1) * 100) / totalSteps)}%`" />
    </div>

    <div class="buttons-container">
      <button v-show="currentStep > 0" @click="prevStep">
        <img :src="backIcon" />
      </button>
    </div>


    <h1 v-if="currentStep === 0">O QUE VOCÊ ACHOU DESTA AULA?</h1>
    <h1 v-else-if="currentStep === totalSteps - 2">Quão difícil foi esta aula?</h1>
    <h1 v-else>O QUE ACHOU DO DESEMPENHO DO EXPERT?</h1>

    <p v-if="currentStep === 0">
      Aula: {{ currentJourneyArticle.name }}
    </p>
    <p v-else-if="currentStep === totalSteps - 2">
      Aula: {{ currentJourneyArticle.name }}
    </p>
    <p v-else>
      Expert: {{ currentExpert }}
    </p>

    <div class="stars-container">
      <div :class="currentRate >= 0 ? 'button-selected' : ''">
        <button v-if="currentRate >= 0" :disabled="userAlreadyRated" @click="handleRate(0)">
          <img :src="rateStarFilled" />
        </button>
        <button v-else :disabled="userAlreadyRated" @click="handleRate(0)">
          <img :src="rateStar" />
        </button>
        <span v-show="currentRate === 0 || currentRate === undefined">
          {{ currentStep === totalSteps - 2 ? 'Muito fácil' : 'Não gostei' }}
        </span>
      </div>
      <div :class="currentRate >= 1 ? 'button-selected' : ''">
        <button v-if="currentRate >= 1" :disabled="userAlreadyRated" @click="handleRate(1)">
          <img :src="rateStarFilled" />
        </button>
        <button v-else :disabled="userAlreadyRated" @click="handleRate(1)">
          <img :src="rateStar" />
        </button>
        <span v-show="currentRate === 1">
          {{ currentStep === totalSteps - 2 ? 'Fácil' : 'Ruim' }}
        </span>
      </div>
      <div :class="currentRate >= 2 ? 'button-selected' : ''">
        <button v-if="currentRate >= 2" :disabled="userAlreadyRated" @click="handleRate(2)">
          <img :src="rateStarFilled" />
        </button>
        <button v-else :disabled="userAlreadyRated" @click="handleRate(2)">
          <img :src="rateStar" />
        </button>
        <span v-show="currentRate === 2">
          {{ currentStep === totalSteps - 2 ? 'Regular' : 'Poderia melhorar' }}
        </span>
      </div>
      <div :class="currentRate >= 3 ? 'button-selected' : ''">
        <button v-if="currentRate >= 3" :disabled="userAlreadyRated" @click="handleRate(3)">
          <img :src="rateStarFilled" />
        </button>
        <button v-else :disabled="userAlreadyRated" @click="handleRate(3)">
          <img :src="rateStar" />
        </button>
        <span v-show="currentRate === 3">
          {{ currentStep === totalSteps - 2 ? 'Difícil' : 'Gostei' }}
        </span>
      </div>
      <div :class="currentRate >= 4 ? 'button-selected' : ''">
        <button v-if="currentRate >= 4" :disabled="userAlreadyRated" @click="handleRate(4)">
          <img :src="rateStarFilled" />
        </button>
        <button v-else :disabled="userAlreadyRated" @click="handleRate(4)">
          <img :src="rateStar" />
        </button>
        <span v-show="currentRate === 4 || currentRate === undefined">
          {{ currentStep === totalSteps - 2 ? 'Muito difícil' : 'Gostei muito!' }}
        </span>
      </div>
    </div>

    <button :disabled="currentRate === undefined" @click="saveAnswer">Próximo</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getCurrentUser } from '@/core/services/firebase'
import evaluationArticleService, { EvaluationType } from '@/modules/Journeys/services/evaluationArticleV2'
import LoadingAnimation from '@/modules/Journeys/components/LoadingAnimation.vue'

export default Vue.extend({
  name: 'RateStars',
  components: {
    LoadingAnimation
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    experts: {
      type: Array,
      required: true
    },
    prevStep: {
      type: Function,
      required: true
    },
    nextStep: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      rateStar: require('../../../assets/rateStar.svg'),
      rateStarFilled: require('../../../assets/rateStarFilled.svg'),
      backIcon: require('../../../assets/icons/caret_left.svg'),
      loading: false,
      showNextButton: false,
      currentUser: null,
      currentRate: undefined,
      userAlreadyRated: false
    }
  },

  computed:{
    ...mapGetters('journeys', ['currentJourneyArticle', 'currentTeraId']),

    currentExpert() {
      return this.experts[this.currentStep - 1]?.name
    }
  },
  watch: {
    currentStep() {
      this.currentRate = undefined
      this.userAlreadyRated = false

      this.getAnswer()
    },
  },
  async mounted(){
    this.currentUser = await getCurrentUser()

    this.getAnswer()
  },
  methods: {
    handleRate(index) {
      this.currentRate = index
    },
    async getAnswer() {
      this.loading = true

      if (this.currentStep === 0) {
        const answer = await evaluationArticleService.getEvaluationByType(
          this.currentTeraId,
          this.currentJourneyArticle.id,
          this.currentUser.uid,
          EvaluationType.CLASS
        )

        if (answer?.rate) {
          this.currentRate = answer.rate - 1

          this.userAlreadyRated = true
        }
      }

      if (this.currentStep === this.totalSteps - 2) {
        const answer = await evaluationArticleService.getEvaluationByType(
          this.currentTeraId,
          this.currentJourneyArticle.id,
          this.currentUser.uid,
          EvaluationType.DIFFICULT
        )

        if (answer?.rate) {
          this.currentRate = answer.rate - 1
          this.userAlreadyRated = true
        }
      }

      if (this.experts && this.experts[this.currentStep - 1] && this.currentRate !== 0 && this.currentStep !== this.totalSteps - 2) {
        const answer = await evaluationArticleService.getEvaluationExpert(
          this.currentTeraId,
          this.currentJourneyArticle.id,
          this.currentUser.uid,
          this.experts[this.currentStep - 1]?.id
        )

        if (answer?.rate) {
          this.currentRate = answer.rate - 1
          this.userAlreadyRated = true
        }
      }

      this.loading = false
    },
    async saveAnswer() {
      if (this.userAlreadyRated) {
        this.nextStep()
        return
      }

      this.loading = true

      let evaluation = {
        teraId: this.currentTeraId,
        blockId: this.currentJourneyArticle.id,
        studentId: this.currentUser.uid,
        rate: this.currentRate + 1,
      }

      if (this.currentStep === 0) {
        evaluation = {
          ...evaluation,
          type: EvaluationType.CLASS,
        }
      }

      if (this.currentStep === this.totalSteps - 2) {
        evaluation = {
          ...evaluation,
          type: EvaluationType.DIFFICULT,
        }
      }

      if (this.experts && this.experts[this.currentStep - 1] && this.currentRate !== 0 && this.currentStep !== this.totalSteps - 2) {
        evaluation = {
          ...evaluation,
          expertId: this.experts[this.currentStep - 1]?.id,
          type: EvaluationType.EXPERT,
        }
      }

      await evaluationArticleService.saveEvaluation(evaluation)

      this.currentRate = undefined
      this.nextStep()
      this.loading = false
    },
  }
})
</script>

<style lang="scss">
.middle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #171717;
  color: #fff;
  padding-bottom: 24px;
  text-align: center;
  position: relative;

  .loading-modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }

  .progress-bar {
    width: 100%;
    height: 8px;
    background: #242424;

    > section {
      height: 100%;
      background: #8ffe81;
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 12px 10px 0;
    height: 20px;

    button {
      display: flex;
      align-content: center;
      justify-content: center;
      background: none;
      border: 0;
      color: #bfbfbf;
      cursor: pointer;

      &:first-child {
        img {
          margin-right: 8px;
        }
      }

      &:last-child {
        img {
          margin-left: 8px;
        }
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  > h1 {
    font-size: 24px;
    line-height: 1.2;
    margin: 99px 0 0 0;
    text-transform: uppercase;
    text-align: center;
    width: 90%;
  }

  > p {
    margin: 8px 0 0 0;
    width: 90%;
    text-align: center;
    font-size: 16px;
    text-transform: initial;
  }

  .stars-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 28px 0 0 0;
    gap: 14px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: transparent;
        cursor: pointer;
        width: 30px;
        height: 30px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      > span {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        color: #fff;
        position: absolute;
        margin-top: 50px;
      }
    }

    .button-selected {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 14px;

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: transparent;
        cursor: pointer;
        width: 30px;
        height: 30px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      > span {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        color: #ededed;
        position: absolute;
        margin-top: 50px;
      }
    }
  }

  > button {
    font-family: 'Rubik', sans-serif;
    margin: 115px 24px 0 0;
    padding: 8px 24px;
    color: #000000;
    background: #8ffe81;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-end;

    &:hover {
      filter: brightness(0.8);
      transform: translate(1px, 1px);
    }

    &:disabled {
      background: #666666;
      color: #333333;
      cursor: default;

      &:hover {
        filter: none;
        transform: none;
      }
    }
  }
}

@media(max-width: 800px) {
  .middle-content {
    > button {
      margin: 54px 24px 0 0;
    }
  }
}
</style>
