var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { db } from '@/core/services/firebase';
const collection = db.collection('evaluations');
export var EvaluationType;
(function (EvaluationType) {
    EvaluationType["CLASS"] = "class";
    EvaluationType["DIFFICULT"] = "difficult";
    EvaluationType["EXPERT"] = "expert";
    EvaluationType["COMMENT"] = "comment";
})(EvaluationType || (EvaluationType = {}));
export default {
    saveEvaluation(evaluation) {
        return __awaiter(this, void 0, void 0, function* () {
            if (evaluation.createdAt === undefined)
                evaluation.createdAt = new Date();
            if (evaluation.updatedAt === undefined)
                evaluation.updatedAt = new Date();
            yield collection.add(evaluation);
        });
    },
    updateEvaluationText(teraId, blockId, studentId, text) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield collection
                .where("teraId", '==', teraId)
                .where("blockId", '==', blockId)
                .where("studentId", '==', studentId)
                .where("type", '==', EvaluationType.COMMENT)
                .limit(1)
                .get();
            const evaluationId = data.docs[0].id;
            yield db
                .collection('evaluations')
                .doc(evaluationId)
                .update({ text: text, updatedAt: new Date() });
        });
    },
    getEvaluationExpert(teraId, blockId, studentId, expertId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield collection
                .where("teraId", '==', teraId)
                .where("blockId", '==', blockId)
                .where("studentId", '==', studentId)
                .where("expertId", '==', expertId)
                .limit(1)
                .get();
            if (((_a = data === null || data === void 0 ? void 0 : data.docs) === null || _a === void 0 ? void 0 : _a.length) === 0)
                return undefined;
            return data.docs[0].data();
        });
    },
    getEvaluationByType(teraId, blockId, studentId, type) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield collection
                .where("teraId", '==', teraId)
                .where("blockId", '==', blockId)
                .where("studentId", '==', studentId)
                .where("type", '==', type)
                .limit(1)
                .get();
            if (((_a = data === null || data === void 0 ? void 0 : data.docs) === null || _a === void 0 ? void 0 : _a.length) === 0)
                return undefined;
            return data.docs[0].data();
        });
    }
};
