<template>
  <base-tab-menu
    type="div"
    class="class-tab-menu"
    :active-tab="localActiveTab"
    active="SOBRE"
    :tab-menu-items="tabMenuItems"
    @input="localActiveTab = $event"
  >
  </base-tab-menu>
</template>

<script>
export default {
  name: 'ClassTabMenu',
  status: 'prototype',
  release: '0.0.1',
  props: {
    activeTab: String,
    hasContent: Boolean
  },
  computed: {
    tabMenuItems() {
      const tabs = [{ name: 'SOBRE', component: 'about' }]

      if (this.hasContent) {
        tabs.push({ name: 'CONTEÚDO', component: 'content' })
      }

      return tabs
    },
    localActiveTab: {
      get() {
        return this.activeTab
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.class-tab-menu {
  position: sticky !important;
  top: 120px;
  z-index: 8;

  ::v-deep > .items {
    @include breakpoint-small {
      align-self: center;
      margin-left: auto !important;
    }

    > .item {
      min-width: 140px !important;
    }
  }
}
</style>
