<template>
  <div class="rate-container">
    <rate-stars
      v-if="currentStep <= totalSteps - 2"
      :currentStep="currentStep"
      :totalSteps="totalSteps"
      :prevStep="prevStep"
      :nextStep="nextStep"
      :experts="currentArticleExperts || []"
    />

    <rate-open-field
      v-else-if="currentStep === totalSteps - 1"
      :currentStep="currentStep"
      :totalSteps="totalSteps"
      :prevStep="prevStep"
      :nextStep="nextStep"
    />

    <rate-last-step-v2
      v-else
      :backToStep1="() => changeStep(0)"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import RateStars from './RateStars.vue'
  import RateOpenField from './RateOpenField.vue'
  import RateLastStepV2 from './RateLastStepV2.vue'

  export default Vue.extend({
    name: 'RateArticleV2',
    components: {
      RateStars,
      RateOpenField,
      RateLastStepV2
    },
    data() {
      return {
        currentStep: 0
      }
    },
    computed: {
      ...mapGetters('journeys', ['currentJourneyArticle', 'currentArticleExperts']),

      totalSteps() {
        return this.currentArticleExperts.length + 3
      }
    },
    mounted() {
      const isFinish = localStorage.getItem(`@tera/evaluate-${this.currentJourneyArticle.id}`)

      if (isFinish) {
        this.changeStep(this.totalSteps)
      }
    },
    methods: {
      nextStep() {
        this.currentStep++
      },
      prevStep() {
        this.currentStep--
      },
      changeStep(stepNumber) {
        this.currentStep = stepNumber
      }
    }
  })
</script>

<style lang="scss">
  .rate-container {
    font-family: 'Rubik', sans-serif;
    margin: 10px auto 0;
    width: 560px;


    @media (max-width: 800px) {
      width: 80%;
      margin: 10px auto 20px;
    }
  }
</style>
