class ArticleBlock {
  constructor(index, moduleIndex, articleRef, teraId, isOptional) {
    this.index = index;
    this.moduleIndex = moduleIndex;
    this.articleRef = articleRef;
    this.teraId = teraId;
    this.isOptional = isOptional
  }

  type = "article";
}

export default ArticleBlock;
