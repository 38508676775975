<template>
  <div :style="showModal ? 'display: flex' : 'display: none'" class="quiz-modal-container">
    <div>
      <header>
        <div>
          <img :src="pinIcon" />
          <h2>NÃO SE PREOCUPE</h2>
        </div>

        <button @click="handleCloseModal">
          <img :src="closeIcon" />
        </button>
      </header>

      <p>
        O quiz não tem pontuação e não será usado para avaliar seu desempenho,
        serve apenas para você saber o quanto já aprendeu até aqui.
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'QuizModal',
  data() {
    return {
      showModal: false,
      pinIcon: require('../../../assets/icons/pin_icon.svg'),
      closeIcon: require('../../../assets/icons/close_icon.svg')
    }
  },
  mounted() {
    this.quizModalViewCount = localStorage.getItem('tera@quizModalViewCount')

    if (this.quizModalViewCount && Number(this.quizModalViewCount) > 5) {
      this.showModal = false
    } else {
      this.showModal = true
    }
  },
  methods: {
    handleCloseModal() {
      this.quizModalViewCount = localStorage.getItem('tera@quizModalViewCount')

      this.showModal = false

      localStorage.setItem('tera@quizModalViewCount', `${1 + (this.quizModalViewCount ? Number(this.quizModalViewCount) : 0)}`)
    }
  }
})
</script>

<style lang="scss">
.quiz-modal-container {
  font-family: 'Rubik', sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 15;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  > div {
    background: #fff;
    width: 632px;
    height: 111px;
    border-radius: 4px;
    margin-bottom: 82px;
    box-sizing: border-box;
    padding: 16px;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;

        > h2 {
          font-size: 16px;
          text-transform: uppercase;
          margin: 0 0 0 16px;
        }
      }

      > button {
        background: none;
        border: 0;
        cursor: pointer;

        &:hover {
          filter: brightness(0.8)
        }
      }
    }

    > p {
      color: #575757;
      display: block;
      margin: 0;
      padding: 0 12px 0 38px;
    }
  }

  @media(max-width: 800px) {
    align-items: center;

    > div {
      width: calc(100% - 32px);
      height: auto;
    }
  }
}
</style>
