<template>
  <div class="middle-content-last">
    <img :src="greenCheckCircle" alt="" />
    <h1>Obrigado por compartilhar seu feedback!</h1>

    <button @click="review">Ver Minhas Respostas</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'RateLastStepV2',

  props: {
    backToStep1: {
      type: Function,
      required: true
    },
  },

  data() {
    return {
      greenCheckCircle: require('../../../assets/icons/greenCheckCircle.svg'),
    }
  },

  computed: {
    ...mapGetters('journeys', ['currentJourneyArticle']),
  },

  mounted() {
    localStorage.setItem(`@tera/evaluate-${this.currentJourneyArticle.id}`, true)
  },

  methods: {
    review() {
      this.backToStep1()
    },
  }
})
</script>

<style lang="scss">
.middle-content-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #171717;
  color: #fff;
  padding-bottom: 24px;
  text-align: center;
  position: relative;

  > img {
    margin-top: 144px;
  }

  > h1 {
    color: white;
    font-size: 20px;
    font-weight: normal;
    width: 90%;
  }

  > button {
    font-family: 'Rubik', sans-serif;
    margin: 141px 24px 0 0;
    padding: 8px 24px;
    color: #000000;
    background: #8ffe81;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-end;

    &:hover {
      filter: brightness(0.8);
      transform: translate(1px, 1px);
    }

    &:disabled {
      background: #666666;
      color: #333333;
      cursor: default;

      &:hover {
        filter: none;
        transform: none;
      }
    }
  }
}

@media(max-width: 800px) {
  .middle-content-open {
    > button {
      margin: 54px 24px 0 0;
    }
  }
}
</style>
