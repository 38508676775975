import ArticleBlock from "./index";

const articleBlockConverter = {
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return new ArticleBlock(
      data.index,
      data.moduleIndex,
      data.articlesRef,
      data.teraId,
      data.isOptional
    );
  },
};

export default articleBlockConverter;
