<template>
  <node-view-wrapper class="container" :style="cssVars">
    <node-view-content :class="classObject" as="ol" />
  </node-view-wrapper>
</template>

<script>
import Vue from 'vue'
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2'

export default Vue.extend({
  name: 'ColouredCardList',
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  props: nodeViewProps,
  data() {
    return {
      colors: {
        black: '#000000',
        'gray-20': '#e0e0e0',
        'green-60': '#00a992',
        white: '#ffffff'
      }
    }
  },
  computed: {
    classObject() {
      return {
        'white-list': this.node.attrs.backgroundColor === 'white'
      }
    },
    cssVars() {
      return {
        '--bg-color': this.colors[this.node.attrs.backgroundColor],
        '--fg-color': this.colors[this.node.attrs.foregroundColor]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}
</style>
