<template>
  <div class="text">
    <div class="headings">
      <div v-if="visualization === 'order'" class="aside-right">
        <heading tag="h1">{{ orderPadded }}</heading>
      </div>
      <div v-if="visualization === 'date'" class="aside-right">
        <heading tag="h1">{{ dateDay }}</heading>
        <heading tag="h2">{{ dateMonth }}</heading>
      </div>
      <div class="aside-left">
        <heading tag="h2" font-weight="light">{{ title }}</heading>
        <div class="paragraph">
          <p>{{ description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const monthNames = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEC'
]

export default {
  name: 'ModuleDescription',
  props: {
    order: Number,
    title: String,
    description: String,
    date: Date,
    visualization: {
      type: String,
      default: 'order'
    }
  },
  computed: {
    orderPadded() {
      return String(this.order).padStart(2, '0')
    },
    dateDay() {
      return this.date.getDate()
    },
    dateMonth() {
      const month = this.date.getMonth()
      return monthNames[month]
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  width: 100%;
  text-align: left;

  > .headings {
    display: flex;
    position: relative;
    margin-bottom: 24px;

    > .aside-right {
      width: 96px;
      top: -10px;
      transform: translateX(-100%);
      position: absolute;

      @include breakpoint-small {
        transform: none;
        position: inherit;
        margin-bottom: 2px;
      }
    }

    > .aside-left {
      width: 100%;
      max-width: 730px;
      height: 100%;
      margin-left: 16px;
      position: relative;

      &::before {
        content: ' ';
        width: 6px;
        height: 100%;
        position: absolute;
        background-color: $color_black;
        left: -15px;
      }

      .paragraph {
        p {
          font-family: 'rubik';
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
    }

    @include breakpoint-small {
      max-width: 312px;
      flex-direction: column;
    }
  }
}
</style>
