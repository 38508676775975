<template>
  <transition name="fade-onboarding">
    <div v-if="isVisible" class="journey-manual-teraway">
      <div class="header" type="header">
        <base-header>
          <template v-slot:default>
            <heading-with-before color="white" :before-color-variation="true">
              {{ title }}
            </heading-with-before>
          </template>
          <template v-slot:footer>
            <heading color="white" tag="h4">{{ subtitle }}</heading>
          </template>
        </base-header>
      </div>
      <div class="main">
        <div class="image">
          <base-image
            image-url="https://storage.googleapis.com/tera-lxs-images/manual-teraway-illustration.jpg"
            image-description="A ilustração brinca com a perspectiva da pessoa que a observa. Apresenta quatro escadas que sobrem e descem para diferente direções, inclusive na diagonal. O início e fim de cada escada varia com quatro janelas. Em cada janela há um desenho de uma pessoa fazendo alguma atividade, como usando um notebook, lendo um livro ou observando."
          ></base-image>
        </div>
        <div class="first-section">
          <paragraph-with-title
            v-for="(p, index) in firstSection"
            :key="index"
            :title="p.title"
            :paragraph="p.paragraph"
          ></paragraph-with-title>
          <base-image
            image-url="https://storage.googleapis.com/tera-lxs-images/manual-teraway-1.jpg"
            image-description="Imagem"
          ></base-image>
        </div>
        <div class="cards">
          <masonry
            class="masonry-container"
            :gutter="16"
            :cols="{ default: 3, 1000: 2, 800: 1 }"
          >
            <text-panel-with-title
              v-for="(t, index) in terawayPrinciples"
              :key="index"
              :title="t.title"
              :text="t.text"
            ></text-panel-with-title>
          </masonry>
        </div>
        <div class="second-section">
          <div class="paragraph">
            <paragraph-with-title
              title="Por que construir uma metodologia proprietária?"
              paragraph="A cultura da Tera é voltada para o Human-Centered Design e isso significa que nós colocamos as pessoas no centro de nossas iniciativas. A nossa intenção é revolucionar a aprendizagem trabalhando junto com todos os envolvidos - principalmente com vocês, estudantes."
            ></paragraph-with-title>
            <paragraph-with-title
              paragraph="Para ajudar na reinvenção da educação, criamos uma metodologia proprietária chamada Teraway. Queremos construir e entregar experiências incríveis para quem estuda, trabalha, colabora e participa da Tera"
            ></paragraph-with-title>
          </div>
          <div class="-quote">
            <heading-with-before tag="h2" font-weight="normal">
              Por isso fugimos dos métodos tradicionais e incrementamos nossa
              metodologia alterando alguns conceitos
            </heading-with-before>
          </div>
          <div class="paragraph">
            <paragraph-with-title
              v-for="(p, index) in secondSection"
              :key="index"
              :title="p.title"
              :paragraph="p.paragraph"
            ></paragraph-with-title>
          </div>
          <base-image
            image-url="https://storage.googleapis.com/tera-lxs-images/manual-teraway-2.jpg"
            image-description="Imagem"
          ></base-image>
        </div>
        <div class="third-section">
          <div class="paragraph">
            <paragraph-with-title
              title="Experts"
              paragraph="Não temos professores, temos experts. Gostamos do conceito de liderança compartilhada: diferentemente das escolas tradicionais, não queremos mestres detentores de todo o saber, que vão avaliar pessoas com provas e trabalhos. Queremos mentores com vivências diferentes, pessoas que vão acrescentar conhecimentos através de suas experiências profissionais (e, por que não, pessoais). Os experts da Tera são profissionais apaixonados por educação e estarão presentes para orientar o aprendizado e aprender também."
            ></paragraph-with-title>
          </div>
          <div class="-quote">
            <heading-with-before tag="h2" font-weight="normal">
              Gostamos de pensar que somos todos estudantes: pessoas curiosas e
              proativas, que se dedicam a aprender e também a ensinar.
            </heading-with-before>
          </div>
          <div class="paragraph">
            <paragraph-with-title
              v-for="(p, index) in thirdSection"
              :key="index"
              :title="p.title"
              :paragraph="p.paragraph"
            ></paragraph-with-title>
          </div>
          <div class="-span-quote">
            <span>
              “O segredo para a mudança é focar suas energias não na luta contra
              o antigo mas na construção do novo.” - Sócrates
            </span>
          </div>
        </div>
      </div>
      <div class="footer">
        <footer-onboarding></footer-onboarding>
      </div>
    </div>
  </transition>
</template>

<script>
import ParagraphWithTitle from '@/modules/Journeys/components/JourneyManual/ParagraphWithTitle'
import FooterOnboarding from '@/modules/Journeys/components/JourneyManual/FooterOnboarding'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JourneyManualTeraway',
  components: {
    ParagraphWithTitle,
    FooterOnboarding
  },
  props: {
    headingQuote: String
  },
  data() {
    return {
      isVisible: false,
      title: 'Conheça o Teraway',
      subtitle: 'O jeito de fazer educação na Tera',
      firstSection: [
        {
          title: 'O que é Teraway?',
          paragraph:
            'A Teraway, nossa metodologia proprietária e nosso jeito de ser e fazer educação, foi desenvolvida com a colaboração e a participação de diversos atores e autores: de estudantes à experts; de startups à grandes empresas; de instituições de educação à cursos não regulamentados; de colaboradores à consultores e pessoas com livros publicados. Colocamos as pessoas para ajudar a tornar nosso jeito de fazer educação incrível.'
        }
      ],
      secondSection: [
        {
          title: 'Cursos',
          paragraph:
            'Os cursos são programas de jornada de aprendizado nos padrões Tera, em que estudantes, experts e facilitadores constroem juntos o conhecimento através da troca de experiências e vivências. Focamos em uma experiência de dinamismo, mão-na-massa e proximidade com a realidade profissional do mercado. Ao longo da jornada, os estudantes criarão projetos baseados em cenários atuais e reais e terão um contato aprofundado com a rotina da carreira em foco.'
        },
        {
          title: 'Estudantes',
          paragraph:
            'Na Tera, gostamos de pensar que somos todos estudantes: pessoas curiosas e proativas, que se dedicam a aprender e também a ensinar, e não só que recebem conhecimento de outrem. Não temos respostas prontas para os estudantes; temos dúvidas e desafios que serão debatidos e transformados em construção de conhecimento em conjunto.'
        }
      ],
      thirdSection: [
        {
          title: 'Evidências de aprendizado',
          paragraph:
            'Não temos provas ou trabalhos valendo nota; temos evidências de aprendizagem através de discussões dentro e fora de sala de aula, com práticas de exercícios e dinâmicas, além de apresentação de projetos desenvolvidos durante os nossos cursos.'
        },
        {
          title: 'Competências e habilidades',
          paragraph:
            'Daremos um certificado ao fim do curso. Achamos importante a validação do curso através de um documento com seu nome, para mostrar para o mundo o seu comprometimento com a sua própria educação. Mas, mais do que apenas te levar à conclusão de uma nova formação, focamos sempre em construir uma bagagem de aprendizado para gerar valor e impacto na sua forma de atuar no mercado.'
        }
      ],
      terawayPrinciples: [
        {
          title: 'Welcome to open space',
          text:
            'Dentro e fora de sala, incorporar práticas de espaços colaborativos e organizadamente dinâmicos para fomentar valores como responsabilidade, empatia, criatividade, participação ativa, melhoria contínua, diversidade, organização e resolução de problemas. Quem estuda, trabalha, colabora na Tera busca praticar os princípios: 1.Quem veio ao encontro era para ter vindo 2. As atividades acontecem quando eram para acontecer 3.O que acontecer era para ter acontecido 4. Quando o encontro terminar, terminou;'
        },
        {
          title: 'Flipped Classroom',
          text:
            'Para que os encontros sejam fluídos e para que o aprendizado aconteça de forma rápida, eficaz e equalitária para todos os estudantes, disponibilizamos diversos conteúdos para serem estudados fora de sala. A intenção é acrescentar conhecimento prévio e estimular reflexões a serem debatidas pelas pessoas, valorizando o momento de encontro com trocas enriquecedoras. Indicaremos leituras, vídeos e exercícios para que vocês estudem sozinhos e se preparem para as aulas e para a jornada de aprendizado do curso;'
        },
        {
          title: 'Give your best',
          text:
            'Liderar pelo exemplo com a intenção de melhorar para si e para os outros ajuda a construir um legado e gera valor compartilhado. Quem estuda, trabalha e colabora na Tera procura sempre dar o melhor de si e deixar as pessoas e lugares melhores do que encontrou porque, no fim do dia, todo mundo se beneficia disso;'
        },
        {
          title: 'Peer to peer',
          text:
            'Estimular discussões, trocas e diálogos para trazer diferentes perspectivas, vivências, especialidades das pessoas também constrói um ambiente de colaboração, empatia e aprendizado coletivo. Quem estuda, trabalha e colabora na Tera aprende com os outros, com a multidisciplinaridade, com histórias e vivências;'
        },
        {
          title: 'Role play',
          text:
            'Colocar as pessoas em dinâmicas que simulam a realidade da competência aprendida as prepara para os problemas e situações reais. Quem estuda na Tera faz exercícios de “role-play” e atua como estivesse no papel da pessoa que realmente está no mercado;'
        },
        {
          title: 'Push-pull mode on',
          text:
            'Construir um ambiente de confiança e transparência na comunicação, para pessoas darem feedback ajuda para alinhar expectativas, percepções e sugerir mudanças, adaptações e melhorias. Quem estuda, trabalha e colabora na Tera quer dar e receber feedback para tornar as relações e experiências ainda melhores;'
        },
        {
          title: 'Learning by doing',
          text:
            'Permitir que a pessoa aprenda fazendo é mais prazeroso e faz com que a fixação dos assuntos seja mais rápida e envolvente. Quem estuda na Tera vai aprender técnicas, ferramentas e conceitos na prática para poder aplicar o que fizer sentido no dia seguinte;'
        },
        {
          title: 'Project based',
          text:
            'Desafiar as pessoas a trabalhar em problemas reais acelera o processo de aprendizagem, e dá abertura para a criatividade, erro ou para falha sem punição. Quem estuda na Tera faz da sala de aula um laboratório de iniciativas e desenvolve ideias, protótipos e projetos como na vida real;'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['currentJourneyMoment'])
  },
  mounted() {
    const {
      journeys,
      modules,
      journeyManuals,
      journeyManualTeraway
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyManuals, journeyManualTeraway]

    this.setRouteBreadcrumb(breadcrumb)

    const interval = setInterval(() => {
      this.isVisible = true
      clearInterval(interval)
    }, 700)
  },
  methods: {
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang='scss' scoped>
.journey-manual-teraway {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .header {
    width: 100%;
    height: 100%;
    min-height: 236px;
    > .base-header {
      min-height: 236px;
      @include breakpoint-small {
        min-height: 176px;
      }
    }
    @include breakpoint-small {
      min-height: unset;
    }
  }

  > .main {
    width: 100%;
    max-width: $container-l;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .image {
      margin-top: -40px;
      position: sticky;
    }

    > .cards {
      padding: 10px calc(calc(100vw - 900px) / 2);
      > .masonry-container {
        .text-panel-with-title {
          margin: 16px auto 0;
          height: fit-content;
        }
      }
    }
    @include breakpoint-small {
      width: 100%;
      max-width: 300px;
      padding: 0 32px;
    }
  }
  .-quote {
    width: 100%;
    max-width: 728px;
    margin-left: 2%;

    > h2 {
      font-weight: lighter;
    }
    @include breakpoint-small {
      margin-left: 4%;
    }
  }

  .-span-quote {
    max-width: $container-m;
    margin-bottom: $space-xl;

    > span {
      color: $color-gray-60;
      font-family: $font-georgia;
      font-size: $font-size-m;
      line-height: 32px;
    }
  }
  > .footer {
    width: 100%;
  }

  @include breakpoint-large {
    width: $container-xl;
  }
  @include breakpoint-medium {
    max-width: $container-m;
  }
  @include breakpoint-small {
    width: 100%;
    max-width: $container-s;
  }
}
</style>
