var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/core/store';
import { getCurrentUser } from '@/core/services/firebase';
import { journeyRoutes } from '@/modules/Journeys/routes';
import { coreRoutes } from '@/core/routes.js';
const allModulesRoutes = [coreRoutes, journeyRoutes];
const getAllModulesRoutesMerged = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let allRoutes = [];
    allModulesRoutes.forEach(r => {
        allRoutes = allRoutes.concat(r);
    });
    return allRoutes;
};
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: getAllModulesRoutesMerged(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            if (to.hash) {
                return {
                    selector: to.hash,
                    offset: { x: 0, y: 10 }
                };
            }
            return { x: 0, y: 0 };
        }
    }
});
router.beforeEach((to, from, next) => __awaiter(void 0, void 0, void 0, function* () {
    if (to.meta) {
        const { routeContext } = to.meta;
        if (routeContext && Store.getters['system/routeContext'] !== routeContext) {
            Store.dispatch('system/setRouteContext', routeContext);
        }
    }
    if (from.name) {
        const currentRoute = { name: from.name, params: from.params };
        Store.dispatch('system/addRouteHistory', currentRoute);
    }
    if (to.path && to.path.toLowerCase().indexOf('login') > -1) {
        return next();
    }
    const isAuthorized = (yield getCurrentUser()) || false;
    if (!isAuthorized) {
        return next({ name: 'Login' });
    }
    // set user id to google analytics
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Vue.$setUser(isAuthorized.uid);
    next();
}));
export default router;
