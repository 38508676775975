<template>
  <div v-if="hasBreadcrumb" class="breadcrumb">
    <div class="content">
      <div class="navigation-container">
        <div @click="back">
          <icon name="ic_left_24" />
        </div>
        <div
          :class="{ '-inactive': forwardRoutes.length === 0 }"
          @click="forward"
        >
          <icon name="ic_right_24" />
        </div>
      </div>
      <div class="links-container">
        <ul>
          <li class="links-mobile-shortener">...</li>
          <router-link
            v-for="(breadcrumb, i) in routes"
            :key="'breadcrumb_' + i"
            :to="breadcrumb.to"
            tag="li"
          >
            <span @click="handleBreadcrumbLinkClick(breadcrumb.displayName)">
              {{ breadcrumb.displayName }}
            </span>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'Breadcrumb',
  data() {
    return {
      forwardRoutes: [],
      routes: [],
      getter: null
    }
  },
  computed: {
    hasBreadcrumb() {
      return !!this.routeBreadcrumb
    },
    ...mapGetters('journeys', [
      'isLoading',
      'originalsIsLoading',
      'currentOriginalsChapter'
    ]),
    ...mapGetters('system', [
      'routeHistory',
      'routeHasHistory',
      'routeBreadcrumb'
    ])
  },
  watch: {
    routeBreadcrumb: function () {
      if (!this.hasBreadcrumb) {
        return
      }

      this.routes = this.getRoutes()
    },
    isLoading: function () {
      if (this.isLoading || !this.hasBreadcrumb) {
        return
      }

      this.routes = this.getRoutes()
    },
    currentOriginalsChapter: function () {
      if (this.originalsIsLoading || !this.hasBreadcrumb) {
        return
      }

      this.routes = this.getRoutes()
    },
    hasBreadcrumb: function () {
      if (!this.hasBreadcrumb) {
        this.routes = []
        this.forwardRoutes = []
      }
    }
  },
  mounted() {
    this.routes = this.getRoutes()
  },
  methods: {
    handleBreadcrumbLinkClick(breadcrumbDisplayName) {
      Vue.$logEvent({ category: 'lxs:geral', action: 'clique:link', label: breadcrumbDisplayName })
    },
    back() {
      if (this.routes.length > 1) {
        Vue.$logEvent({ category: 'lxs:geral', action: 'clique:link', label: 'voltar' })

        const routeForward = this.routes.pop()
        const routeToGo = this.routes[this.routes.length - 1]

        if (this.routeHasHistory) {
          const routeFromStore = this.routeHistory[this.routeHistory.length - 1]
          if (routeFromStore.name === routeToGo.to.name) {
            this.forwardRoutes.push(routeForward)
            this.popRouteHistory()
            history.back()
            return
          }
        }

        this.forwardRoutes.push(routeForward)
        this.$router.replace(routeToGo.to)
      }
    },
    forward() {
      if (this.forwardRoutes.length > 0) {
        Vue.$logEvent({ category: 'lxs:geral', action: 'clique:link', label: 'avancar' })

        const routeToGo = this.forwardRoutes.pop()
        this.$router.push(routeToGo.to)
      }
    },
    getRoutes() {
      if (!this.routeBreadcrumb) {
        return
      }

      return this.routeBreadcrumb.map((b) => {
        const displayName = this.unparseStoreParam(b.displayName)

        const params = {}

        if (b.urlParams) {
          for (const param in b.urlParams) {
            const currentParam = b.urlParams[param]
            params[param] = this.unparseStoreParam(currentParam)
          }
        }

        return { displayName, to: { name: b.routeName, params } }
      })
    },
    unparseStoreParam(displayName) {
      if (displayName.indexOf('{') === -1) {
        return displayName
      }

      let parts = displayName.split(/[{}]+/)

      parts = parts.map((part) => {
        if (!part.startsWith('store:')) {
          return part
        }

        part = part.replace('store:', '')

        return part.split('.').reduce((object, property) => {
          if (typeof object[property] === 'number') {
            return Number(object[property])
          }

          return object[property] ? object[property] : ''
        }, this.$store.getters)
      })

      return parts.join('')
    },
    ...mapActions('system', ['popRouteHistory'])
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 60px;
  background-color: $color-white;
  z-index: 9;

  > .content {
    max-width: $container-xl;
    width: 100%;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > .navigation-container {
      margin: auto 16px auto 10px;
      display: inline-flex;
      cursor: pointer;

      @media (min-width: $container_xl) {
        margin: auto 16px auto 0;
      }

      .-inactive {
        opacity: 0.2;
      }
    }

    > .links-container {
      flex-grow: 2;
      align-items: flex-start;
      justify-content: flex-start;
      display: inline-flex;
      margin: auto 0;

      ul {
        padding: 0;
        margin: auto 0;
        list-style: none;
        display: inline-flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        li {
          text-decoration: none;
          font-family: 'Rubik', sans-serif;
          font-size: 16px;
          line-height: 20px;
          color: $color-gray-80;
          cursor: pointer;
          margin: 0 2.5px;
          max-width: 192px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &::before {
            content: '|';
            cursor: default;
          }

          &:first-child {
            cursor: default;

            &::before {
              content: '';
            }
          }

           &:nth-child(2) {
            cursor: default;

            &::before {
              content: '';
            }
          }

          &.router-link-exact-active {
            color: $color-black;
          }

          &.links-mobile-shortener {
            display: none;
          }

          @media (max-width: $container_s) {
            display: none;

            &.router-link-exact-active,
            &.links-mobile-shortener {
              display: inline-block;
              max-width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
