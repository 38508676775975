class OriginalsBlock {
  constructor(index, moduleIndex, originalsRef, teraId) {
    this.index = index;
    this.moduleIndex = moduleIndex;
    this.originalsRef = originalsRef;
    this.teraId = teraId;
  }

  type = "originals";
}

export default OriginalsBlock;
