<template>
  <transition name="fade-onboarding">
    <div v-if="isVisible" class="journey-manual-intro">
      <div class="header" type="header">
        <base-header>
          <template v-slot:default>
            <heading-with-before color="white" :before-color-variation="true">
              {{ title }}
            </heading-with-before>
          </template>
          <template v-slot:footer>
            <heading color="white" tag="h4">{{ subtitle }}</heading>
          </template>
        </base-header>
      </div>
      <div class="main">
        <div class="image">
          <base-image
            image-url="https://storage.googleapis.com/tera-lxs-images/manual-intro-illustration.jpg"
            image-description="Ilustração com muitas cores e formas ao fundo, formando um círculo. No primeiro plano há sombra de silhueta de uma pessoa."
          ></base-image>
        </div>
        <div class="first-section">
          <paragraph-with-title
            v-for="(p, index) in firstSection"
            :key="index"
            :title="p.title"
            :paragraph="p.paragraph"
          >
          </paragraph-with-title>
          <base-image
            image-url="https://storage.googleapis.com/tera-lxs-images/manual-intro-1.jpg"
            image-description="Imagem"
          ></base-image>
        </div>
        <div class="second-section">
          <paragraph>
            Além de estarmos muito contentes com a sua chegada, esse é um
            momento importante para a gente alinhar e comunicar alguns detalhes
            de experiência dos cursos da Tera. Reserve alguns minutos para se
            dedicar a leitura dos conteúdos, conhecer nossa metodologia Teraway
            e explorar algumas ferramentas que disponibilizamos neste manual
            para facilitar seu aprendizado.
          </paragraph>
          <div class="side-by-side-images">
            <base-image
              image-url="https://storage.googleapis.com/tera-lxs-images/manual-intro-2.jpg"
              image-description="Imagem"
            ></base-image>
            <base-image
              image-url="https://storage.googleapis.com/tera-lxs-images/manual-intro-3.jpg"
              image-description="Imagem"
            ></base-image>
          </div>
          <paragraph>
            Para que você tenha uma jornada agradável, cheia de aprendizados e
            novas conexões, separamos alguns conteúdos abaixo para ajudar você a
            compreender os pontos essenciais da nossa experiência. Vamos
            decolar!
          </paragraph>
        </div>
      </div>
      <div class="footer">
        <footer-onboarding></footer-onboarding>
      </div>
    </div>
  </transition>
</template>

<script>
import ParagraphWithTitle from '@/modules/Journeys/components/JourneyManual/ParagraphWithTitle'
import FooterOnboarding from '@/modules/Journeys/components/JourneyManual/FooterOnboarding'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JourneyManualIntro',
  components: {
    ParagraphWithTitle,
    FooterOnboarding
  },
  props: {
    paragraph: String
  },
  data() {
    return {
      isVisible: false,
      title: 'Primeiros Passos',
      subtitle: 'Sua introdução aos cursos da Tera',
      firstSection: [
        {
          title: 'Que bom que você veio!',
          paragraph:
            'Estamos muito felizes que você acreditou na Tera para ajudar a aperfeiçoar suas habilidades e competências para a economia digital. Fazendo um curso na Tera, você faz parte da nossa comunidade de aprendizagem para a vida toda (comunidade lifelong learning). Já se imaginou aprendendo algo novo durante a vida toda e tendo a possibilidade de escolher e se adaptar ao mercado de trabalho? Você pode!'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['currentJourneyMoment'])
  },
  mounted() {
    const {
      journeys,
      modules,
      journeyManuals,
      journeyManualIntro
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyManuals, journeyManualIntro]

    this.setRouteBreadcrumb(breadcrumb)

    const interval = setInterval(() => {
      this.isVisible = true
      clearInterval(interval)
    }, 700)
  },
  methods: {
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang='scss' scoped>
.journey-manual-intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .header {
    width: 100%;
    height: 100%;
    min-height: 236px;
    > .base-header {
      min-height: 236px;
      @include breakpoint-small {
        min-height: 176px;
      }
    }
    @include breakpoint-small {
      min-height: unset;
    }
  }

  > .main {
    width: 100%;
    max-width: $container-l;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .image {
      margin-top: -40px;
      position: sticky;
    }

    > .second-section {
      width: 100%;
      max-width: $container-m;
      > .paragraph {
        margin: $space-xl 0;
      }

      > .side-by-side-images {
        width: 100%;
        display: flex;

        > .base-image {
          width: 100%;
          max-width: 368px;
          &:first-of-type {
            margin-right: $space-s;
          }
        }

        @include breakpoint-small {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .paragraph {
      font-family: $font-georgia;
      line-height: $space-m;
    }

    @include breakpoint-large {
      width: $container-xl;
    }
    @include breakpoint-medium {
      max-width: $container-m;
    }
    @include breakpoint-small {
      width: $container-s;
      max-width: 300px;
    }
  }

  > .footer {
    width: 100%;
  }
}
</style>
