<template>
  <section>
    <img src="@/assets/rewards.svg" alt="Logo Tera Rewards" />
    <img class="logo" src="@/assets/tera_rewards.svg" alt="Logo Tera Reward" />
    <div>
      <h2>Mostre aos seus amigos como é o futuro.</h2>
      <p>Indique amigos e ganhe descontos progressivos a cada inscrição. Seus indicados ganham <em>20% OFF</em> para dar o próximo passo na carreira com a Tera.</p>
      <div class="button-referral">
        <base-button
          type="a"
          href="https://rewards.somostera.com/?utm_campaign=Referral&utm_source=lxs&utm_content=botao-indique-aqui"
          fontColor="-font-black"
          color="-background-white"
          borderColor="-white"
          target="_blank"
          aria-label="Quero indicar"
          @click="sendEvent"
        >
          Indicar amigos
        </base-button>
      </div>
    </div>
    <img src="@/assets/little_balls_banner_rewards.svg" alt="Imagem de um qaudrado formado por vários círculos brancos. Está localizado no lado direito do banner de indique amigos.">
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'BannerReferral',
  methods: {
    sendEvent() {
      const event = this.$trackeventsTemplates.events.trackButtonRefer
      this.setEvent(event)
    },
    ...mapActions('events', ['setEvent'])
  }
}

</script>

<style lang="scss" scoped>
section {
  background: linear-gradient(#575757,#575757) padding-box, linear-gradient(135deg, #99FB8C, #00A992) border-box;
  border: 7px solid transparent;
  box-sizing: border-box;
  display: flex;
  position: relative;
  margin-bottom: 64px;

  @include breakpoint-small {
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
  }

  img {
     @include breakpoint-small {
       display: none;
     }
  }

  .logo {
    display: none;

     @include breakpoint-small {
       display: block;
       width: 155px;
     }
  }

  @include breakpoint-medium {
    > img:first-child {
      margin-left: 42px !important;
      margin-right: 48px !important;
      width: 103px !important;
    }
    > img:last-child {
      display: none;
    }
  }

  > img:first-child {
    margin-left: 72px;
    margin-right: 56px;
  }

  > img:last-child {
    position: absolute;
    right: 0;  
  }

  div {
    max-width: 574px;
    width:100%;

      @include breakpoint-medium {
        max-width: 488px;  
        width:100%;    
      }

      @include breakpoint-small {
        max-width: 264px;
        width:100%; 
      }

    > h2 {
      padding-top: 48px;
      text-transform: uppercase;
      color: #fff;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      font-family: 'Rubik';
      margin: 0;


      @include breakpoint-medium {
        font-size: 32px;
        line-height: 32px;
      }

       @include breakpoint-small {
         font-size: 24px;
         line-height: 24px;
         font-weight: 700;
         text-align: center;
         padding-top: 19px;
         //padding-bottom: 24px;
       }
    }

    > p {
      font-family: 'Rubik';
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-top: 8px;
      margin-bottom: 24px;

       @include breakpoint-medium {
        font-size: 16px;
        line-height: 20.5px;
       }

       @include breakpoint-small {
          font-size: 16px;
          line-height: 20.5px;
          
       }

      em {
        color: #8DFE7E;
        font-weight: 700;
        font-style: normal;  
      }
    }
    > .button-referral {
      padding-bottom: 48px;

      @include breakpoint-medium {
        padding-bottom: 24px;
      }

       @include breakpoint-small {
        padding-bottom: 24px;
      }

        a {
          line-height: 20px;
          font-weight: 700;
          font-size: 16px;
          max-width:264px;
          height: 48px;
          width: 100%;
        }
      }
    }
}
</style>