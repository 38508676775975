<template lang="html">
  <div :class="['base-tab-menu', 'journey-tab-menu', '-background-' + backgroundColor]">
    <div ref="tab-menu-items-container" class="items">
      <div
        v-for="(item, index) in tabMenuItems"
        :key="'tab-menu-items_' + index"
        ref="tab-menu-items"
        class="item"
        :class="{ 'active': localActive === item.component }"
        @click="changeActiveTab(item)"
      >
        {{ item.name }}
        <div class="notification-dot" :class="{'active': item.notification}"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "JourneyOnDemandTabMenu",
    status: "prototype",
    release: "0.0.6",
    model: {
      prop: "active",
    },
    props: {
      /**
       * The html element name used for the tab menu.
       */
      type: {
        type: String,
        default: "div",
      },
      /**
       * State which tab is active when initiated (using name of the component).
       */
      activeTab: {
        required: true,
        type: String,
      },
      /**
       * Menu items to be displayed on the tab menu.
       */
      tabMenuItems: {
        required: true,
        type: Array,
      },
      /**
       * The card background color.
       * The color can be any value in the color tokens
       */
      backgroundColor: {
        type: String,
        default: "black",
        validator: value => {
          return value != "" || value != undefined
        },
      },
      /**
       * A route object to be used with router-link.
       */
      route: {
        type: String,
        validator: value => {
          return value && value.name
        },
      },
    },
    computed: {
      localActive: {
        get() {
          return this.activeTab
        },
        set(val) {
          this.$emit("input", val)
        },
      },
    },
    methods: {
      changeActiveTab(item) {
        this.localActive = item.component
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .base-tab-menu {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    background-color: black;
  
    @include breakpoint-small {
      width: 100%;
      overflow-x: auto;
    }
  
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  
    &:hover::-webkit-scrollbar {
      display: none;
    }
  
    > .items {
      width: 100%;
      max-width: 752px;
      display: block;
      align-items: flex-start;
      align-self: center;
      margin: auto auto 2px;
      cursor: pointer;
      white-space: nowrap;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
  
      @include breakpoint-medium {
        margin-left: 42px;
      }
  
      @include breakpoint-small {
        width: fit-content;
        margin-left: 32px;
      }
  
      > .item {
        min-width: 48px;
        max-width: 120px;
        width: 100%;
        height: 20px;
        margin-top: 10px;
        margin-right: 15px;
        padding: 0 0 12px;
        color: $color-white;
        text-decoration: none;
        font-family: $font-rubik;
        font-size: 16px;
        line-height: 20px;
        display: inline-flex;
        align-self: center;
        justify-content: center;
        position: relative;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
  
        @include breakpoint-medium {
          max-width: 634px;
        }
  
        &::after {
          content: "";
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: 0;
          left: 0;
          visibility: hidden;
          transition: 0.3s ease-in-out all;
        }
  
        &:hover {
          &::after {
            background: $color-gray-60;
            visibility: visible;
          }
        }
  
        &.active {
          color: #8FFE81;
  
          &::after {
            background: #8FFE81 !important;
            visibility: visible;
          }
        }

        .notification-dot {

          &::after {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            background: #000;
            border-radius: 100%;
            top: -8px;
            bottom: initial;
            left: initial;
            right: 16px;
            visibility: hidden;
            transition: 0.3s ease-in-out all;
          }

          &.active {
            color: #8FFE81;
    
            &::after {
              background: #8FFE81 !important;
              visibility: visible;
            }
          }

        }

      }
    }
  }
  </style>