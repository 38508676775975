import People from './index';
import { getOptionalParametersFromDataSnapshot } from '../../utils/classUtils';

const peopleConverter = {
  fromFirestore (snapshot, options) {
    const data = snapshot.data(options);
    var people = new People(
      data.name,
      data.company,
      data.roles,
      getOptionalParametersFromDataSnapshot(data, snapshot)
    );

    return people;
  }
};

export default peopleConverter;
