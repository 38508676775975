<template>
  <div class="modules-content">
    <div class="header">
      <journey-header :course-name="courseName" :journey-name="journeyName" :course-icon="courseIcon"></journey-header>
    </div>

    <journey-tab-menu v-if="!tabsWithLivesInsteadOfModules" :tab-menu-items="tabMenuItems" :active-tab="activeTab" @input="changeActiveTab($event)">
    </journey-tab-menu>
    <journey-on-demand-tab-menu v-else :tab-menu-items="tabMenuItems" :active-tab="activeTab" @input="changeActiveTab($event)">
    </journey-on-demand-tab-menu>
    

    <main-container>
      <div v-if="!tabsWithLivesInsteadOfModules">
        <div v-if="journeyIsEvent || journeyIsDex || journeyIsNanodegree">
          <module-visualization-dpw v-if="journeyIsEvent" :current-user-journey="currentUserJourney"
                                    :current-journey-blocks="currentJourneyBlocks" :current-journey-experts="currentJourneyExperts"
                                    :current-journey-places="currentJourneyPlaces" :current-journey-articles="currentJourneyArticles"
                                    :current-journey-articles-progress="currentJourneyArticlesProgress" :child-route-params="childRouteParams"
          >
          </module-visualization-dpw>
          <module-visualization-dex v-else-if="journeyIsDex" :current-user-journey="currentUserJourney"
                                    :current-journey-blocks="currentJourneyBlocks" :current-journey-experts="currentJourneyExperts"
                                    :current-journey-places="currentJourneyPlaces" :current-journey-articles="currentJourneyArticles"
                                    :current-journey-articles-progress="currentJourneyArticlesProgress" :child-route-params="childRouteParams"
          >
          </module-visualization-dex>
          <module-visualization-nanodegree v-else-if="journeyIsNanodegree" :current-user-journey="currentUserJourney"
                                           :current-journey-blocks="currentJourneyBlocks" :current-journey-experts="currentJourneyExperts"
                                           :current-journey-places="currentJourneyPlaces" :current-journey-articles="currentJourneyArticles"
                                           :current-journey-articles-progress="currentJourneyArticlesProgress" :child-route-params="childRouteParams"
          >
          </module-visualization-nanodegree>
        </div>
        <div v-else>
          <module-visualization-default :current-user-journey="currentUserJourney"
                                        :current-journey-blocks="currentJourneyBlocks" :current-journey-experts="currentJourneyExperts"
                                        :current-journey-places="currentJourneyPlaces" :current-journey-articles="currentJourneyArticles"
                                        :current-journey-articles-progress="currentJourneyArticlesProgress" :child-route-params="childRouteParams"
          >
          </module-visualization-default>
        </div>
      </div>
      <div v-else>
        <div v-if="activeTab === 'aulas'">
          <on-demand-articles-visualization :current-user-journey="currentUserJourney"
                                            :current-journey-blocks="currentJourneyBlocks" :current-journey-experts="currentJourneyExperts"
                                            :current-journey-places="currentJourneyPlaces" :current-journey-articles="currentJourneyArticles"
                                            :current-journey-articles-progress="currentJourneyArticlesProgress" :child-route-params="childRouteParams"
          >
          </on-demand-articles-visualization>
        </div>
        <div v-if="activeTab === 'ao-vivo'">
          <on-demand-lives-visualization :current-user-journey="currentUserJourney"
                                         :current-journey-blocks="currentJourneyBlocks" :current-journey-experts="currentJourneyExperts"
                                         :current-journey-places="currentJourneyPlaces" :current-journey-articles="currentJourneyArticles"
                                         :current-journey-articles-progress="currentJourneyArticlesProgress"
                                         :child-route-params="childRouteParams"
          ></on-demand-lives-visualization>
        </div>
      </div>
    </main-container>

    <progress-scroll-tracker v-if="tabsWithLivesInsteadOfModules ? showProgressBottomBar && activeTab == 'aulas' : showProgressBottomBar"></progress-scroll-tracker>

    <progress-footer-tracker v-if="tabsWithLivesInsteadOfModules ? showProgressOnFooter && activeTab == 'aulas' : showProgressOnFooter"></progress-footer-tracker>
  </div>
</template>

<script>
import MainContainer from '@/core/components/MainContainer'
import JourneyHeader from '@/modules/Journeys/components/JourneyHeader.vue'
import JourneyTabMenu from '@/modules/Journeys/components/JourneyTabMenu.vue'
import JourneyOnDemandTabMenu from '../components/JourneyOnDemandTabMenu.vue'
import ModuleVisualizationDefault from '@/modules/Journeys/components/ModuleVisualization/Default.vue'
import ModuleVisualizationDpw from '@/modules/Journeys/components/ModuleVisualization/DPW.vue'
import ModuleVisualizationDex from '@/modules/Journeys/components/ModuleVisualization/DEX.vue'
import ModuleVisualizationNanodegree from '@/modules/Journeys/components/ModuleVisualization/Nanodegree.vue'
import OnDemandArticlesVisualization from '../components/ModuleVisualization/OnDemandArticles.vue'
import OnDemandLivesVisualization from '@/modules/Journeys/components/ModuleVisualization/OnDemandLives.vue'
import ProgressScrollTracker from '@/modules/Journeys/components/ProgressScrollTracker.vue'
import ProgressFooterTracker from '@/modules/Journeys/components/ProgressFooterTracker.vue'

import { mapGetters, mapActions } from 'vuex'
import { showProgressBottomBar, showProgressOnFooter, tabsWithLivesInsteadOfModules } from '@/core/services/remote-config'

export default {
  name: 'Modules',
  components: {
    MainContainer,
    JourneyHeader,
    JourneyTabMenu,
    JourneyOnDemandTabMenu,
    ModuleVisualizationDefault,
    ModuleVisualizationDpw,
    ModuleVisualizationDex,
    ModuleVisualizationNanodegree,
    OnDemandArticlesVisualization,
    OnDemandLivesVisualization,
    ProgressScrollTracker,
    ProgressFooterTracker
  },
  props: {
    teraId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      activeTab: tabsWithLivesInsteadOfModules(this.teraId) ? 'aulas' : '1'
    }
  },
  computed: {
    showProgressBottomBar () {
      return showProgressBottomBar(this.teraId)
    },
    showProgressOnFooter () {
      return showProgressOnFooter(this.teraId)
    },
    tabsWithLivesInsteadOfModules () {
      return tabsWithLivesInsteadOfModules(this.teraId)
    },
    courseName () {
      const { courseInfo } = this.currentUserJourney

      return courseInfo && courseInfo.name !== undefined ? courseInfo.name : ''
    },
    journeyName () {
      return this.currentUserJourney.name
    },
    courseIcon () {
      const { courseInfo } = this.currentUserJourney

      if (this.journeyIsEvent) {
        return {
          url: `https://storage.googleapis.com/tera-lxs-images/icons/icon_${this.currentUserJourney.teraId.toLowerCase()}_gradient.svg`,
          alt: `Ícone do curso: ${courseInfo.name}`
        }
      }

      return {
        url: `https://storage.googleapis.com/tera-lxs-images/icons/icon_${courseInfo.acronym}_gradient.svg`,
        alt: `Ícone do curso: ${courseInfo.name}`
      }
    },
    childRouteParams () {
      return { teraId: this.teraId }
    },
    tabMenuItems () {
      if (this.tabsWithLivesInsteadOfModules) {
        return [{
          name: 'Aulas',
          component: 'aulas'
        }, {
          name: 'Ao vivo',
          component: 'ao-vivo',
          notification: this.isOnDemandAndHasActiveLive
        }]
      }


      var modules = []

      modules = this.currentUserJourney.modules.map((m, index) => {
        const moduleNumber = index + 1
        const moduleName = ('0' + moduleNumber).slice(-2)
        return {
          name: moduleName,
          component: String(moduleNumber)
        }
      })

      return modules
    },
    journeyIsEvent () {
      return this.currentUserJourney.teraId.includes('EV_REM')
    },
    journeyIsDex () {
      return this.currentUserJourney.teraId.includes('DEX')
    },
    journeyIsNanodegree () {
      return (
        this.currentUserJourney.teraId.includes('2022') &&
        ['DPLAD', 'UXDAD', 'DTAAD', 'MKTAD'].some((acronym) => this.currentUserJourney.teraId.includes(acronym))
      )
    },
    isOnDemandAndHasActiveLive () {
      if (!tabsWithLivesInsteadOfModules) return false

      let lives = this.currentJourneyBlocks.filter((jb) => jb.type !== 'originals' && jb.type !== 'articles')
      let activeLives = lives.filter((journeyBlock) => {
            return journeyBlock.endTime >= new Date()
          })
      return activeLives && activeLives.length > 0
    },
    ...mapGetters('journeys', [
      'isLoading',
      'currentUserJourney',
      'currentJourneyBlocks',
      'journeyBlocksHasUpdates',
      'currentJourneyExperts',
      'currentJourneyPlaces',
      'currentJourneyArticles',
      'currentJourneyArticlesProgress'
    ]),
    ...mapGetters('account', ['person', 'user'])
  },
  beforeMount () {
    this.setCurrentTeraId(this.teraId)

    if(!this.tabsWithLivesInsteadOfModules) {
      this.enableScrollspyForActiveTabs()
    } else {
      const hasPreviouslyActiveTab = localStorage.getItem(`@somostera-lxs/onDemandActiveTab-${this.currentUserJourney.teraId}`)
      this.activeTab = hasPreviouslyActiveTab || this.activeTab
    }

    const { journeys, modules } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules]

    this.setRouteBreadcrumb(breadcrumb)
  },
  destroyed () {
    this.disableScrollspyForActiveTabs()
  },
  methods: {
    enableScrollspyForActiveTabs () {
      window.addEventListener('scroll', this.changeActiveTabOnScroll, {
        passive: true
      })
    },
    disableScrollspyForActiveTabs () {
      window.removeEventListener('scroll', this.changeActiveTabOnScroll)
    },
    changeActiveTabOnScroll () {
      const modules = document.querySelectorAll('.module-container')
      modules.forEach((el, index) => {
        const elTop = el.getBoundingClientRect().top - 190
        const elBottom = el.getBoundingClientRect().bottom
        if (elTop <= 0 && elBottom >= 0) {
          this.activeTab = String(index + 1)
        }
      })
    },
    changeActiveTab (value) {
      if(this.tabsWithLivesInsteadOfModules) {
        this.activeTab = value
        localStorage.setItem(`@somostera-lxs/onDemandActiveTab-${this.currentUserJourney.teraId}`, value)
        if (value == 'ao-vivo') {
          this.tabMenuItems[1].notification = false
        }
        return
      }

      const tab = this.$el.querySelector('#module-' + value)
      window.scrollTo(0, tab.getBoundingClientRect().y - 190 + window.scrollY)
    },
    ...mapActions('journeys', ['setCurrentTeraId', 'setCurrentJourneyBlockId', 'updateJourneyBlocksWithStream']),
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.modules-content {
  width: 100%;
  box-sizing: border-box;

  >.main-container {
    margin: 0 auto;

    @include breakpoint-medium {
      max-width: 752px;
    }
  }
}
</style>
