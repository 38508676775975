import journeyService from '@/modules/Journeys/services/journeysService'
import articleService from "@/modules/Journeys/services/articleService"
import Journey from '@/core/entities/models/Journey'
import ClassBlock from '@/core/entities/models/JourneyBlocks/ClassBlock'

import storageApi from '@/core/api/storage.api'
import { db } from '@/core/services/firebase'

const INITIAL_ONSNAPSHOT_VALUE = -1
const RESETED_ONSNAPSHOT_VALUE = 0

export const getInitialState = () => {
  return {
    userJourneys: [],
    currentJourneyBlocks: [],
    currentUserOriginals: [],
    currentJourneyArticles: [],
    currentJourneyArticlesProgress: [],
    currentChapterInteractions: [],
    currentJourneyExperts: [],
    currentJourneyArticlesWithExperts: [],
    currentJourneyPlaces: [],
    currentUserJourneyPeople: [],
    currentUserProgressByJourney: [],
    isLoading: false,
    isLoadingProgress: false,
    journeyProgressIsLoadingDebounce: null,
    activeReferral: false,
    activeCourseBanner: false,
    originalsIsLoading: false,
    originalsLoadingDebounce: null,

    currentTeraId: '',
    currentModuleIndex: null,
    currentJourneyBlockId: '',
    currentOriginalsUrl: '',
    currentOriginalsChapter: null,
    currentArticleExperts: [],

    currentArticleId: '',

    streamedJourneyBlocksUpdates: INITIAL_ONSNAPSHOT_VALUE,
    streamedJourneyBlocks: [],
    streamedJourneyBlocksUnsubscribe: null,

    streamedJourneyProgressUpdates: INITIAL_ONSNAPSHOT_VALUE,
    streamedJourneyProgress: [],
    streamedJourneyProgressUnsubscribe: null
  }
}

const state = getInitialState()

const getters = {
  userJourneys: state => {
    return state.userJourneys.sort((a, b) => {
      return b.endsAt - a.endsAt
    })
  },
  currentUserJourney: state => {
    if (state.userJourneys.length > 0) {
      const journey = state.userJourneys.find(j => j.teraId === state.currentTeraId)
      if (journey) {
        return journey
      }
    }
    return new Journey('', {}, '', new Date(), new Date())
  },
  currentUserProgressByJourney: state => {
    return state.currentUserProgressByJourney
  },
  currentJourneyProgress: state => {
    if (state.currentUserProgressByJourney.length > 0) {
      return state.currentUserProgressByJourney.find(j => j.teraId === state.currentTeraId)
    }
    return { ratio: 0 }
  },
  currentChapterIndex: state => {
    const { currentChapterInteractions } = state
    return currentChapterInteractions.length > 0 ? currentChapterInteractions.findIndex(i => i.currentChapter) : 0
  },
  currentChapterIsLastChapter: (state, getters) => {
    if (!getters.currentJourneyArticle) return false
    return getters.currentChapterIndex === getters.currentJourneyArticle.chapters.length - 1
  },
  currentJourneyBlocks: state => {
    return state.currentJourneyBlocks
  },
  currentLastClassJourneyBlock: state => {
    var jbSize = state.currentJourneyBlocks.length
    if (!jbSize) {
      return null
    }

    var lastJourneyBlockIndex = jbSize - 1

    while (lastJourneyBlockIndex > 0) {
      const journeyBlock = state.currentJourneyBlocks.find(jb => jb.index === lastJourneyBlockIndex)

      if (journeyBlock.type === 'originals') {
        lastJourneyBlockIndex -= 1
      } else {
        return journeyBlock
      }
    }
  },
  currentUserOriginals: state => {
    return state.currentUserOriginals
  },
  currentJourneyArticles: state => {
    return state.currentJourneyArticles
  },
  currentJourneyArticlesProgress: state => {
    return state.currentJourneyArticlesProgress
  },
  currentJourneyArticle: state => {
    return state.currentJourneyArticles.find(c => c.id === state.currentArticleId)
  },
  getArticleById: (state) => (id) => {
    return state.currentJourneyArticles.find(article => article.id === id)
  },
  currentOriginals: state => {
    if (state.currentUserOriginals.length > 0) {
      const originals = state.currentUserOriginals.find(to => to.url === state.currentOriginalsUrl)
      return originals
    }
    return null
  },
  currentOriginalsChapter: state => {
    return state.currentOriginalsChapter
  },
  currentOriginalsUrl: state => {
    return state.currentOriginalsUrl
  },
  currentOriginalsChapterObject: state => {
    if (state.currentUserOriginals.length > 0) {
      const originals = state.currentUserOriginals.find(to => to.url === state.currentOriginalsUrl)
      const chapter = originals ? originals.chapters[state.currentOriginalsChapter - 1] : null
      return chapter
    }
    return null
  },
  currentJourneyBlock: state => {
    if (state.currentJourneyBlocks.length > 0) {
      const journeyBlock = state.currentJourneyBlocks.find(jb => jb.id === state.currentJourneyBlockId)
      if (journeyBlock) {
        return journeyBlock
      }
    }
    return new ClassBlock('', {}, '', new Date(), new Date())
  },
  currentJourneyPeople: state => {
    const { currentTeraId } = state
    if (currentTeraId) {
      return state.currentUserJourneyPeople.find(t => t.teraId === currentTeraId)
    }
    return null
  },
  isLoading: state => {
    return state.isLoading
  },
  isLoadingProgress: state => {
    return state.isLoadingProgress
  },
  originalsIsLoading: state => {
    return state.originalsIsLoading
  },
  journeyBlocksHasUpdates: state => {
    return state.streamedJourneyBlocksUpdates > 0
  },
  journeyProgressHasUpdates: state => {
    return state.streamedJourneyProgressUpdates > 0
  },
  currentExpert: (state, getters) => {
    const { currentJourneyBlock } = getters
    if (currentJourneyBlock) {
      return state.currentJourneyExperts.find(e => e.id === currentJourneyBlock.expertId)
    }
  },
  currentPlace: (state, getters) => {
    const { currentJourneyBlock } = getters
    if (currentJourneyBlock) {
      return state.currentJourneyPlaces.find(p => p.id === currentJourneyBlock.placeId)
    }
  },
  currentJourneyExperts: state => {
    return state.currentJourneyExperts
  },
  currentArticleExperts: state => {
    return state.currentArticleExperts
  },
  currentJourneyArticlesWithExperts: state => {
    return state.currentJourneyArticlesWithExperts
  },
  currentJourneyPlaces: state => {
    return state.currentJourneyPlaces
  },
  currentTeraId: state => {
    return state.currentTeraId
  },
  currentJourneyBlockId: state => {
    return state.currentJourneyBlockId
  },
  currentModuleIndex: state => {
    return state.currentModuleIndex
  },
  currentModuleIndexPadded: state => {
    if (state.currentModuleIndex) {
      return String(state.currentModuleIndex).padStart(2, '0')
    }
    return null
  },
  currentJourneyMoment: (state, getters) => {
    const { currentUserJourney } = getters

    if (!currentUserJourney) return ''

    const courseStartDate = currentUserJourney.startsAt
    const courseEndDate = currentUserJourney.endsAt
    const today = new Date()

    if (today < courseStartDate) {
      return 'pre-journey'
    } else if (today > courseEndDate) {
      return 'post-journey'
    } else {
      return 'during-journey'
    }
  },
  activeReferral: state => {
    return state.activeReferral
  },
  activeCourseBanner: state => {
    return state.activeCourseBanner
  }
}

const actions = {
  async uploadDownloadableContent({ state, getters }, { file, name }) {
    try {
      var url = await storageApi.uploadContent(file)
      const material = {
        name: name,
        url: url,
        type: file.name.substring(file.name.lastIndexOf('.') + 1).toUpperCase(),
        sizeInMb: (file.size / 1024 / 1024).toFixed(2)
      }
      journeyService.updateJourneyBlockDownloads(getters.currentUserJourney.id, state.currentJourneyBlockId, material)
    } catch (error) {
      console.log(error)
    }
  },
  async deleteSpecificDownloadableContent({ state, getters }, material) {
    try {
      await journeyService.deleteJourneyBlockSpecificDownload(
        getters.currentUserJourney.id,
        state.currentJourneyBlockId,
        material
      )
    } catch (error) {
      console.log(error)
    }
  },
  async getAllByPersonRef({ commit, state, rootState, dispatch }) {
    const { user } = rootState.account
    if (!user || !user.uid) {
      return
    }

    commit('toggleLoading', true)

    try {
      const journeys = await journeyService.getAllByPerson(user.uid)

      commit('setUserJourneys', journeys)

      const journeyPeople = await journeyService.getJourneyPeopleByAuthId(user.uid)

      commit('setCurrentUserJourneyPeople', journeyPeople)
      dispatch('setCurrentProgressByJourney', user.uid)

      commit('toggleOriginalsIsLoading', true)
      var currentOriginals = await journeyService.getAllOriginalsFromJourneys(
        journeys.map(j => {
          return j.id
        })
      )
      commit('setUserOriginals', currentOriginals)
      commit('toggleOriginalsIsLoading', false)

      if (state.currentTeraId) {
        await dispatch('setCurrentJourneyBlocks')
      } else {
        commit('toggleLoading', false)
      }
    } catch (e) {
      commit('toggleLoading', false)
    }
  },
  async setCurrentTeraId({ commit, state, dispatch }, teraId) {
    if (state.currentTeraId === teraId) {
      return
    }

    commit('setCurrentTeraId', teraId)

    await dispatch('setCurrentJourneyBlocks')
  },
  setCurrentModuleIndex({ commit }, moduleIndex) {
    commit('setCurrentModuleIndex', moduleIndex)
  },
  setCurrentArticleExperts({ commit }, experts) {
    commit('setCurrentArticleExperts', experts)
  },
  setCurrentOriginalsUrl({ commit }, originalsUrl) {
    commit('setCurrentOriginalsUrl', originalsUrl)
  },
  setCurrentOriginalsChapter({ commit }, originalsChapter) {
    commit('setCurrentOriginalsChapter', originalsChapter)
  },
  setCurrentJourneyBlockId({ commit }, journeyBlockId) {
    commit('setCurrentJourneyBlockId', journeyBlockId)
  },
  toggleLoading({ commit }, isLoading) {
    commit('toggleLoading', isLoading)
  },
  async setCurrentProgressByJourney({ commit, dispatch }, uid){
    dispatch('unbindStreamedJourneyProgressByUser')

    dispatch('bindStreamedJourneyProgressByUser')

    const progressByJourney = await journeyService.getAllProgressFromJourneys(uid)

    commit('setCurrentProgressByJourney', progressByJourney)
  },
  async setCurrentJourneyBlocks({ commit, getters, rootState, dispatch }) {
    const { user } = rootState.account
    if (!user || !user.uid) {
      return
    }

    if (getters.currentUserJourney.id) {
      commit('toggleLoading', true)

      dispatch('unbindStreamedJourneyBlocks')

      dispatch('bindStreamedJourneyBlocksByJourneyId')

      const currentUserJourney = getters.currentUserJourney

      const journeyBlocks = await journeyService.getAllJourneyBlocksByJourneyId(currentUserJourney.id)

      commit('setCurrentJourneyBlocks', journeyBlocks)

      const articles = await journeyService.getAllArticlesFromJourneyBlocks(journeyBlocks)
      const articlesProgress = await journeyService.getAllProgressFromArticles(user.uid)

      commit('setCurrentJourneyArticles', articles)
      commit('setCurrentJourneyArticlesProgress', articlesProgress)

      const { experts, places, articlesWithExperts } = await journeyService.getExpertAndPlaceDataFromJourneyBlocks(journeyBlocks, articles)

      commit('setCurrentJourneyExperts', experts)
      commit('setCurrentJourneyPlaces', places)
      commit('setCurrentJourneyArticlesWithExperts', articlesWithExperts)

      commit('toggleLoading', false)
    }
  },
  setCurrentArticleId({ commit, dispatch }, id) {
    commit('setCurrentArticleId', id)
    dispatch('setCurrentArticleInteractions')
  },
  async updateArticlesProgress({ commit, rootState }) {
    const { user } = rootState.account
    if (!user || !user.uid) {
      return
    }

    const articlesProgress = await journeyService.getAllProgressFromArticles(user.uid)

    commit('setCurrentJourneyArticlesProgress', articlesProgress)
  },
  async updateCurrentArticleInteractions({ commit, rootState, state, dispatch }, chapterInteractions) {
    const { person } = rootState.account

    if (person && person.id && state.currentArticleId) {

      const teraId = state.currentTeraId

      commit('setCurrentArticleInteraction', chapterInteractions)

      await db.collection('people')
        .doc(person.id)
        .collection('people_article_interaction')
        .doc(state.currentArticleId)
        .update({ chapterInteractions: chapterInteractions, teraId: teraId })

      await dispatch('updateArticlesProgress')
    }
  },
  async setCurrentArticleInteractions({ commit, state, rootState, getters }) {
    const { person } = rootState.account
    const currentJourneyArticle = getters.currentJourneyArticle
    const teraId = state.currentTeraId

    if (!person && person.id) {
      return
    }

    const article = currentJourneyArticle

    if (!article) {
      return
    }

    const articleChapterInteraction = state.currentJourneyArticlesProgress.find(ai => ai.id === article.id)

    if (articleChapterInteraction) {

      const chapterInteractions = await articleService.updateChapterInteractions(articleChapterInteraction.chapterInteractions, article.chapters, person.id, article.id, teraId)

      commit('setCurrentArticleInteraction', chapterInteractions)
      return
    }

    const newPeopleArticleInteraction = article.chapters.map((chapter, index) => {
      const status = index === 0 ? 'doing' : 'unseen'
      const currentChapter = index === 0
      const chapterIndex = index
      return {
        status,
        currentChapter,
        chapterIndex
      }
    })

    await db.collection('people')
      .doc(person.id)
      .collection('people_article_interaction')
      .doc(article.id)
      .set({ chapterInteractions: newPeopleArticleInteraction, teraId: teraId })

    commit('setCurrentArticleInteraction', newPeopleArticleInteraction)

  },
  async changeInteractionCurrentChapterByIndex({ commit, state, getters }) {
    const { person } = rootState.account
    const currentJourneyArticle = getters.currentJourneyArticle
    const { currentJourneyArticles } = state.journeys

    if (currentJourneyArticles) {
      const updatedInteractions = state.currentChapterInteractions.map(c => {
        return {
          chapterIndex: c.chapterIndex,
          currentChapter: false,
          status: c.status
        }
      })

      await db.collection('people')
        .doc(person.id)
        .collection('people_article_interaction')
        .doc(currentJourneyArticle.id)
        .update({ chapterInteractions: updatedInteractions })

      commit('setCurrentArticleInteraction', updatedInteractions)
    }
  },
  async articleToNextChapter({ getters, state, commit, dispatch }) {
    const currentChapter = getters.currentChapterIndex

    const isLastChapter = getters.currentChapterIsLastChapter

    if (isLastChapter) {
      commit('changeArticleInteraction', {
        interactionIndex: currentChapter,
        currentChapter: false,
        status: 'done'
      })

      commit('changeArticleInteraction', {
        interactionIndex: 0,
        currentChapter: true
      })
    } else {
      commit('changeArticleInteraction', {
        interactionIndex: currentChapter,
        currentChapter: false,
        status: 'done'
      })

      commit('changeArticleInteraction', {
        interactionIndex: currentChapter + 1,
        currentChapter: true,
        status: 'doing'
      })
    }

    if (isLastChapter) {
      dispatch('toggleProgressUpdateIndicator')
    }

    dispatch('updateCurrentArticleInteractions', state.currentChapterInteractions)
  },
  async setArticleCurrentChapterByIndex({ dispatch, state }, index) {
    const { currentChapterInteractions } = state

    if (currentChapterInteractions[index].status !== 'unseen') {
      var nextInteractions = currentChapterInteractions.map(i => {
        return {
          status: i.status,
          currentChapter: false,
          chapterIndex: i.chapterIndex
        }
      })

      nextInteractions[index].currentChapter = true

      window.scrollTo(0, 0)

      dispatch('updateCurrentArticleInteractions', nextInteractions)
    }
  },
  toggleProgressUpdateIndicator({commit}){
    commit('toggleLoadingProgress', true)
  },
  clearCurrentJourneyStates({ commit }) {
    commit('clearCurrentJourneyStates')
  },
  clearState({ commit }) {
    commit('clearState')
  },
  bindStreamedJourneyBlocksByJourneyId({ commit, getters }) {
    const currentJourney = getters.currentUserJourney
    if (currentJourney.id === '') {
      return
    }

    const unsubscribe = journeyService.bindJourneyBlocksByJourneyId(currentJourney.id, journeyBlocks => {
      commit('updateStreamedJourneyBlocks', journeyBlocks)
    })

    commit('setStreamedJourneyBlocksUnsubscribe', unsubscribe)
  },
  unbindStreamedJourneyBlocks({ commit }) {
    commit('unbindStreamedJourneyBlocks')
  },
  bindStreamedJourneyProgressByUser({ commit, rootState }) {
    const { user } = rootState.account
    if (!user || !user.uid) {
      return
    }

    const unsubscribe = journeyService.bindJourneyProgressByUser(user.uid, progress => {
      commit('toggleLoadingProgress', true)
      commit('updateStreamedJourneyProgress', progress)
      commit('setCurrentProgressByJourney', progress)
      commit('toggleLoadingProgress', false)
    })

    commit('setStreamedJourneyProgressUnsubscribe', unsubscribe)
  },
  unbindStreamedJourneyProgressByUser({ commit }) {
    commit('unbindStreamedJourneyProgress')
  },
  setActiveReferral({ commit }, activeReferral) {
    commit('setActiveReferral', activeReferral)
  },
  setActiveCourseBanner({ commit }, activeCourseBanner) {
    commit('setActiveCourseBanner', activeCourseBanner)
  },
  updateJourneyBlocksWithStream({ commit, state }) {
    if (state.streamedJourneyBlocks && state.streamedJourneyBlocksUpdates > 0) {
      commit('toggleLoading', true)
      const loadingInterval = setInterval(() => {
        commit('setCurrentJourneyBlocks', state.streamedJourneyBlocks)
        commit('clearStreamedJourneyBlocksUpdates')
        commit('toggleLoading', false)
        clearInterval(loadingInterval)
      }, 300)
    }
  },
  updateJourneyProgressWithStream({ commit, state }) {
    if (state.streamedJourneyProgress && state.streamedJourneyProgressUpdates > 0) {
      commit('toggleLoadingProgress', true)
      const loadingInterval = setInterval(() => {
        commit('setCurrentProgressByJourney', state.streamedJourneyProgress)
        commit('clearStreamedJourneyProgressUpdates')
        commit('toggleLoadingProgress', false)
        clearInterval(loadingInterval)
      }, 300)
    }
  }
}

const mutations = {
  setActiveReferral(state, activeReferral) {
    state.activeReferral = activeReferral
  },
  setActiveCourseBanner(state, activeCourseBanner) {
    state.activeCourseBanner = activeCourseBanner
  },
  setUserJourneys(state, userJourneys) {
    state.userJourneys = userJourneys
  },
  setCurrentProgressByJourney(state, progressByJourney) {
    state.currentUserProgressByJourney = progressByJourney
  },
  setCurrentUserJourneyPeople(state, journeyPeople) {
    state.currentUserJourneyPeople = journeyPeople
  },
  setCurrentJourneyExperts(state, experts) {
    state.currentJourneyExperts = experts
  },
  setCurrentArticleExperts(state, experts) {
    state.currentArticleExperts = experts
  },
  setCurrentJourneyArticlesWithExperts(state, articlesWithExperts) {
    state.currentJourneyArticlesWithExperts = articlesWithExperts
  },
  setCurrentJourneyPlaces(state, places) {
    state.currentJourneyPlaces = places
  },
  setCurrentJourneyArticles(state, articles) {
    state.currentJourneyArticles = articles
  },
  setCurrentJourneyArticlesProgress(state, articlesProgress) {
    state.currentJourneyArticlesProgress = articlesProgress
  },
  setCurrentChapter: (state, currentChapterIndex) => {
    const lastItemIndex = state.currentChapterInteractions.length - 1

    if (lastItemIndex >= currentChapterIndex) {
      throw {
        error: 'article/store/setCurrentChapter/chapter-does-not-exists',
        message: 'O artigo não contém esse capitúlo'
      }
    }
    const nextCurrentChapterStatus = state.currentChapterInteractions[currentChapterIndex].status

    if (nextCurrentChapterStatus === 'unseen') {
      throw {
        error: 'article/store/setCurrentChapter/chapter-never-seen',
        message: 'O artigo ainda não foi visto, termine os capítulos anteriores antes'
      }
    } else {
      state.currentChapterInteractions.forEach(i => {
        i.currentChapter = false
      })

      state.currentChapterInteractions[currentChapterIndex].status = 'doing'

      state.currentChapterInteractions[currentChapterIndex].currentChapter = true
    }
  },
  setCurrentArticleInteraction: (state, peopleArticleInteraction) => {
    state.currentChapterInteractions = peopleArticleInteraction
  },
  changeArticleInteraction: (state, { interactionIndex, currentChapter, status }) => {
    state.currentChapterInteractions[interactionIndex].currentChapter = currentChapter

    if (state.currentChapterInteractions[interactionIndex].status !== 'done') {
      state.currentChapterInteractions[interactionIndex].status = status
    }
  },
  setCurrentArticleId(state, articleId) {
    state.currentArticleId = articleId
  },
  setCurrentJourneyBlocks(state, journeyBlocks) {
    state.currentJourneyBlocks = journeyBlocks
  },
  setUserOriginals(state, originals) {
    state.currentUserOriginals = originals
  },
  setCurrentTeraId(state, teraId) {
    state.currentTeraId = teraId
  },
  setCurrentModuleIndex(state, moduleIndex) {
    state.currentModuleIndex = moduleIndex
  },
  setCurrentOriginalsUrl(state, originalsUrl) {
    state.currentOriginalsUrl = originalsUrl
  },
  setCurrentOriginalsChapter(state, originalsChapter) {
    state.currentOriginalsChapter = originalsChapter
  },
  setCurrentJourneyBlockId(state, journeyBlockId) {
    state.currentJourneyBlockId = journeyBlockId
  },
  toggleLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  toggleLoadingProgress(state, isLoadingProgress) {
    if (isLoadingProgress) {
      state.isLoadingProgress = isLoadingProgress
      if (state.journeyProgressIsLoadingDebounce) return
      state.journeyProgressIsLoadingDebounce = longerDebounce(() => {
        state.isLoadingProgress = false
      })
    } else {
      clearInterval(state.journeyProgressIsLoadingDebounce)
      state.isLoadingProgress = isLoadingProgress
      state.journeyProgressIsLoadingDebounce = null
    }
  },
  toggleOriginalsIsLoading(state, originalsIsLoading) {
    if (originalsIsLoading) {
      state.originalsLoadingDebounce = debounce(() => {
        state.originalsIsLoading = originalsIsLoading
      })
    } else {
      clearInterval(state.originalsLoadingDebounce)
      state.originalsIsLoading = originalsIsLoading
    }
  },
  updateStreamedJourneyBlocks(state, journeyBlocks) {
    state.streamedJourneyBlocks = journeyBlocks
    state.streamedJourneyBlocksUpdates++
  },
  clearStreamedJourneyBlocksUpdates(state) {
    state.streamedJourneyBlocksUpdates = RESETED_ONSNAPSHOT_VALUE
  },
  unbindStreamedJourneyBlocks(state) {
    if (state.streamedJourneyBlocksUnsubscribe) {
      state.streamedJourneyBlocksUnsubscribe()
    }
    state.streamedJourneyBlocksUnsubscribe = getInitialState().streamedJourneyBlocksUnsubscribe
    state.streamedJourneyBlocksUpdates = getInitialState().streamedJourneyBlocksUpdates
  },
  setStreamedJourneyBlocksUnsubscribe(state, unsubscribe) {
    state.streamedJourneyBlocksUnsubscribe = unsubscribe
  },
  updateStreamedJourneyProgress(state, progress) {
    state.streamedJourneyProgress = progress
    state.streamedJourneyProgressUpdates++
  },
  clearStreamedJourneyProgressUpdates(state) {
    state.streamedJourneyProgressUpdates = RESETED_ONSNAPSHOT_VALUE
  },
  unbindStreamedJourneyProgress(state) {
    if (state.streamedJourneyProgressUnsubscribe) {
      state.streamedJourneyProgressUnsubscribe()
    }
    state.streamedJourneyProgressUnsubscribe = getInitialState().streamedJourneyProgressUnsubscribe
    state.streamedJourneyProgressUpdates = getInitialState().streamedJourneyProgressUpdates
  },
  setStreamedJourneyProgressUnsubscribe(state, unsubscribe) {
    state.streamedJourneyProgressUnsubscribe = unsubscribe
  },
  clearCurrentJourneyStates(state) {
    Object.assign(state, {
      ...getInitialState(),
      userJourneys: state.userJourneys
    })
  },
  clearState(state) {
    Object.assign(state, getInitialState())
  }
}

export const journeys = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const debounce = callback => {
  return setInterval(() => {
    callback()
  }, 1500)
}

const longerDebounce = callback => {
  return setInterval(() => {
    callback()
  }, 10000)
}

export const journeysWatchers = {
  getUserJourneysWithAccountPerson(store) {
    return {
      state: state => state.account.user,
      watcher: user => {
        if (user && user.uid) {
          if (store.getters['system/routeContext'] === 'journeys') {
            store.dispatch('journeys/getAllByPersonRef')
          }
        } else {
          store.dispatch('journeys/clearState')
        }
      }
    }
  },
  getUserJourneysWhenContextChange(store) {
    return {
      state: state => state.system.routeContext,
      watcher: routeContext => {
        const user = store.state.account.user
        if (routeContext === 'journeys' && user && user.uid) {
          store.dispatch('journeys/getAllByPersonRef')
        } else {
          store.dispatch('journeys/clearState')
        }
      }
    }
  },
  setLoadingStateWhenJourneyIsSystemContext(store) {
    return {
      state: state => state.system.routeContext,
      watcher: routeContext => {
        const user = store.state.account.user
        if (routeContext === 'journeys' && user) {
          store.dispatch('journeys/toggleLoading', true)
        } else {
          store.dispatch('journeys/toggleLoading', false)
        }
      }
    }
  }
}
