<template>
  <div class="quiz-container">
    <quiz-modal v-if="!quizWillBeUsedForEvalutationPurposes" />

    <div v-if="loading" class="loading-container">
      <loading-animation />
    </div>

    <div class="content-container ">
      <span class="quiz-label">PERGUNTA</span>

      <div class="quiz-title-container">
        <span />
        <h1>
          {{ dataQuiz.title }}
        </h1>
      </div>

      <p>
        {{ dataQuiz.subtitle }}
      </p>

      <div class="quiz-options-container">
        <quiz-option v-for="(item) in dataQuiz.options" :key="item.index" :optionData="item" />
      </div>

      <div :hidden="getSelectedResponse === null" class="quiz-feedback-container">
        <quiz-feedback :type="getSelectedResponse
                         ? 'success'
                         : 'fail'" :text="dataQuiz.comment"
                       :quizWillBeUsedForEvalutationPurposes="quizWillBeUsedForEvalutationPurposes"
                       :remainingTries="remainingTries"
        />
      </div>
      <p v-if="quizWillBeUsedForEvalutationPurposes" class="quiz-max-retries">
        Tentativas restantes: {{ remainingTries }}
      </p>
      <hr />

      <div class="buttons-container">
        <div>
          <button v-if="!quizWillBeUsedForEvalutationPurposes" :hidden="getSelectedResponse !== false"
                  @click="handleResponseAgain"
          >
            Responder outra vez
          </button>
          <button v-else-if="remainingTries > 0" :hidden="getSelectedResponse !== false" @click="handleResponseAgain">
            Responder outra vez
          </button>
          <button v-else :hidden="getSelectedResponse !== false" @click="handleShowResponse">
            Ver gabarito
          </button>
        </div>
        <button v-if="getSelectedResponse === null" :disabled="getSelectedIndexOption === null" @click="handleResponse">
          Responder
        </button>
        <button v-else-if="currentChapterIsLastChapter" @click="handleGoToFinishChapter">
          Voltar para o curso
        </button>
        <button v-else @click="handleGoToNextChapter">
          Avançar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { getCurrentUser } from '@/core/services/firebase'
import QuizOption from '@/modules/Journeys/components/QuizOption.vue'
import QuizModal from '@/modules/Journeys/components/QuizModal.vue'
import QuizFeedback from '@/modules/Journeys/components/QuizFeedback.vue'
import quizService from '@/modules/Journeys/services/quizService'
import { shouldUseQuizWithLimitedAnswers } from '@/core/services/remote-config'

import LoadingAnimation from '../LoadingAnimation.vue'

export default Vue.extend({
  name: 'QuizView',
  components: {
    QuizOption,
    QuizModal,
    QuizFeedback,
    LoadingAnimation
  },
  props: {
    quiz: Object
  },
  data () {
    return {
      dataQuiz: [],
      loading: false,
      totalGivenAnswers: 0
    }
  },
  computed: {
    ...mapGetters('quiz', ['getSelectedIndexOption', 'getSelectedOption', 'getSelectedResponse']),
    ...mapGetters('journeys', ['currentJourneyArticle', 'currentTeraId', 'currentChapterIsLastChapter', 'currentChapterIndex']),
    quizWillBeUsedForEvalutationPurposes () {
      // NOTE: Don't open the modal explaning that the quiz will not be use for the student evaluation if we are limiting their answers and basicaly evaluating them
      return shouldUseQuizWithLimitedAnswers(this.currentTeraId)
    },
    maxAllowedAnswers () {
      if (!this.dataQuiz || !this.dataQuiz.options) return 0

      return this.dataQuiz.options.length - 1
    },
    remainingTries () {
      return this.maxAllowedAnswers - this.totalGivenAnswers
    }
  },
  watch: {
    async quiz () {
      this.loading = true

      this.setCurrentResponse(null)
      this.setSelectedIndexOption(null),
        this.setSelectedOption({})

      this.dataQuizResponse = await quizService.getQuizById(this.quiz.quizId)
      this.dataQuiz = {
        ...this.dataQuizResponse,
        options: this.shuffleArray(this.dataQuizResponse.options)
      }
      this.setCurrentQuizOptions(this.dataQuiz.options)

      this.lastAnswer = await quizService.getLastAnswer(this.quiz.quizId)

      this.lastOption = this.dataQuiz.options.find(item => item.option === this.lastAnswer.answer)

      this.totalGivenAnswers = await quizService.getTotalAnswers(this.quiz.quizId, this.dataQuiz.options.length)

      if (this.lastAnswer?.answer && this.lastOption) {
        this.setCurrentResponse(this.lastAnswer.quizApproved)
        this.setSelectedOption(this.lastOption)
        this.setSelectedIndexOption(this.lastOption.index)
      }

      this.loading = false
    }
  },
  async mounted () {
    this.loading = true

    this.setCurrentResponse(null)
    this.setSelectedIndexOption(null),
      this.setSelectedOption({})

    this.dataQuizResponse = await quizService.getQuizById(this.quiz.quizId)
    this.dataQuiz = {
      ...this.dataQuizResponse,
      options: this.shuffleArray(this.dataQuizResponse.options)
    }
    this.setCurrentQuizOptions(this.dataQuiz.options)

    this.lastAnswer = await quizService.getLastAnswer(this.quiz.quizId)

    this.lastOption = this.dataQuiz.options.find(item => item.option === this.lastAnswer.answer)

    this.totalGivenAnswers = await quizService.getTotalAnswers(this.quiz.quizId, this.dataQuiz.options.length -1)

    if (this.lastAnswer?.answer && this.lastOption) {
      this.setCurrentResponse(this.lastAnswer.quizApproved)
      this.setSelectedOption(this.lastOption)
      this.setSelectedIndexOption(this.lastOption.index)
    }

    this.loading = false
  },
  methods: {
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
          ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    ...mapActions('quiz', ['setCurrentResponse', 'setCurrentQuizOptions', 'setIsSecondChance', 'setSelectedIndexOption', 'setSelectedOption']),
    ...mapActions('journeys', ['articleToNextChapter', 'setArticleCurrentChapterByIndex', 'toggleProgressUpdateIndicator']),

    async handleResponse () {
      this.totalGivenAnswers++

      this.setCurrentResponse(this.getSelectedOption.itCorrect)

      const user = await getCurrentUser()

      this.currentAnswer = {
        peopleId: user?.uid,
        quizApproved: this.getSelectedOption.itCorrect,
        answer: this.getSelectedOption.option
      }

      await quizService.saveAnswer(this.quiz.quizId, this.currentAnswer)
      this.totalGivenAnswers = await quizService.getTotalAnswers(this.quiz.quizId, this.dataQuiz.options.length)
      this.toggleProgressUpdateIndicator()
    },
    handleResponseAgain () {
      this.setCurrentResponse(null)
      this.setIsSecondChance(true)
    },
    handleShowResponse () {
      let correctOption = this.dataQuiz.options.find(option => option.itCorrect)
      this.setSelectedIndexOption(correctOption.index)
      this.setSelectedOption(correctOption)
      this.setCurrentResponse(true)

    },
    handleGoToNextChapter () {
      Vue.$logEvent({
        category: `lxs:conteudos:${this.currentJourneyArticle.name}`,
        action: 'clique:link',
        label: 'avançar'
      })

      this.articleToNextChapter()
    },
    handleGoToFinishChapter () {
      Vue.$logEvent({
        category: `lxs:conteudos:${this.currentJourneyArticle.name}`,
        action: 'clique:botao',
        label: 'concluir'
      })
      this.articleToNextChapter()
      this.$router.go(-1)
    }
  }
})
</script>

<style lang="scss">
.quiz-container {
  font-family: 'Rubik', sans-serif;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    background: rgba(0, 0, 0, 0.8);
  }

  .content-container {
    margin: 0 auto;
    padding: 40px 48px 0;
    width: 560px;

    .quiz-label {
      text-transform: uppercase;
    }

    .quiz-title-container {
      display: flex;
      text-transform: uppercase;
      margin-top: 3px;
      position: relative;
      margin-left: -16px;

      >span {
        width: 6px;
        height: 100%;
        background: #99fb8c;
        position: absolute;
      }

      >h1 {
        margin: 0 0 0 16px;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
    }

    >p {
      color: #575757;
      line-height: 24px;
    }

    .quiz-options-container {
      margin-top: 24px;

      >div+div {
        margin-top: 16px;
      }
    }

    .quiz-feedback-container {
      margin-top: 31px;
    }

    .quiz-max-retries {
      margin: 44px 0 -44px 0;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    >hr {
      border: 1px solid #e0e0e0;
      margin: 60px 0 16px 0;
    }

    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >div {
        >button {
          padding: 12px 38px;
          border-radius: 8px;
          border: 2px solid #000000;
          background: #ffffff;
          color: #000000;
          cursor: pointer;
          font-weight: bold;
          font-family: 'Rubik', sans-serif;
          text-transform: uppercase;

          &:disabled {
            background: #ffffff;
            color: #838383;
            border-color: #838383;
            cursor: not-allowed;

            &:hover {
              transform: translate(0, 0);
            }
          }

          &:hover {
            transform: translate(1px, 1px);
          }
        }
      }

      >button {
        padding: 14px 40px;
        border-radius: 8px;
        border: 0;
        background: #000000;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
        font-family: 'Rubik', sans-serif;
        text-transform: uppercase;

        &:hover {
          transform: translate(1px, 1px);
        }

        &:disabled {
          background: #e3e3e3;
          color: #bfbfbf;
          cursor: not-allowed;

          &:hover {
            transform: translate(0, 0);
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .quiz-container {
    width: 100%;

    .content-container {
      width: 80%;
      padding: 116px 16px 32px;
      margin: 0 auto;

      .quiz-title-container {
        margin-left: 0;

        >h1 {
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.7px;
        }
      }

      .buttons-container {
        flex-direction: column;

        >div {
          width: 100%;

          >button {
            width: 100%;
          }
        }

        >button {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
