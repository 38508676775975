import Login from '@/core/views/Login.vue'
import LoginItau from '@/core/views/LoginItau.vue'
import LoginSSO from '@/core/views/LoginSSO.vue'
import MicroFrontend from '@/core/views/MicroFrontend.vue'
import LoginCommunity from '@/core/views/LoginCommunity.vue'

export const coreRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login-itau',
    name: 'Login Itau',
    component: LoginItau
  },
  {
    path: '/login-sso',
    name: 'Login SSO',
    component: LoginSSO
  },
  {
    path: '/microfrontend',
    name: 'MicroFrontend',
    component: MicroFrontend
  },
  {
    path: '/community',
    name: 'LoginCommunity',
    component: LoginCommunity
  }
]
