<template>
  <div>
    <div id="loading-animation"></div>
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'LoadingAnimation',
  mounted() {
    lottie.loadAnimation({
      container: document.getElementById('loading-animation'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/animation/loading.json')
    })
  }
}
</script>

<style>
#loading-animation {
  width: 56px;
  height: 56px;
}
</style>
