import { OrderedList } from '@tiptap/extension-ordered-list';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import Component from './Component.vue';
export const ColouredCardList = OrderedList.extend({
    defaultOptions: Object.assign(Object.assign({}, OrderedList.options), { HTMLAttributes: {
            class: 'coloured-card-list'
        }, backgroundColor: 'white', foregroundColor: 'black' }),
    addAttributes() {
        var _a;
        return Object.assign(Object.assign({}, (_a = this.parent) === null || _a === void 0 ? void 0 : _a.call(this)), { backgroundColor: {
                default: 'white'
            }, foregroundColor: {
                default: 'black'
            } });
    },
    addCommands() {
        return {
            toggleColouredCardList: () => ({ commands }) => commands.toggleList('orderedList', 'listItem')
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(Component);
    }
});
