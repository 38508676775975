<template>
  <div @click="handleCardClick">
    <card-router-link :route="goToRoute" class="originals-chapter-card">
      <small-base-card
        :background-color="isCurrentChapter ? 'gray-60' : 'gray-100'"
      >
        <template v-slot:left>
          <heading
            class="chapter-index"
            tag="h2"
            font-weight="light"
            :color="isCurrentChapter ? 'gray-80' : 'blue-primary'"
          >
            {{ paddedIndex }}
          </heading>
        </template>
        <template v-slot:content>
          <heading tag="h3" :color="isCurrentChapter ? 'gray-80' : 'white'">
            {{ title }}
          </heading>
          <p v-if="subtitle">{{ subtitle }}</p>
        </template>
        <template v-slot:footer>
          <heading v-if="expectedMinutes" tag="h4" color="gray-80">
            {{ isCurrentChapter ? 'Você está aqui' : expectedMinutes + ' Min' }}
          </heading>
        </template>
      </small-base-card>
    </card-router-link>
  </div>
</template>

<script>
import Vue from 'vue'
import CardRouterLink from '@/core/components/CardRouterLink.vue'

export default {
  name: 'OriginalsChapterCard',
  components: {
    CardRouterLink
  },
  props: {
    index: { type: Number, required: true },
    isCurrentChapter: Boolean,
    title: { type: String, required: true },
    subtitle: String,
    url: { type: String, required: true },
    expectedMinutes: Number,
    routeParams: Object,
    routeName: { type: String, default: 'OriginalsChapter' }
  },
  computed: {
    goToRoute() {
      const params = this.routeParams
        ? { originalsUrl: this.url, chapter: this.index, ...this.routeParams }
        : { originalsUrl: this.url, chapter: this.index }

      return { name: this.routeName, params }
    },
    paddedIndex() {
      return String(this.index).padStart(2, '0')
    }
  },
  methods: {
    handleCardClick() {
      Vue.$logEvent({
        category: 'lxs:originals',
        action: 'clique:card',
        label: this.title
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.originals-chapter-card {
  > .small-base-card {
    padding: 16px 16px 16px 0;
    color: $color-white;
  }

  .chapter-index {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: -4px;
  }
}
</style>
