var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { db } from '@/core/services/firebase';
import { StatusChapterInteraction } from '@/modules/Journeys/services/interface/ChapterInteraction';
export default {
    updateChapterInteractions(currentChapterInteractions, articleChapters, personId, articleId, teraId) {
        return __awaiter(this, void 0, void 0, function* () {
            const articleHasNewChaptersNotUpdatedToTheInteraction = articleChapters.length > currentChapterInteractions.length;
            const articleHadChapterDeletedSinceLastInteraction = currentChapterInteractions.length > articleChapters.length;
            if (articleHasNewChaptersNotUpdatedToTheInteraction) {
                const notExistsInInteraction = (articleChapter) => {
                    const chapterInteraction = currentChapterInteractions.filter(chapterInteraction => chapterInteraction.chapterIndex == articleChapter.index);
                    return chapterInteraction.length === 0;
                };
                const articleChaptersNotExistsInInteraction = articleChapters.filter(articleChapter => notExistsInInteraction(articleChapter));
                const newPeopleArticleInteraction = articleChaptersNotExistsInInteraction.map(chapter => {
                    const status = StatusChapterInteraction.UNSEEN;
                    const currentChapter = false;
                    const chapterIndex = chapter.index;
                    return {
                        status,
                        currentChapter,
                        chapterIndex
                    };
                });
                currentChapterInteractions.push(...newPeopleArticleInteraction);
                yield this.updateChapterInteractionsWithArticleChapters(currentChapterInteractions, personId, articleId, teraId);
                return currentChapterInteractions;
            }
            if (articleHadChapterDeletedSinceLastInteraction) {
                const newInteractionsBasedOnTheOldOnes = articleChapters.map(chapter => {
                    const status = currentChapterInteractions[chapter.index] ?
                        currentChapterInteractions[chapter.index].status :
                        StatusChapterInteraction.UNSEEN;
                    const currentChapter = chapter.index === 0;
                    const chapterIndex = chapter.index;
                    return {
                        status,
                        currentChapter,
                        chapterIndex
                    };
                });
                yield this.updateChapterInteractionsWithArticleChapters(newInteractionsBasedOnTheOldOnes, personId, articleId, teraId);
                return currentChapterInteractions;
            }
            return currentChapterInteractions;
        });
    },
    updateChapterInteractionsWithArticleChapters(currentChapterInteractions, personId, articleId, teraId) {
        return __awaiter(this, void 0, void 0, function* () {
            const peopleDb = db.collection('people');
            yield peopleDb
                .doc(personId)
                .collection('people_article_interaction')
                .doc(articleId)
                .update({ chapterInteractions: currentChapterInteractions, teraId: teraId });
        });
    }
};
