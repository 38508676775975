import Journeys from '@/modules/Journeys/views/Journeys.vue';
import Modules from '@/modules/Journeys/views/Modules.vue';
import Class from '@/modules/Journeys/views/Class.vue';
import Live from '@/modules/Journeys/views/Live.vue';
import Article from '@/modules/Journeys/views/Article.vue';
import ModuleLibrary from '@/modules/Journeys/views/ModuleLibrary.vue';
import Originals from '@/modules/Journeys/views/Originals.vue';
import JourneyManualListAll from '@/modules/Journeys/views/JourneyManual/ListAll.vue';
import JourneyManualIntro from '@/modules/Journeys/views/JourneyManual/Intro.vue';
import JourneyManualTeraway from '@/modules/Journeys/views/JourneyManual/Teraway.vue';
import JourneyManualToolkit from '@/modules/Journeys/views/JourneyManual/Toolkit.vue';
export const journeyRouteContext = 'journeys';
export const journeyRoutes = [
    {
        path: '/',
        name: 'Journeys',
        component: Journeys,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId',
        name: 'Modules',
        props: true,
        component: Modules,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/class/:journeyBlockId',
        name: 'Class',
        props: true,
        component: Class,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/live/:journeyBlockId',
        name: 'Live',
        props: true,
        component: Live,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/library-:moduleIndex',
        name: 'ModuleLibrary',
        props: (route) => {
            return {
                teraId: route.params.teraId,
                moduleIndex: Number(route.params.moduleIndex)
            };
        },
        component: ModuleLibrary,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/library-:moduleIndex/originals/:originalsUrl',
        redirect: '/originals/:originalsUrl',
        name: 'JourneyOriginals',
        props: (route) => {
            return {
                teraId: route.params.teraId,
                originalsUrl: route.params.originalsUrl,
                moduleIndex: Number(route.params.moduleIndex)
            };
        },
        component: Originals,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/library-:moduleIndex/originals/:originalsUrl',
        redirect: '/originals/:originalsUrl'
    },
    {
        path: '/journeys/:teraId/library-:moduleIndex/originals/:originalsUrl/:chapter',
        redirect: '/originals/:originalsUrl/:chapter'
    },
    {
        path: '/originals/:originalsUrl/:chapter',
        name: 'OriginalsChapter',
        props: (route) => {
            return {
                teraId: route.params.teraId,
                moduleIndex: Number(route.params.moduleIndex),
                originalsUrl: route.params.originalsUrl,
                chapter: Number(route.params.chapter)
            };
        },
        component: Originals,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/originals/:originalsUrl',
        name: 'Originals',
        props: (route) => {
            return {
                originalsUrl: route.params.originalsUrl
            };
        },
        component: Originals,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/manual',
        name: 'journey-manuals',
        props: (route) => {
            return {
                teraId: route.params.teraId
            };
        },
        component: JourneyManualListAll,
        meta: {
            requiresAuth: true,
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/manual/welcome',
        name: 'journey-manual-intro',
        props: true,
        component: JourneyManualIntro,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/manual/teraway',
        name: 'journey-manual-teraway',
        component: JourneyManualTeraway,
        props: true,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journeys/:teraId/manual/toolkit',
        name: 'journey-manual-toolkit',
        component: JourneyManualToolkit,
        props: true,
        meta: {
            routeContext: journeyRouteContext
        }
    },
    {
        path: '/journey/:teraId/article/:articleId',
        name: 'journey-article',
        component: Article,
        props: (route) => {
            const { teraId, articleId } = route.params;
            const { chapter } = route.query;
            return {
                teraId,
                articleId,
                chapterIndex: chapter ? Number(chapter) : 0
            };
        },
        meta: {
            routeContext: journeyRouteContext
        }
    }
];
