<template>
  <main-container class="courses">
    <heading v-if="personFirstName" tag="h2" font-weight="light" color="black">
      Olá, {{ personFirstName }}!
    </heading>
    <heading v-else tag="h2" font-weight="light" color="black">Olá!</heading>
    <div v-if="userHasCourses" class="current-courses">
      <heading tag="h4" color="black">Seus cursos</heading>
      <div class="cards">
        <journey-card
          v-for="(j, i) in userJourneys"
          :key="i + '-userJourneys'"
          background-color="black"
          :journey="j"
          :progress="getJourneyProgress(j.teraId)"
          class="card"
        />
      </div>
    </div>
    <div v-else class="no-courses">
      <p>
        <span class="warn uppercase">Não encontramos nenhum curso para o e-mail:</span>
        <br>
        <span id="email-formatter">"{{ personEmail }}"</span> 🤔
      </p>
      <p>
        Verifique se este é o mesmo e-mail que você forneceu durante a sua inscrição.
      </p>
      <hr>
      <p>
        <span class="warn">Gostaria de entrar com outro e-mail?</span>
        <br>
        <a href="https://app.somostera.com/login" @click="logout">Faça o login novamente.</a>
      </p>
      <p>
        <span class="warn">Você entrou com o e-mail correto?</span>
        <br>
        Fale com nosso suporte a estudante:
        <a href="mailto:meajuda@somostera.com" target="_blank"
           @click="() => this.$logEvent({ category: 'lxs:home', action: 'clique:link', label: 'me-ajuda' })"
        >meajuda@somostera.com</a>.
      </p>
      <p>
        <span class="warn">Gostaria de se inscrever em alguns dos nossos cursos?</span>
        <br>
        Descubra abaixo aquele que mais combina com você.
      </p>
    </div>

    <anivertera-banner v-show="AniverteraBannerIsVisible"></anivertera-banner>

    <banner-referral v-show="false"></banner-referral>

    <div class="other-courses">
      <heading v-if="userHasCourses" tag="h4" color="black">Outros cursos da Tera</heading>
      <heading v-else tag="h4" color="black">Inscreva-se</heading>

      <div class="cards">
        <journey-card v-for="(j, i) in otherJourneysWithoutUserJourneys" :key="i + '-otherJourneys'"
                      :journey="j.journey" :current-course="false" :route="j.route" class="card"
        ></journey-card>
      </div>
    </div>
  </main-container>
</template>

<script>
import BannerReferral from '@/modules/Referral/BannerReferral.vue'
import AniverteraBanner from '@/modules/Journeys/components/AniverteraBanner.vue'
import JourneyCard from '@/modules/Journeys/components/JourneyCard.vue'
import MainContainer from '@/core/components/MainContainer'
import Journey from '@/core/entities/models/Journey'
import { app } from '@/core/services/firebase'
import { getAllCourses } from '@/modules/Journeys/services/courses'
import { showAniverteraBanner, showReferralBanner } from '@/core/services/remote-config'


import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'Journeys',
  components: {
    JourneyCard,
    MainContainer,
    BannerReferral,
    AniverteraBanner,
  },
  data () {
    return {
      otherCourses: [],
      journeys: []
    }
  },
  computed: {
    ...mapGetters('journeys', ['userJourneys', 'currentUserProgressByJourney']),
    ...mapGetters('account', ['person', 'user']),
    personFirstName () {
      if (!this.person) {
        return ''
      }

      return this.person.name.split(' ')[0]
    },
    personEmail () {
      return (
        app.auth().currentUser.email ??
        localStorage.getItem('emailForSignIn')
      )
    },
    otherJourneysWithoutUserJourneys () {
      if (!this.userJourneys) {
        return this.otherCourses

      }

      const userJourneysAcronyms = this.userJourneys.map((uj) => {
        if (!uj.courseInfo) return
        return uj.teraId.includes('TR') || uj.teraId.includes('EV_REM') || uj.teraId.includes('DPW') ? '' : uj.courseInfo.acronym
      }).filter((a) => a !== '')

      return this.otherCourses.filter((otherJourney) => {
        if (
          otherJourney.journey.courseInfo.acronym === 'trp'
          || otherJourney.journey.courseInfo.acronym === 'dpw'
          || otherJourney.journey.courseInfo.acronym === 'uxd'
        ) return
        return userJourneysAcronyms.indexOf(otherJourney.journey.courseInfo.acronym) === -1
      })
    },
    userHasCourses () {
      return this.userJourneys && this.userJourneys.length > 0
    },
    showReferralBanner () {
      return showReferralBanner()
    },
    AniverteraBannerIsVisible () {
      return showAniverteraBanner(this.userJourneys)
    }
  },
  mounted () {
    this.getCourses()
    this.setCurrentProgressByJourney(this.user.uid)
    this.setRouteBreadcrumb(null)
    localStorage.removeItem('emailForSignIn')
  },
  methods: {
    ...mapActions('journeys', [
      'setCurrentProgressByJourney',
      'unbindStreamedJourneyBlocks',
      'unbdinStreamedJourneyProgress',
    ]),
    ...mapActions('system', ['setRouteBreadcrumb']),
    getJourneyProgress(teraId) {
      const defaultValue = { ratio: 0 }

      if (!teraId || !this.currentUserProgressByJourney) {
        return defaultValue
      }
      return this.currentUserProgressByJourney.find(j => j.teraId === teraId)
    },
    logout () {
      Vue.$setUser(null)
      Vue.$logEvent({ category: 'lxs:home', action: 'clique:link', label: 'sair' })
      app.auth().signOut()
      this.$store.dispatch('account/resetState')
    },
    beforeRouteEnter (to, from, next) {
      next((vm) => {
        vm.unbindStreamedJourneyBlocks()
        vm.unbdinStreamedJourneyProgress()
      })
    },

    async getCourses () {
      try {
        const validFormats = ['Part-time', 'Full-time']
        const courses = await getAllCourses()
        const coursesData = []
        courses.forEach((course) => {
          if (course.public === true) {
            const courseFormat = []
            course.formats.forEach((format) => {
              if (validFormats.includes(format.name)) { courseFormat.push(format.name) }
            })
            const courseFormatName = courseFormat.join([' e '])
            coursesData.push(
              {
                journey: new Journey(courseFormatName, {
                  acronym: course.acronym,
                  name: course.name
                }),
                route: {
                  href: course.siteInfo.siteUrl
                }
              }
            )
          }
        })
        this.otherCourses = coursesData
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#email-formatter {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.courses {
  max-width: $container-xl;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;

  >h2 {
    margin: 56px 0 24px 0;
  }

  >.no-courses {
    width: 100%;
    max-width: 596px;
    margin-bottom: 64px;

    hr {
      margin: 28px 0 0 0;
      border: 1px solid #BFBFBF;
    }

    p {
      margin: 28px 0 0 0;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: $color-black;

      .warn {
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
      }

      .uppercase {
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
      }
    }

    a {
      color: #04A0C3;
    }
  }

  >.current-courses,
  .other-courses {
    width: 100%;
    text-align: left;

    >.cards {
      height: auto;
      margin: 8px 0 64px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &::after {
        content: "";
        width: calc(100% / 3 - #{$space-xxs});
        min-height: 1px;

        @include breakpoint-medium {
          width: calc(50% - #{$space-xxs});
        }

        @include breakpoint-small {
          width: 100%;
        }
      }

      @include breakpoint-extra-large {
        justify-content: space-between;
      }

      @include breakpoint-small {
        justify-content: center;
      }

      >.card {
        width: calc(100% / 3 - #{$space-xxs});
        margin: #{$space-xxs / 2} 0 #{$space-xxs};

        @include breakpoint-medium {
          width: calc(50% - #{$space-xxs});
        }

        @include breakpoint-small {
          width: 100%;
        }
      }
    }
  }
}
</style>
