<template>
  <transition name="fade-to-login">
    <div ref="background" key="loading" class="loading-background">
      <loading></loading>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'
import { firebase } from '@/core/services/firebase'
import { journeyRouteContext } from '@/modules/Journeys/routes'

import Loading from '@/core/components/Loading.vue'

export default {
  name: 'LoginSSO',
  components: {
    Loading
  },
  watch: {
    isLoggedIn (value) {
      if (value) {
        this.redirectToJourneys()
      }
    }
  },
  mounted () {
    this.loginWithToken()
    if (this.isLoggedIn) {
      this.redirectToJourneys()
    }
  },
  methods: {
    redirectToJourneys () {
      this.setRouteContext(journeyRouteContext)
      this.$router.push({ name: 'Journeys' })
    },
    loginWithToken () {
      const queryParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const token = queryParams.u
      
      firebase.auth().signInWithCustomToken(token).then(() => {
        this.$router.replace('/')
      }).catch((error) => {
        console.error(error)
        alert('Ocorreu um problema com o seu processo de login. Por favor, entre em contato com o suporte da Tera.')
      })

    },
    ...mapActions('system', ['setRouteContext']),
    ...mapActions('account', ['toggleWaitingLogin']),
    ...mapActions('journeys', ['toggleLoading'])
  }
}
</script>

<style lang="scss" scoped>
</style>
