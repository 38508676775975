import Vue from 'vue';
import Check from './Check.vue';
import ChevronLeft from './ChevronLeft.vue';
import Play from './Play.vue';
import Video from './Video.vue';
export default Vue.extend({
    name: 'BaseIcon',
    components: {
        Check,
        ChevronLeft,
        Play,
        Video
    },
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: [String, Number],
            default: 20
        },
        color: {
            type: String,
            default: '#000'
        }
    }
});
