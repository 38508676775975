import Vue from 'vue';
export default Vue.extend({
    name: 'ProgressBar',
    props: {
        size: {
            type: [String, Number],
            default: 16
        },
        color: {
            type: String,
            default: '#333'
        },
        percent: {
            type: [String, Number],
            required: true
        },
        showOnlyProgress: {
            type: Boolean,
            required: false,
            default: false
        },
        rotating: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        cssProps() {
            return {
                height: `${Number(this.size)}px`,
                width: `${Number(this.size)}px`
            };
        },
        cx() {
            return Number(this.size) / 2;
        },
        cy() {
            return Number(this.size) / 2;
        },
        r() {
            return (Number(this.size) - 6) / 2;
        },
        height() {
            return Number(this.size);
        },
        width() {
            return Number(this.size);
        },
        strokeDashoffset() {
            // stroke-dasharray - 2.pi.radius * percentage / 100 => radius = icon size / 2 - 2*stroke-width
            return 360 - (Number(this.size) - 6) * Math.PI * (Number(this.percent) / 100);
        },
        top() {
            return Number(this.size) / 2 - 28;
        },
        left() {
            return Number(this.size) / 2 - 16;
        }
    }
});
